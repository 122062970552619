import { Box } from "@mui/material";
import { styled } from '@mui/material/styles';

export default styled(Box)(({ theme, width }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: width||500,
    width: '100%',
    maxHeight: '90%',
    overflow: 'auto',
    padding: theme.spacing(2),
}));