import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Paper } from '@mui/material';
import Box from '@mui/material/Box';
import { Stack } from '@mui/system';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import { createRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { FormTextField } from '../shared/form-components';
import { CHANGE_PASSWORD } from '../graphql/mutations';

const changePasswordSchema = Yup.object({
    currentPassword: Yup.string().required(),
    password: Yup.string().required(),
    confirmPassword: Yup.string().required().oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

export default ({onChanged}) => {
    const { enqueueSnackbar } = useSnackbar();

    const {t} = useTranslation();

    const password1Ref = createRef();
    const password2Ref = createRef();
    
    const {control, handleSubmit, formState: {isLoading} } = useForm({
        resolver: yupResolver(changePasswordSchema)
    });

    const [changePasswordFnc] = useMutation(CHANGE_PASSWORD);

    const changePassword = async (data) =>{
        await changePasswordFnc({
            variables: {
                input: {
                    currentPassword: data.currentPassword,
                    newPassword: data.password,
                }
            }
        });

        enqueueSnackbar(t("ChangePasswordDone"), {variant: "success"});
        onChanged && onChanged();
    }

    const handleFocusNextWhenEnterKeyDown = (elementRef) => (event) => {
        if (event.keyCode === 13) {
            elementRef.current.focus();
        }
    }

    const handlePassword2KeyDown = (event) => {
        if (event.keyCode === 13) {
            handleSubmit(changePassword)();
        }
    }

    return (
        <Paper>
            <Box p={2}>
                <Stack spacing={2}>
                    <FormTextField 
                        ref={password1Ref}
                        onKeyDown={handleFocusNextWhenEnterKeyDown(password1Ref)}
                        control={control}
                        type='password'
                        label={t("CurrentPassword")}
                        name="currentPassword"
                        inputProps={{
                            autocomplete: 'new-password',
                            form: {
                            autocomplete: 'off',
                            },
                        }}
                    />

                    <FormTextField 
                        ref={password1Ref}
                        onKeyDown={handleFocusNextWhenEnterKeyDown(password2Ref)}
                        control={control}
                        type='password'
                        label={t("Password")}
                        name="password"
                        inputProps={{
                            autocomplete: 'new-password',
                            form: {
                            autocomplete: 'off',
                            },
                        }}
                    />

                    <FormTextField 
                        ref={password2Ref}
                        onKeyDown={handlePassword2KeyDown}
                        control={control}
                        type='password'
                        label={t("Confirm Password")}
                        name="confirmPassword"
                        inputProps={{
                            autocomplete: 'new-password',
                            form: {
                            autocomplete: 'off',
                            },
                        }}
                    />

                    <LoadingButton 
                        fullWidth
                        color="primary" 
                        variant="contained"
                        loading={isLoading}
                        onClick={handleSubmit(changePassword)}>{t('ChangePassword')}</LoadingButton>
                </Stack>
            </Box>
        </Paper>);
    }
