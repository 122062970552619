import { useEffect, useState } from "react";
import { io } from "socket.io-client";
import { useToken } from "../auth/hooks";
import { useConfiguration } from "../shared/configuration";

export const useAsyncTurns = () => {
    const [{ws: {uri, path}}] = useConfiguration();
    const [socket, setSocket] = useState(undefined);
    const [isConnected, setIsConnected] = useState(false);
    const [token] = useToken();
    const [turn, setTurn] = useState(undefined);
  
    useEffect(()=>{
        const newSocket = io(uri, {
            path: path,
            extraHeaders: {
                authorization: token ? `Bearer ${token}` : "",
            }
        });

        newSocket.on('connect', () => {
            setIsConnected(true);
         });

        newSocket.on('disconnect', () => {      
            setIsConnected(false);
        });

        newSocket.on('turn', (param) => {   
            setTurn(param);
        });

        setSocket(newSocket);
        return ()=>{
            newSocket.close();
        }
    }, [uri, path, token]);
    
    const reconnect = () => {
        socket?.connect();
    }

    return [turn, isConnected, reconnect];
}