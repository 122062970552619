import styled from "@emotion/styled";
import { Box } from "@mui/material";

const MainBox = styled(Box)(({theme}) => ({
    height: '100vh', 
    display:'flex', 
    width: '100%',
    background: 'linear-gradient(to bottom right, #ffffff, #62d2ff)'
}));

export default MainBox;