import { 
    Button, 
    Divider, 
    Stack, 
    Box,
    Paper,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography, 
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from 'react-i18next'
import Title from '../../shared/Title';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { 
    FormTextField,
} from '../../shared/form-components';
import { useMutation, useQuery } from '@apollo/client';
import { 
    CREATE_USER, UPDATE_USER
} from '../../graphql/mutations'
import { 
    FIND_USER_BY_ID, GET_USERS
} from '../../graphql/queries'
import Confirmation from '../../shared/Confirmation';
import { LoadingButton } from '@mui/lab';
import { AccessSection, FormSelectMultipleGroupsQuery, accessSchema, EntityConstraintRefSection, entityConstraintRefSchema } from '../form-components';
import { useSnackbar } from 'notistack';
import StatusChip from '../../shared/StatusChip';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTenantId } from '../../tenant/hooks';

export default ({
    userId,
    onCancel, 
    onAdded 
}) => {

    const isNew = !userId;
    const [tenantId] = useTenantId();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const userSchema = yup.object({
        _id: yup.string(),
        fullName: yup.string().required(t('RequiredField', {Field: t('FullName')})),
        email: yup.string().when('_id', {
            is: (_id) => !_id,
            then: (schema) => schema.required(t('RequiredField', {Field: t('Email')}))
        }),
        username: yup.string().when('_id', {
            is: (_id) => !_id,
            then: (schema) => schema.required(t('RequiredField', {Field: t('Username')}))
        }),
        password: yup.string().when('_id', {
            is: (_id) => !_id,
            then: (schema) => schema.required(t('RequiredField', {Field: t('Password')}))
        }),
        status: yup.string().when('_id', {
            is: (_id) => !!_id,
            then: (schema) => schema.required()
        }),
        groupIds: yup.array().of(yup.string()),
        access: yup.array().of(accessSchema),
        entityConstraintRefs: yup.array().of(entityConstraintRefSchema)
    }).transform((v) => {
        const {...others} = v;

        if (others._id) {
            const {password, username, ...result} = others;
            return result;
        } else {
            const {_id, status, ...result} = others;
            return result;
        }
    });

    const [ saveUser, {data, loading} ] = useMutation(isNew ? CREATE_USER : UPDATE_USER, {
        refetchQueries: [FIND_USER_BY_ID, GET_USERS]
    })
    const {data: userData, loading: userLoading} = useQuery(FIND_USER_BY_ID, {
        variables: {
            userId: userId
        },
        skip: isNew
    });
    
    const methods = useForm({
        resolver: yupResolver(userSchema),
        mode: 'all',
        defaultValues: {
            _id: userId,
            groupIds: [],
            access: []
        }
    });

    const { setValue, handleSubmit, control, formState:{errors}, watch } = methods;
    const [openConfirmation, setOpenConfirmation] = useState(false);

    const onSubmit = async (data) => {
        data.access = data.access.map(a=>({entityRef: a.entityRef, actionId: a.actionId, entityConstraintRefKeys: a.entityConstraintRefKeys}));
        const response = await saveUser({
            variables: {
                input: data,
                ...(isNew ? {tenants:[tenantId]}: {})
            }
        });
        onAdded && onAdded({
            ...data,
            ...response.data.user
        });

        enqueueSnackbar(t("Operation done successfully."), {variant: "success"});
    }

    useEffect(()=>{
        if (!userData?.findUser) {
            return;
        }

        setValue("username", userData.findUser.username);
        setValue("fullName", userData.findUser.fullName);
        setValue("email", userData.findUser.email||'');
        setValue("status", userData.findUser.status);
        setValue("access", userData.findUser.access.map(a=>{
            return {
                ...a,
                actionId: a?.action?._id
            }
        }));

        setValue("entityConstraintRefs", userData.findUser.entityConstraintRefs??[]);
        setValue("groupIds", userData.findUser.groups.map(g=>{
            return g._id
        }));
    }, [userData]);

    const handleClose = () => {
        setOpenConfirmation(false);
    }

    const showConfirmation = (_, e) => {
        setOpenConfirmation(true);
    }

    const {status, access, entityConstraintRefs} = watch();
    let entities = (access?.reduce((l,a)=>[...l, ...(a.action?.entityConstraints??[]) ],[])??[]);
    
    return (
        <Paper>
            <Confirmation 
                open={openConfirmation}
                onNo={handleClose}
                onClose={handleClose}
                onYes={handleSubmit(onSubmit)}
                title={t('Confirmation')}
                description={t('AreYouSureQuestion')}
            />
            <FormProvider {...methods}>
                <form onSubmitCapture={(e)=>e.preventDefault()}>
                    <Box p={2}>
                        <Stack direction="row" spacing={2} alignItems="center">
                            {!isNew && <StatusChip status={status}/> }
                            <Title sx={{marginBottom: 0}}>{t(isNew ? "CreateUser":"EditUser")}</Title>
                        </Stack>
                    </Box>
                    <Divider />
                    <Box p={2}>
                        <Stack spacing={2}>
                            <FormTextField 
                                label={t('FullName')}
                                control={control}
                                name="fullName" />
                    
                            <FormTextField 
                                label={t('Username')}
                                control={control}
                                name="username" 
                                disabled={!isNew}/>

                            <FormTextField 
                                label={t('Email')}
                                control={control}
                                name="email" 
                                disabled={!isNew}/>

                            {isNew &&
                            <FormTextField 
                                label={t('Password')}
                                control={control}
                                type="password"
                                name="password" 
                                inputProps={{
                                    autocomplete: 'new-password',
                                    form: {
                                      autocomplete: 'off',
                                    },
                                }}/>}

                            <FormSelectMultipleGroupsQuery
                                control={control}
                                name="groupIds"
                                tenantId={tenantId}
                                label={t('Groups')} />
                      
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>{t('EntityConstraintRefs')}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <EntityConstraintRefSection name="entityConstraintRefs" entities={entities} />
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>{t('Access')}</Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{height:'30vh', overflowY:'scroll'}}>
                                    <AccessSection entityConstraintRefs={entityConstraintRefs} name="access" />
                                </AccordionDetails>
                            </Accordion>
                        </Stack>
                    </Box>
                    <Divider />
                    <Box p={2}>
                        <Stack direction="row" justifyContent="space-between">
                            <Button 
                                disabled={loading} 
                                variant='contained' 
                                color="grey" 
                                onClick={onCancel}
                            >{t('Cancel')}</Button>
                            <LoadingButton 
                                loading={loading} 
                                variant='contained' 
                                color='primary'
                                onClick={handleSubmit(showConfirmation)}
                            >{t('Submit')}</LoadingButton>
                        </Stack>
                    </Box>
                </form>
            </FormProvider>
        </Paper>
    )
}