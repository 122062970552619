import { useMutation } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import { Box, Divider, Paper, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { Fragment } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { WRITE_APPOINTMENT_COMMENT } from "../graphql/mutations";
import { FormTextField } from "../shared/form-components";
import SubTitle from "../shared/SubTitle";
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import * as moment from 'moment';
import { FIND_APPOINTMENT_BY_ID, GET_QUEUED_APPOINTMENTS } from "../graphql/queries";
import { HasAccessTo } from "../auth/hooks";

const noteSchema = Yup.object({
    comment: Yup.string().required()
});

export default ({comments, appointmentId, onAdded}) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const  {control, handleSubmit, setValue} =  useForm({
        resolver: yupResolver(noteSchema)
    });

    const [writeAppointmentNote, {data, loading}] = useMutation(
        WRITE_APPOINTMENT_COMMENT,{
            refetchQueries: [
                FIND_APPOINTMENT_BY_ID,
                GET_QUEUED_APPOINTMENTS
            ],
            onCompleted: ()=>{
                enqueueSnackbar(t("OperationDoneSuccessfully"), {variant: "success"});
            }
        });

    const handleWriteComment = async (data) => {
        await writeAppointmentNote({
            variables: {
                ...data,
                appointmentId: appointmentId
            }
        });
        onAdded && onAdded();
        setValue("comment", "");
    }

    return (
        <Paper>
            <Box p={2}>
                <SubTitle>{t('Comments')}</SubTitle>
            </Box>
            <Divider />
            <Box p={2}>

                <Stack spacing={0}>
                    {comments && comments.length>0 ? 
                        comments.map((c,index)=>{
                            return (<Fragment key={index}>
                                <pre style={{margin:0}}>
                                    {c.comment}                                
                                </pre>
                                <Stack spacing={2} mb={2} mt={2} direction="row" alignItems="center">
                                    <Typography>
                                        {moment(c.createdAt).fromNow()}
                                    </Typography>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Divider sx={{ flexGrow: 1, width:'100%'}}/>
                                    </Box>
                                    <Typography>
                                        {c.wroteBy?.fullName}
                                    </Typography>
                                </Stack>
                            </Fragment>)
                        })
                    : 
                    <Typography>
                        {t('ThereIsNotData')}
                    </Typography>}
                </Stack>
            </Box>
            <HasAccessTo entity="Appointment" code="UPDATE">
                <>
                    <Divider/>
                    <Box p={2}>
                        <Stack spacing={2}>
                            <FormTextField 
                                fullWidth
                                rows={3}
                                multiline={true} 
                                control={control}
                                name="comment"
                                label={t("Comment")}
                                disabled={loading}
                            />

                            <LoadingButton 
                                variant="contained" 
                                color="primary"
                                loading={loading}
                                onClick={handleSubmit(handleWriteComment)}>
                                {t('Write')}
                            </LoadingButton>
                        </Stack>
                    </Box>
                </>
            </HasAccessTo>
        </Paper>
    );
}
