import { Box, Button, Divider, Paper, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from "react-hook-form";
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next'
import Title from '../shared/Title';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@apollo/client';
import { FIND_DISCOUNT_BY_ID, GET_DISCOUNTS } from '../graphql/queries';
import { CREATE_DISCOUNT, UPDATE_DISCOUNT } from '../graphql/mutations';
import { FormSwitch, FormTextField, FormSlider } from '../shared/form-components';
import { useSnackbar } from 'notistack';
import { useTenantId } from '../tenant/hooks';

export default ({discountId, onCancel, onAdded}) => {
    const isNew = !discountId;
   
    const [tenantId] = useTenantId();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [ createDiscount] = useMutation(isNew ? CREATE_DISCOUNT : UPDATE_DISCOUNT,{
        refetchQueries: [
            GET_DISCOUNTS,
            FIND_DISCOUNT_BY_ID
        ]
    })
 
    const {data, loading} = useQuery(FIND_DISCOUNT_BY_ID, {
        variables: {
            discountId: discountId
        },
        fetchPolicy: 'network-only',
        skip: isNew
    });

    const schema = yup.object({
        _id: yup.string(),
        name: yup.string().required(t('RequiredField', {Field: t('Name')})),
        percent: yup.number().required(),
        enabled: yup.bool().required()
    });
    
    const methods = useForm({
        resolver: yupResolver(schema),
        mode: 'all',
        defaultValues: {
            _id: discountId,
            enabled: true,
            percent: 0
        }
    });
    const { setValue, handleSubmit, formState: { isSubmitting } } = methods;

    const onSubmit = async data => {
        const response = await createDiscount({
            variables: {
                input: data,
                ...(isNew ? {tenants:[tenantId]}: {})
            }
        });
        onAdded && onAdded({
            ...data,
            ...response.data.createDiscount
        });

        enqueueSnackbar(t("OperationDoneSuccessfully"), {variant: "success"});
    }
    
    useEffect(()=>{
        if (!data?.findDiscount) {
            return;
        }

        setValue("name", data.findDiscount.name);
        setValue("percent", Number(data.findDiscount.percent));
        setValue("enabled", data.findDiscount.enabled);
    }, [data]);

    return (
        <Paper>
            <FormProvider {...methods}>
                <Box p={2}>
                    <Title sx={{marginBottom: 0}}>{t(isNew?"CreateDiscount":"EditDiscount")}</Title>
                </Box>
                <Divider />
                <Box p={2}>
                    <Stack spacing={2}>
                        <FormTextField 
                            label={t('Name')}
                            name="name"
                            variant="outlined"
                            fullWidth
                        />

                        <FormSlider
                            label={t('Percent')}
                            name="percent"
                        />
                    
                        <FormSwitch 
                            label={t('Enabled')}
                            name="enabled"
                        />
                    </Stack>
                </Box>
                <Divider />
                <Box p={2}>
                    <Stack direction="row" justifyContent="space-between">
                        <Button variant='contained' color="grey" onClick={onCancel} disabled={isSubmitting}>Cancel</Button>
                        <LoadingButton 
                            variant='contained' 
                            color='primary' 
                            loading={isSubmitting}
                            onClick={handleSubmit(onSubmit)}>{t('Submit')}</LoadingButton>
                    </Stack>
                </Box>
            </FormProvider>
        </Paper>
    )
}