import { FormProvider, useForm } from "react-hook-form"
import Title from "../shared/Title"
import { useTranslation } from "react-i18next"
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { useTenantId } from "./hooks";
import { useMutation } from "@apollo/client";
import { enqueueSnackbar } from "notistack";
import {  FormTextField } from "../shared/form-components";

import { SEND_TENANT_WHATSAPP_TEST_MESSAGE } from "../graphql/mutations";
import { Box, Button, Divider, Paper, Stack } from "@mui/material";

const testSchema = yup.object({
    tenantId: yup.string().required(),
    phoneNumber: yup.string().required(),
    message: yup.string().required()
});
export default ({}) => {
    const { t } = useTranslation();
    const [tenantId] = useTenantId();
    

    const [sendMessage, {loading}] = useMutation(SEND_TENANT_WHATSAPP_TEST_MESSAGE, {
        refetchQueries: [],
        onCompleted: ()=>{
            enqueueSnackbar(t("OperationDoneSuccessfully"), {variant: "success"});
        }
    });


    const methods = useForm({
        resolver: yupResolver(testSchema),
        mode: 'all',
        values: {
            tenantId: tenantId
        }
    });

    const  { control, handleSubmit, setValue } = methods;

    const onSubmit = async data => {
        await sendMessage({
            variables: {
                ...data
            }
        });
        setValue('message', '');
    }

    return <>
        <FormProvider>
            <Title sx={{marginBottom: 0}}>{t('Send Test Message')}</Title>
            <Divider />
            <Stack spacing={2}>
                <FormTextField 
                    disabled={loading}
                    name='phoneNumber'
                    label={t('PhoneNumber')}
                    control={control}
                />
                <FormTextField 
                    disabled={loading}
                    fullWidth
                    rows={3}
                    multiline={true} 
                    name='message'
                    label={t('Message')}
                    control={control}
                />
            </Stack>
            <Divider />
            <Stack direction="row" justifyContent="space-between">
                <Button disabled={loading} variant='contained' color='primary' onClick={handleSubmit(onSubmit)} >{t('Send')}</Button>
            </Stack>
        </FormProvider>
    </>
}