import { createContext, useContext } from "react";
import { useEffect, useState } from "react"
import { useUser } from "../auth/hooks";

const LS_SS_KEY = 'selectedSpecialists';

export const getStoredSelectedSpecialists = (userId) => {
    try {
        return JSON.parse(localStorage[`${userId}.${LS_SS_KEY}`]) ?? [];
    } catch {
        return [];
    }
}

export const SpecificationsContext = createContext({
    specialists: [],
    setSpecialists: () => {}
});

export const useSpecialists = () => {
    const {specialists, setSpecialists} = useContext(SpecificationsContext);
    return [specialists, setSpecialists];
}

export const SpecialistProvider = ({children}) => {
    const [user] = useUser();
    const [specialists, setSpecialists] = useState([]);
     const value = {
        specialists,
        setSpecialists
     };

     useEffect(()=>{
        if(!user) {
            setSpecialists([]);
        } else {
            setSpecialists(getStoredSelectedSpecialists(user._id));
        }
     },[user]);

     useEffect(()=>{
        if (!user) {
            return;
        }
        localStorage.setItem(`${user._id}.${LS_SS_KEY}`, JSON.stringify(specialists));
     }, [specialists]);

    return (
        <SpecificationsContext.Provider value={value}>
            {children}
        </SpecificationsContext.Provider>
    )
}