import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import DefaultContainer from "../shared/DefaultContainer";
import Title from "../shared/Title";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useQuery } from '@apollo/client';
import { GET_GENERAL_REPORT } from '../graphql/queries';
import { LoadingButton } from '@mui/lab';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import * as moment from 'moment';
import GeneralReport from './GeneralReport';
import { useTenantId } from '../tenant/hooks';
import { Box, Container, Paper } from '@mui/material';

export default () => {
    const { t } = useTranslation();
    const [tenantId] = useTenantId();
    const [from, setFrom] = useState(moment(new Date));
    const [to, setTo] = useState(moment(new Date));

    const { data, loading, refetch } = useQuery(GET_GENERAL_REPORT, {
        variables: {
            from: from.startOf('day').utc(),
            to: to.endOf('day').utc(),
            tenants: [tenantId]
        }
    });

    useEffect(()=> {
        search();
    },[from, to]);

    const search = () => {
        refetch({
            from: from.startOf('day').utc(),
            to: to.endOf('day').utc(),
            tenants: [tenantId]
        })
    }
    
    return (
        <>
            <Container plain>
                <Box pt={2}>
                    <Title>{t('GeneralReport')}</Title>
                </Box>
            </Container>
            <Container>
                <Stack direction="row" spacing={1} sx={{mb:1}}>
                    <DatePicker
                        value={from}
                        onChange={setFrom}
                        slotProps={{
                            textField:{
                                fullWidth: true,
                                size: 'small',
                                disabled:loading,
                                label:t("Start")
                            }
                        }}
                    />
                    <DatePicker
                        value={to}
                        onChange={setTo}
                        slotProps={{
                            textField:{
                                fullWidth: true,
                                size: 'small',
                                disabled:loading,
                                label:t("End")
                            }
                        }}
                    />
                    <LoadingButton 
                        variant='contained' 
                        loading={loading} 
                        onClick={search} 
                        color='primary'>
                        {t('Search')}
                    </LoadingButton>
                </Stack>
            </Container>
            <DefaultContainer>
                {loading ? <LinearProgress /> : undefined }
                <GeneralReport data={data} />
            </DefaultContainer>
        </>
    );
}