import { useTranslation } from 'react-i18next';
import { GET_SPECIALISTS } from '../graphql/queries'
import { useQuery } from '@apollo/client';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
import Autocomplete from '@mui/material/Autocomplete';
import { useSpecialists } from './hooks';
import { hasAccess, useCurrentUserAccess } from '../auth/hooks';
import { useEffect, useMemo } from 'react';
import { useTenantId } from '../tenant/hooks';

const SpecialistSelect = ({
   access
}) => {
    const [tenantId] = useTenantId();
    const { t } = useTranslation();
    const [specialist, setSpecialists] = useSpecialists();
    const userAccess = useCurrentUserAccess();

    const { data, loading } = useQuery(GET_SPECIALISTS, {
        variables: {
            enabled: true,
            tenants: [tenantId]
        }
    })
    
    const handleChange = (event, values) => {
        setSpecialists(values);
    };

    const options = useMemo(() => {
        let opts = data?.getSpecialists || [];
        if (access) {
            opts = opts.filter(({_id}) => {
                return hasAccess(userAccess)({
                    ...access,
                    constraint: 'Specialist',
                    entityRef:_id
                });
            });
        }

        return opts;
    }, [data, access, userAccess])

    useEffect(()=>{
        if (options.length===1 && specialist.length === 0) {
            setSpecialists([options[0]])
        } else if (specialist.length>0 && specialist.some(s=>options.every(o => o._id != s._id)) ) {
            setSpecialists([]);
        }
    }, [options, specialist, setSpecialists]);

    return (
        <FormControl fullWidth>
            <Autocomplete
                multiple
                disabled={loading}
                readOnly={options.length === 1}
                options={options}
                getOptionLabel={(option) => `[${option.code}] ${option.name} (${option.specialty.name})` }
                value={specialist}
                isOptionEqualToValue={(l,r, a) =>  r._id === l._id}
                onChange={handleChange}
                filterSelectedOptions
                renderInput={(params) => (
                <TextField
                    {...params}
                    size='small'
                    label={t('Specialists')}
                    placeholder={t('Specialists')}
                />
                )}
            />
            {( loading ) && <LinearProgress /> }
        </FormControl>
    );

}

export default SpecialistSelect;