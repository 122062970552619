import React from 'react';
import Box from "@mui/material/Box";
import { Typography } from '@mui/material';
import DefaultContainer from '../DefaultContainer';

export default () => {
    return (
    <DefaultContainer>
        <Box textAlign='center' mb={5} mt={5}>
            <Typography variant='caption'>
                Ups! page not found.
            </Typography>
            <Typography variant='h1'>
                404 
            </Typography>
            <Typography variant='subtitle1'>
                Not Found
            </Typography>
        </Box>
    </DefaultContainer>);
}