import { useState } from "react";
import { Container, Box, Fab, Paper } from "@mui/material"
import DefaultContainer from "../../shared/DefaultContainer"
import ModalContainer from "../../shared/ModalContainer"
import Title from "../../shared/Title"
import GroupList from "./GroupList"
import AddIcon from '@mui/icons-material/Add';
import GroupForm from "./GroupForm"
import Modal from '@mui/material/Modal';
import { useMutation, useQuery } from '@apollo/client';
import {GET_GROUPS} from '../../graphql/queries';
import Confirmation from "../../shared/Confirmation";
import { DELETE_GROUPS } from "../../graphql/mutations";
import CustomToolbar from "../../shared/CustomToolbar";
import { HasAccessTo } from "../hooks";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useTenantId } from "../../tenant/hooks";

const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
};

const GroupModal = ({open, onClose, onAdded, groupId}) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <ModalContainer sx={{padding: 0}}>
                <GroupForm 
                    groupId={groupId}
                    onCancel={onClose}
                    onAdded={onAdded}/>
            </ModalContainer>
        </Modal>
    )
}



export default ({}) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [tenantId] = useTenantId();

    const [open,setOpen] = useState(false);

    const [openDeleteConfirmation,setOpenDeleteConfirmation] = useState(false);

    const [selecteds,setSelecteds] = useState([]);

    const {data, loading, refetch} = useQuery(GET_GROUPS, {
        variables: {
            tenants: [tenantId]
        }
    });

    const [deleteGroups, {loading:ldGroups}] = useMutation(DELETE_GROUPS, {
        refetchQueries: [
            GET_GROUPS
        ],
        onCompleted: ()=>{
            enqueueSnackbar(t("OperationDoneSuccessfully"), {variant: "success"});
        }
    });
    const [editGroupId,setEditGroupId] = useState(undefined);

    const switchOpen = () => {
        setOpen(!open);
        if (!!open) {
            setEditGroupId(undefined);
        }
    }


    const handleRowSelectedChanged = (value) => {
        setSelecteds(value);
    }

    const switchDeleteConfirmation = () => {
        setOpenDeleteConfirmation(!openDeleteConfirmation);
    }

    const handleDeleteGroups = async () => {
        switchDeleteConfirmation();
        await deleteGroups({
            variables: {
                groupIds: selecteds
            }
        });
    }

    const handleEditGroup = () => {
        setEditGroupId(selecteds[0]);
        switchOpen();
    }

    return (
        <>
            <Confirmation 
                open={openDeleteConfirmation}
                title={t("Confirmation")}
                description={t("AreYouSureToDeleteTheseGroupsQuestion")}
                onClose={switchDeleteConfirmation}
                onNo={switchDeleteConfirmation}
                onYes={handleDeleteGroups}
            />
            <GroupModal 
                onClose={switchOpen}
                onAdded={switchOpen}
                groupId={editGroupId}
                open={open}/>
            <Container>
                <Box pt={2}>
                    <Title>{t('Groups')}</Title>
                </Box>
            </Container>
            <DefaultContainer sx={{mt:0}} plain>
                <Paper>
                    <GroupList 
                        loading={loading}
                        rows={data?.getGroups || []}
                        onRowSelectedChanged={handleRowSelectedChanged}
                        toolbar={()=><CustomToolbar 
                            entity='Group'
                            selecteds={selecteds} 
                            deleting={ldGroups} 
                            onEdit={handleEditGroup}
                            onDelete={switchDeleteConfirmation}
                        />}
                    />
                </Paper>
            </DefaultContainer>
       
            <HasAccessTo entity="Group" code="CREATE">
                <Fab 
                    onClick={switchOpen}
                    color="primary" 
                    sx={fabStyle}>
                    <AddIcon />
                </Fab>
            </HasAccessTo>
        </>
    )
}