import moment from "moment/moment";
import { useEffect, useState } from "react"



export const useFromNow = (date) => {
    const [text,setText] = useState(moment(date).fromNow());

    useEffect(()=>{
        const intervalId = setInterval(()=>{
            setText(moment(date).fromNow());
        }, 60*1000);
        return () => {
            clearInterval(intervalId);
        }
    },[]);

    return text;
}

export const TimeFromNow = ({date}) => {
    const text = useFromNow(date);
    return text;
}