import {useContext} from 'react';
import IconButton from '@mui/material/IconButton';
import {LanguageModeContext} from "./ThemeSettings";
import {useTranslation} from "react-i18next";
import {SpainFlag, UsFlag} from "./FlagIcons";

const LanguageToggleButton = () => {
    const { changeLocale } = useContext(LanguageModeContext); //theme language (mui components)
    const { i18n } = useTranslation();

    const switchLanguage = (lng) => {
        i18n.changeLanguage(lng);
        changeLocale(lng === 'en' ? 'enUS' : 'esES');
    };

    return (
        <IconButton onClick={() => switchLanguage(i18n.language === 'en' ? 'es' : 'en')} color="inherit" sx={{mr: 0}} size="medium">
            {i18n.language === 'en' ? <SpainFlag/> : <UsFlag/>}
        </IconButton>
    );
};


export default LanguageToggleButton