import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import {ThemeProvider} from '@emotion/react';
import {createTheme} from '@mui/material/styles';
import {SpecialistProvider} from './features/specialist/hooks';

import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import {grey, common} from '@mui/material/colors';

import {ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, from} from '@apollo/client';
import {onError} from '@apollo/client/link/error';

import {BrowserRouter, Link as RouterLink} from 'react-router-dom';
import {setContext} from '@apollo/client/link/context';
import {AuthTokenProvider, AuthUserProvider, useToken, useUser} from './features/auth/hooks';
import Main from './features/main';
import {ConfigurationProvider, useConfiguration} from './features/shared/configuration';

import './i18n';
import {SnackbarProvider} from 'notistack';
import 'moment/locale/es';
import * as moment from 'moment';
import {TenantProvider} from './features/tenant/hooks';
import ColorModeContext, {LanguageModeContext} from "./features/shared/ThemeSettings";
import useThemeContext from "./features/shared/ThemeContext";
import 'moment/locale/es';


const root = ReactDOM.createRoot(document.getElementById('root'));

const LinkBehavior = React.forwardRef < HTMLAnchorElement > ((props, ref) => {
    const {href, ...other} = props;

    // Map href (Material UI) -> to (react-router)
    return <RouterLink ref={ref} to={href} {...other} />;
});

const defaultTheme = createTheme({
    components: {
        MuiLink: {
            defaultProps: {
                component: LinkBehavior,
            },
        },
        MuiButtonBase: {
            defaultProps: {
                component: LinkBehavior,
            },
        }
    },
    palette: {
        grey: {
            main: grey[500],
            contrastText: common.white
        }
    }
});

const generateClient = (uri, token, clearToken, addErrorMessage) => {
    const authLink = setContext((_, {headers}) => {
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : "",
            }
        }
    });

    const httpLink = createHttpLink({
        uri: uri
    });

    const errorLink = onError(({graphQLErrors, networkError}) => {
        let messages = [];
        if (graphQLErrors) {
            for (let err of graphQLErrors) {
                switch (err.message) {
                    // Apollo Server sets code to UNAUTHENTICATED
                    // when an AuthenticationError is thrown in a resolver
                    case "Unauthorized":
                        // Modify the operation context with a new token
                        clearToken && clearToken(clearToken)
                        break
                    default:
                        messages.push(err.message);
                }
            }
        }

        addErrorMessage(messages);
    });

    const nClient = new ApolloClient({
        link: from([errorLink, authLink, httpLink]),
        cache: new InMemoryCache({
            addTypename: false
        }),

    });

    return nClient;
}

const CustomApolloProvider = () => {

    const [token, setToken] = useToken();
    const snackRef = useRef();
    const [{uri}] = useConfiguration();
    const [authenticated, setAuthenticated] = useState((token || '').length > 0);

    const addErrorMessage = (messages) => {
        for (let message of messages) {
            snackRef.current.enqueueSnackbar(message, {
                variant: 'error'
            });
        }
    }

    const clearToken = useCallback(() => {
        setToken('');
        setAuthenticated(false);
    }, [setToken]);

    const [client, setClient] = useState(generateClient(uri, token, clearToken, addErrorMessage));

    useEffect(() => {
        setClient(generateClient(uri, token, clearToken, addErrorMessage));
        if (token) {
            setAuthenticated(true);
        }
    }, [token]);

    const [mode, colorMode, theme, localeMode] = useThemeContext();

    return (
        <ApolloProvider client={client}>
            <AuthUserProvider authenticated={authenticated}>
                <TenantProvider>
                    <SpecialistProvider>
                        <ThemeProvider theme={theme}>
                            <ColorModeContext.Provider value={colorMode}>
                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es">
                                    <LanguageModeContext.Provider value={localeMode}>
                                        <SnackbarProvider ref={snackRef} maxSnack={5}>
                                            <Main authenticated={authenticated}/>
                                        </SnackbarProvider>
                                    </LanguageModeContext.Provider>
                                </LocalizationProvider>
                            </ColorModeContext.Provider>
                        </ThemeProvider>
                    </SpecialistProvider>
                </TenantProvider>
            </AuthUserProvider>
        </ApolloProvider>
    )
}

root.render(
    <React.StrictMode>
        <ConfigurationProvider>
            <AuthTokenProvider>
                <BrowserRouter>
                    <CustomApolloProvider/>
                </BrowserRouter>
            </AuthTokenProvider>
        </ConfigurationProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
