import {Fragment, forwardRef, useRef, useEffect} from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import {
    Box,
    Collapse,
    Divider,
    IconButton,
    ListItemButton as MUIListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Stack,
    Toolbar,
    Tooltip,
    Typography,
    ListSubheader,
    Avatar
} from '@mui/material';
import { 
    ChevronLeft, 
    ChevronRight,
    Person, 
    Groups, 
    CalendarMonth,
    LocalActivity,
    Logout,
    ExpandLess,
    ExpandMore,
    ManageAccounts,
    BackupTable,
    Assessment,
    EditCalendar,
    ArrowLeft,
    Dashboard,
    Close
} from '@mui/icons-material';
import MeetingRoomTwoToneIcon from '@mui/icons-material/MeetingRoomTwoTone';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import HealingIcon from '@mui/icons-material/Healing';
import SummarizeIcon from '@mui/icons-material/Summarize';
import MasksIcon from '@mui/icons-material/Masks';
import GradeIcon from '@mui/icons-material/Grade';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import ForestIcon from '@mui/icons-material/Forest';
import DiscountIcon from '@mui/icons-material/Discount';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import List from '@mui/material/List';
import { HasAccessTo} from '../auth/hooks';
import {useResolvedPath, useMatch, Link} from 'react-router-dom';
import ScoreboardIcon from '@mui/icons-material/Scoreboard';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import TenantSelect from '../tenant/TenantSelect';
import LanguageToggleButton from './LanguageToggleButton';
import ThemeToggleButton from './ThemeToggleButton';
import AccountButton from './AccountButton';
import { useTheme } from '@emotion/react';
import { blue, blueGrey } from "@mui/material/colors";

const SubMenuContainer = styled(Stack)(({
    position: 'fixed', 
    left: '50px',
    alignSelf: 'baseline'
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open' && prop !== 'sidebarWidth',
    skipSx: (prop) => true 
})(
    ({ theme, open, sidebarWidth }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: sidebarWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: '61px',
                [theme.breakpoints.up('sm')]: {
                    width: '61px',
                },
            }),
        },
    }),
);


const MENUS = [
    {
        title: 'Operations',
        icon: <EditCalendar  color="default"/>,
        options: [
            {
                text: 'Dashboard',
                href: '',
                icon: Dashboard
            },
            {
                text: 'Calendar',
                href: 'calendar',
                icon: CalendarMonth,
                access: [{
                    entity: "Appointment",
                    code: "CALENDAR"
                }]
            },
            {
                text: 'Turns',
                href: 'turns',
                icon: LocalActivity,
                access: [{
                    entity: "Turn",
                    code: "HANDLE"
                }]
            },
            {
                text: 'Workspace',
                href: 'workspace',
                icon: HealingIcon,
                access: [{
                    entity: "Specialist",
                    code: "WORKSPACE"
                }]
            },
            {
                text: 'Turn Board',
                href: 'turn-board',
                icon: ScoreboardIcon,
                access: [{
                    entity: "Turn",
                    code: "BOARD"
                }]
            },
            {
                text: 'TimeOffs',
                href: 'timeOffs',
                icon: ForestIcon,
                access: [{
                    entity: "TimeOff"
                }]
            },
        ]
    },
    {
        title: 'Reports',
        icon: <Assessment color="default"/>,
        options: [
            {
                text: 'General Report',
                href: 'general-report',
                icon: SummarizeIcon,
                access: [{
                    entity: "Report",
                    code: "GENERAL"
                }]
            }
        ]
    },
    {
        title: 'Maintenances',
        icon: <BackupTable color="default"/>,
        options: [
            {
                text: 'Appointments',
                href: 'appointments',
                icon: AutoStoriesIcon,
                access: [{
                    entity: "Appointment",
                    code: "VIEW"
                },]
            },
            {
                text: 'Patients',
                href: 'patients',
                icon: MedicalInformationIcon,
                access: [{
                    entity: "Patient"
                }]
            },
            {
                text: 'Offices',
                href: 'offices',
                icon: MeetingRoomTwoToneIcon,
                access: [{
                    entity: "Office"
                }]
            },
            {
                text: 'Specialties',
                href: 'specialties',
                icon: MasksIcon,
                access: [{
                    entity: "Specialty"
                }]
            },
            {
                text: 'Specialists',
                href: 'specialists',
                icon: GradeIcon,
                access: [{
                    entity: "Specialist"
                }]
            },
            {
                text: 'Medical Tests',
                href: 'medical-tests',
                icon: VaccinesIcon,
                access: [{
                    entity: "MedicalTest"
                }]
            },
            {
                text: 'Discounts',
                href: 'discounts',
                icon: DiscountIcon,
                access: [{
                    entity: "Discount"
                }]
            },
            {
                text: 'Services',
                href: 'services',
                icon: MedicalServicesIcon,
                access: [{
                    entity: "Service"
                }]
            },
            {
                text: 'Insurances',
                href: 'insurances',
                icon: HealthAndSafetyIcon,
                access: [{
                    entity: "Insurance"
                }]
            }
        ]
    },
    {
        title: 'Administration',
        icon: <ManageAccounts color="default"/>,
        options: [
            {
                text: 'Users',
                href: 'users',
                icon: Person,
                access: [{
                    entity: "User"
                }]
            },
            {
                text: 'Groups',
                href: 'groups',
                icon: Groups,
                access: [{
                    entity: "Group"
                }]
            }
        ]
    }
]

const ListItemButton = forwardRef((props, ref) => {
    const { to, icon, text } = props;
    const { t } = useTranslation();

    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });

    const tText = t(text);

    return (
        <Tooltip title={tText} placement='right'>
            <MUIListItemButton selected={to && !!match } ref={ref} {...props} >
                <ListItemIcon>
                    {icon}         
                </ListItemIcon>
                <ListItemText primary={t(tText)} />
            </MUIListItemButton>
        </Tooltip>
    );
});


const SubMenu = ({menu, onLinkClick}) => {
        return menu.options && menu.options.map(o => {
                return (
                    <HasAccessTo 
                        key={`${menu.title}-${o.text}`}
                        any={o.access}>
                        <ListItemButton
                            component={Link}
                            onClick={onLinkClick}
                            to={`/${o.href}`}
                            icon={<o.icon />}
                            text={o.text} />
                    </HasAccessTo>
                )});
}

export default ({ isTemporary, open, toggleSidebar, sidebarWidth }) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const handleLinkClicked = () => {
        if (isTemporary) {
            toggleSidebar();
        }
    }

    const render = (
        <Stack height='100dvh' direction='row' divider={<Divider orientation='vertical'></Divider>}>
            <Stack bgcolor={theme.palette.primary.main} spacing={2} paddingTop={1} paddingBottom={1} padding={1} justifyContent='space-between'>
                <Stack alignItems='center'>
                    <IconButton onClick={toggleSidebar}>
                        { isTemporary ? <Close /> : (open ? <ChevronLeft /> : <ChevronRight />)}
                    </IconButton>
                </Stack>
                <Stack alignItems='center' >
                    <LanguageToggleButton />
                    <ThemeToggleButton />
                    <AccountButton />
                </Stack>
            </Stack>
            <Stack sx={{width: '100%'}} divider={<Divider  />}>
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [1],
                        overflow: 'hidden'
                    }}
                >
                    <Stack flexGrow={1} direction='row' spacing={1}>
                        <TenantSelect />
                    </Stack>
                </Toolbar>
                <Box flexShrink={1} overflow='auto'>
                    <Stack justifyContent='space-between' flexGrow={1} sx={{overflowY: 'auto'}}>
                        { MENUS.map(m => {
                            const accessPermissions = m.options?.filter(a=>a.access)?.reduce((a,b)=>a.concat(b.access), []) || [];
                            return (
                            <Fragment key={m.title}>
                                <HasAccessTo any={accessPermissions}>
                                    <List component='nav' subheader={
                                        (<ListSubheader>
                                            {m.title?.toUpperCase()}
                                        </ListSubheader>)
                                    }>
                                        <SubMenu menu={m} addSpace={true} onLinkClick={handleLinkClicked} />
                                    </List>
                                </HasAccessTo>
                            </Fragment>);
                            })
                        }
                    </Stack>
                </Box>
            </Stack>
        </Stack>
    );

    if (isTemporary) {
        return (
            <MuiDrawer open={open} onClose={toggleSidebar}>
                {render}
            </MuiDrawer>
        );
    }

    return (
        <Drawer variant='permanent' open={open} sidebarWidth={sidebarWidth}>
            {render}
        </Drawer>
    );
}