import { gql } from '@apollo/client'

export const CREATE_APPOINTMENT = gql`
    mutation createAppointment($tenants:[String!]!, $input: CreateAppointmentInput!) {
        createAppointment(input: $input, tenants: $tenants) {
            _id,
            date,
            status,
            time,
            patient {
                _id,
                patientName,
                tutorFirstName,
                tutorLastName
            },
            service {
                name
            },
            specialist {
                name
            },
            totalAmount,
            commissionAmount,
            createdAt
        }
    }
`;

export const QUEUE_APPOINTMENT = gql`
    mutation queueAppointment($appointmentId: String!) {
        queueAppointment(appointmentId: $appointmentId) {
            _id,
            status
        }
    }
`;

export const REQUEST_NEXT_APPOINTMENT = gql`
    mutation requestNextAppointment($specialistId: String!, $date: DateTime!) {
        requestNextAppointment(specialistId: $specialistId, date: $date) {
            _id,
            status
        }
    }
`;

export const COMPLETE_APPOINTMENT = gql`
    mutation completeAppointment($appointmentId: String!) {
        completeAppointment(appointmentId: $appointmentId) {
            _id
        }
    }
`;

export const WRITE_APPOINTMENT_COMMENT = gql`
    mutation writeAppointmentComment($appointmentId: String!, $comment: String!) {
        writeAppointmentComment(appointmentId: $appointmentId, comment: $comment) {
            _id
        }
    }
`;

export const SET_APPOINTMENT_CONFIRMATION = gql`
    mutation setAppointmentConfirmation($appointmentId: String!, $confirm: Boolean!) {
        setAppointmentConfirmation(appointmentId: $appointmentId, confirm: $confirm) {
            _id
        }
    }
`;

export const UPDATE_APPOINTMENT_REQUESTED_MEDICAL_TESTS = gql`
    mutation updateAppointmentRequestedMedicalTests($appointmentId: String!, $medicalTests: [MedicalTestInput!]!) {
        updateAppointmentRequestedMedicalTests(appointmentId: $appointmentId, medicalTests: $medicalTests) {
            _id
        }
    }
`;

export const UPDATE_APPOINTMENT = gql`
    mutation updateAppointment($input: UpdateAppointmentInput!) {
        appointment: updateAppointment(input: $input) {
            _id
        }
    }`;

export const CANCEL_APPOINTMENT = gql`
    mutation cancelAppointment($appointmentId: String!) {
        appointment: cancelAppointment(appointmentId: $appointmentId) {
            _id
        }
    }`;

export const DELETE_APPOINTMENTS = gql`
mutation deleteAppointments($appointmentIds: [String!]!) {
    deleteAppointments(appointmentIds: $appointmentIds) {
        _id
    }
}`;


export const LOGIN = gql`
    mutation login($input: CredentialInput!) {
        login(input: $input) {
            access_token
            user {
                _id,
                fullName
            }
        }
    }`;

export const GENERATE_RESET_PASSWORD_CODE = gql`
    mutation generateResetPasswordCode($email: String!) {
        generateResetPasswordCode(email: $email) {
            success
        }
    }`;

export const CREATE_SIGN_UP_CODE = gql`
    mutation createSignUpCode($email: String!) {
        createSignUpCode(email: $email) {
            success
        }
    }`;

export const RESET_PASSWORD = gql`
    mutation resetPassword($input: ResetPasswordInput!) {
        resetPassword(input: $input){
            success
        }
    }`;

export const CHANGE_PASSWORD = gql`
    mutation changePassword($input: ChangePasswordInput!) {
        changePassword(input: $input){
            success
        }
    }`;

export const SIGN_UP_USER = gql`
    mutation signUpUser($input: SignUpInput!) {
        signUpUser(input: $input){
            success
        }
    }`;


export const CREATE_USER = gql`
    mutation createUser($tenants:[String!]!, $input: CreateUserInput!) {
        user:createUser(input: $input, tenants: $tenants) {
            _id
        }
    }`;

export const UPDATE_USER = gql`
    mutation updateUser($input: UpdateUserInput!) {
        user: updateUser(input: $input) {
            _id
        }
    }`;

export const DELETE_USERS = gql`
mutation deleteUsers($userIds: [String!]!) {
    deleteUsers(userIds: $userIds) {
        _id
    }
}`;

export const CREATE_GROUP = gql`
    mutation createGroup($tenants:[String!]!, $input: CreateGroupInput!) {
        group:createGroup(input: $input, tenants: $tenants) {
            _id
        }
    }`;

export const UPDATE_GROUP = gql`
    mutation updateGroup($input: UpdateGroupInput!) {
        group: updateGroup(input: $input) {
            _id
        }
    }`;

export const DELETE_GROUPS = gql`
mutation deleteGroups($groupIds: [String!]!) {
    deleteGroups(groupIds: $groupIds) {
        _id
    }
}`;

export const DELETE_PATIENTS = gql`
mutation deletePatients($patientIds: [String!]!) {
    deletePatients(patientIds: $patientIds) {
        _id
    }
}`;

export const DELETE_OFFICES = gql`
mutation deleteOffices($officeIds: [String!]!) {
    deleteOffices(officeIds: $officeIds) {
        _id
    }
}`;

export const CREATE_PATIENT = gql`
    mutation createPatient($tenants:[String!]!, $input: CreatePatientInput!) {
        createPatient(input: $input, tenants: $tenants) {
            _id
        }
    }
`;

export const UPDATE_PATIENT = gql`
    mutation updatePatient($input: UpdatePatientInput!) {
        updatePatient(input: $input) {
            _id
        }
    }
`;

export const CREATE_OFFICE = gql`
    mutation createOffice($tenants:[String!]!, $input: CreateOfficeInput!) {
        createOffice(input: $input, tenants: $tenants) {
            _id
        }
    }
`;

export const UPDATE_OFFICE = gql`
    mutation updateOffice($input: UpdateOfficeInput!) {
        updateOffice(input: $input) {
            _id
        }
    }
`;

export const CREATE_SPECIALTY = gql`
    mutation createSpecialty($tenants:[String!]!, $input: CreateSpecialtyInput!) {
        createSpecialty(input: $input, tenants: $tenants) {
            _id
        }
    }
`;

export const UPDATE_SPECIALTY = gql`
    mutation updateSpecialty($input: UpdateSpecialtyInput!) {
        updateSpecialty(input: $input) {
            _id
        }
    }
`;

export const DELETE_SPECIALTIES = gql`
    mutation deleteSpecialties($specialtyIds: [String!]!) {
        deleteSpecialties(specialtyIds: $specialtyIds) {
            _id
        }
    }
`;

export const CREATE_MEDICAL_TEST = gql`
    mutation createMedicalTest($tenants:[String!]!, $input: CreateMedicalTestInput!) {
        createMedicalTest(input: $input, tenants: $tenants) {
            _id
        }
    }
`;

export const UPDATE_MEDICAL_TEST = gql`
    mutation updateMedicalTest($input: UpdateMedicalTestInput!) {
        updateMedicalTest(input: $input) {
            _id
        }
    }
`;

export const DELETE_MEDICAL_TESTS = gql`
    mutation deleteMedicalTests($medicalTestIds: [String!]!) {
        deleteMedicalTests(medicalTestIds: $medicalTestIds) {
            _id
        }
    }
`;

export const CREATE_SPECIALIST = gql`
    mutation createSpecialist($tenants:[String!]!, $input: CreateSpecialistInput!) {
        createSpecialist(input: $input, tenants: $tenants) {
            _id
        }
    }
`;

export const UPDATE_SPECIALIST = gql`
    mutation updateSpecialist($input: UpdateSpecialistInput!) {
        updateSpecialist(input: $input) {
            _id
        }
    }
`;

export const DELETE_SPECIALISTS = gql`
    mutation deleteSpecialists($specialistIds: [String!]!) {
        deleteSpecialists(specialistIds: $specialistIds) {
            _id
        }
    }
`;

export const CREATE_TIME_OFF = gql`
    mutation createSpecialist($tenants:[String!]!, $input: CreateTimeOffInput!) {
        timeOff: createTimeOff(input: $input, tenants: $tenants) {
            _id
        }
    }
`;

export const UPDATE_TIME_OFF = gql`
    mutation updateTimeOff($input: UpdateTimeOffInput!) {
        timeOff: updateTimeOff(input: $input) {
            _id
        }
    }
`;

export const DELETE_TIME_OFFS = gql`
    mutation deleteTimeOffs($timeOffIds: [String!]!) {
        deleteTimeOffs(timeOffIds: $timeOffIds) {
            _id
        }
    }
`;


export const CREATE_DISCOUNT = gql`
    mutation createDiscount($tenants:[String!]!, $input: CreateDiscountInput!) {
        createDiscount(input: $input, tenants: $tenants) {
            _id
        }
    }
`;

export const UPDATE_DISCOUNT = gql`
    mutation updateDiscount($input: UpdateDiscountInput!) {
        updateDiscount(input: $input) {
            _id
        }
    }
`;

export const DELETE_DISCOUNTS = gql`
    mutation deleteDiscounts($discountIds: [String!]!) {
        deleteDiscounts(discountIds: $discountIds) {
            _id
        }
    }
`;


export const CREATE_SERVICE = gql`
    mutation createService($tenants:[String!]!, $input: CreateServiceInput!) {
        createService(input: $input, tenants: $tenants) {
            _id
        }
    }
`;

export const UPDATE_SERVICE = gql`
    mutation updateService($input: UpdateServiceInput!) {
        updateService(input: $input) {
            _id
        }
    }
`;

export const DELETE_SERVICES = gql`
    mutation deleteServices($serviceIds: [String!]!) {
        deleteServices(serviceIds: $serviceIds) {
            _id
        }
    }
`;

export const CREATE_INSURANCE = gql`
    mutation createInsurance($tenants:[String!]!, $input: CreateInsuranceInput!) {
        createInsurance(input: $input, tenants: $tenants) {
            _id
        }
    }
`;

export const UPDATE_INSURANCE = gql`
    mutation updateInsurance($input: UpdateInsuranceInput!) {
        updateInsurance(input: $input) {
            _id
        }
    }
`;

export const DELETE_INSURANCES = gql`
    mutation deleteInsurances($insuranceIds: [String!]!) {
        deleteInsurances(insuranceIds: $insuranceIds) {
            _id
        }
    }
`;

export const SET_TURN_PRIORITY = gql`
    mutation setTurnPriority($turnId: String!, $priority: Int!) {
        setTurnPriority(turnId: $turnId, priority: $priority) {
            _id
        }
    }
`

export const CREATE_TAG = gql`
    mutation createTag($tenants:[String!]!, $input: CreateTagInput!) {
        createTag(input: $input, tenants: $tenants) {
            _id,
            name,
            description
        }
    }
`;

export const UPDATE_TAG = gql`
    mutation updateTag($tenants:[String!]!, $input: UpdateTagInput!) {
        updateTag(input: $input, tenants: $tenants) {
            _id
        }
    }
`;

export const DELETE_TAGS = gql`
    mutation deleteTags($tagIds: [String!]!) {
        deleteTags(tagIds: $tagIds) {
            _id
        }
    }
`;

export const UPDATE_TENANT = gql`
    mutation updateTenant($input: UpdateTenantInput!) {
        updateTenant(input: $input) {
            _id
        }
    }
`;

export const CREATE_TENANT = gql`
    mutation createTenant($input: CreateTenantInput!) {
        createTenant(input: $input) {
            _id
        }
    }
`;

export const START_TENANT_WHATSAPP_SESSION = gql`
    mutation startTenantWhatsAppSession($tenantId: String!) {
        startTenantWhatsAppSession(tenantId: $tenantId) {
            _id
        }
    }
`;

export const LOGOUT_TENANT_WHATSAPP_SESSION = gql`
    mutation logoutTenantWhatsAppSession($tenantId: String!) {
        logoutTenantWhatsAppSession(tenantId: $tenantId) {
            _id
        }
    }
`;

export const SEND_TENANT_WHATSAPP_TEST_MESSAGE = gql`
    mutation sendTenantWhatsAppTestMessage($tenantId: String!, $phoneNumber: String!, $message: String!) {
        sendTenantWhatsAppTestMessage(tenantId: $tenantId, phoneNumber: $phoneNumber, message: $message) {
            _id
        }
    }
`;

export const CONFIRM_APPOINTMENT_BY_CONFIRMATION_ACCESS_TOKEN = gql`
    mutation confirmAppointmentByConfirmationAccessToken($confirmationAccessToken: String!, $confirm: Boolean!) {
        confirmAppointmentByConfirmationAccessToken(confirmationAccessToken: $confirmationAccessToken, confirm: $confirm) {
            _id
        }
    }
`;


export const VERIFY_ACCOUNT = gql`
    mutation verifyUserByCode($verificationCode: String!) {
        verifyUserByCode(verificationCode: $verificationCode) {
            success
        }
    }
`;