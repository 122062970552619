import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from '@mui/material';
import { useTranslation } from 'react-i18next';


export default ({
    title,
    description,
    onYes,
    onNo,
    onClose,
    open
}) => {
    const {t} = useTranslation();
    return (
    <Dialog
        open={open}
        onClose={onClose}
    >
        <DialogTitle>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button data-testid="confirmation-no-btn" onClick={onNo}>{t('No')}</Button>
          <Button data-testid="confirmation-yes-btn" variant='contained' color='success' onClick={onYes} autoFocus>
            {t('Yes')}
          </Button>
        </DialogActions>
      </Dialog>
    );
}