import { useEffect, useState } from "react";
import { Container, Box, Fab, Paper } from "@mui/material"
import DefaultContainer from "../shared/DefaultContainer"
import ModalContainer from "../shared/ModalContainer"
import Title from "../shared/Title"
import AddIcon from '@mui/icons-material/Add';
import Modal from '@mui/material/Modal';
import { useMutation, useQuery } from '@apollo/client';
import {GET_PAGINATED_TIME_OFFS} from '../graphql/queries';
import Confirmation from "../shared/Confirmation";
import CustomToolbar from "../shared/CustomToolbar";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import TimeOffForm from "./TimeOffForm";
import TimeOffList from "./TimeOffList";
import { DELETE_TIME_OFFS } from "../graphql/mutations";
import { HasAccessTo, useAccessConstraints } from "../auth/hooks";
import { useTenantId } from "../tenant/hooks";

const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
};

const TimeOffModal = ({open, onClose, onAdded, timeOffId}) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <ModalContainer sx={{padding: 0}}>
                <TimeOffForm
                    timeOffId={timeOffId}
                    onCancel={onClose}
                    onAdded={onAdded}/>
            </ModalContainer>
        </Modal>
    )
}

const TimeOffMaintenance = () => {
    const { t } = useTranslation();
    const [tenantId] = useTenantId();

    const { enqueueSnackbar } = useSnackbar();

    const [open,setOpen] = useState(false);

    const [openDeleteConfirmation,setOpenDeleteConfirmation] = useState(false);

    const [selecteds,setSelecteds] = useState([]);

    const specialistIds = useAccessConstraints({
        entity: "TimeOff",
        code: "VIEW",
        constraint: "Specialist"
    });

    const {data, loading, refetch} = useQuery(GET_PAGINATED_TIME_OFFS, {
        variables: {
            skip:0,
            limit: 10,
            filter: {
                specialistIds: specialistIds
            },
            tenants: [tenantId]
        }
    });

    const [sort, setSort] = useState(undefined);
    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(10);
    const [filter, setFilter] = useState({
        specialistIds: specialistIds
    });
    
    const [deleteTimeOffs, {loading:ldTimeOffs}] = useMutation(DELETE_TIME_OFFS, {
        refetchQueries: [
            GET_PAGINATED_TIME_OFFS
        ],
        onCompleted: ()=>{
            enqueueSnackbar(t("OperationDoneSuccessfully"), {variant: "success"});
        }
    });
    const [editTimeOffId,setEditTimeOffId] = useState(undefined);

    useEffect(()=>{
        refetch({
            sort: sort,
            skip: skip,
            limit: limit,
            filter: filter,
            tenants: [tenantId]
        });
    }, [sort, skip, limit, filter, refetch]);
    
    const switchOpen = () => {
        setOpen(!open);
        if (!!open) {
            setEditTimeOffId(undefined);
        }
    }

    const handleRowSelectedChanged = (value) => {
        setSelecteds(value);
    }

    const switchDeleteConfirmation = () => {
        setOpenDeleteConfirmation(!openDeleteConfirmation);
    }

    const handleDeleteTimeOffs = async () => {
        switchDeleteConfirmation();
        await deleteTimeOffs({
            variables: {
                timeOffIds: selecteds
            }
        });
    }

    const handleEditTimeOff = () => {
        setEditTimeOffId(selecteds[0]);
        switchOpen();
    }

    const handlePaginationModelChanged = (e) => {
        setSkip(e.page*e.pageSize);
        setLimit(e.pageSize);
    }

    const handleSortModelChanged = (sorts) => {
        const sort = sorts.reduce((p,c)=>{
            p[c.field] = c.sort === 'asc' ? 'Asc' : 'Desc';
            return p;
        }, {});
        setSort(sort);
    }

    const handleFilterModelChanged = (e) => {
        const nFilter =  e.items.reduce((p, c) => {
            p[c.field] = c.value;
            return p;
        }, {});
        setFilter({
            nFilter,
            specialistIds: specialistIds
        });
    }

    const handleTimeOffAdded = async (timeOff) => {
        switchOpen();
    }

    const rows = data?.paginatedResults?.rows;
    
    return (
        <>
            <Confirmation 
                open={openDeleteConfirmation}
                title={t("Confirmation")}
                description={t("AreYouSureToDeleteTheseTimeOffsQuestion")}
                onClose={switchDeleteConfirmation}
                onNo={switchDeleteConfirmation}
                onYes={handleDeleteTimeOffs}
            />
            <TimeOffModal 
                onClose={switchOpen}
                onAdded={handleTimeOffAdded}
                timeOffId={editTimeOffId}
                open={open}/>
            <Container>
                <Box pt={2}>
                    <Title>{t('TimeOffs')}</Title>
                </Box>
            </Container>
            <DefaultContainer sx={{mt:0}} plain>
                <Paper>
                    <TimeOffList 
                        loading={loading}
                        onSortModelChange={handleSortModelChanged}
                        onPaginationModelChange={handlePaginationModelChanged}
                        rows={rows || []}
                        count={data?.paginatedResults?.count || 0}
                        onFilterModelChange={handleFilterModelChanged}
                        onRowSelectedChanged={handleRowSelectedChanged}
                        toolbar={()=><CustomToolbar 
                            entity='TimeOff'
                            selecteds={selecteds} 
                            deleting={ldTimeOffs} 
                            constraint='Specialist'
                            getEntityRefProp={(_id) => rows?.find(s=>s._id === _id)?.specialist?._id}
                            onEdit={handleEditTimeOff}
                            onDelete={switchDeleteConfirmation}
                        >
                        </CustomToolbar>}
                        />
                </Paper>
            </DefaultContainer>
                            
            <HasAccessTo 
                entity="TimeOff"
                code="CREATE"
            >
                <Fab 
                    onClick={switchOpen}
                    color="primary" 
                    sx={fabStyle}>
                    <AddIcon />
                </Fab>
            </HasAccessTo>
        </>
    )
}

export default TimeOffMaintenance;