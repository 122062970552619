import { Box } from '@mui/material';
import {DataGrid} from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next';

const buildColumns = (t) => [
    { field: '_id', headerName: t('ID'), width: 250, },
    { field: 'name', headerName: t('Name'), width: 250 },
    { field: 'code', headerName: t('Code'), width: 250 },
    { 
      field: 'specialty.name', 
      headerName: t('Specialty'), 
      width: 250,
      valueGetter: ({row})=>row.specialty?.name
    }, 
    { 
      field: 'office.name', 
      headerName: t('Office'), 
      width: 250,
      valueGetter: ({row})=>row.office?.name
    },
   ];
  
  export default ({
      rows, 
      count, 
      loading, 
      onFilterModelChange, 
      onSortModelChange, 
      onPaginationModelChange, 
      onRowSelectedChanged,
      toolbar
    }) => {
    const {t} = useTranslation();
    const columns = buildColumns(t);
  
    return (
      <Box sx={{ height: 450, width: '100%' }}>
        <DataGrid
          density='compact'
          rows={rows}
          columns={columns}
          rowCount={count}
          loading={loading}
          getRowId={(r)=>r._id}
          onRowSelectionModelChange={onRowSelectedChanged}
          initialState={{
            columns: {
              columnVisibilityModel: {
                _id: false
              }
            },
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 15, 20]}
          onPaginationModelChange={onPaginationModelChange}
          checkboxSelection
          onSortModelChange={onSortModelChange}
          onFilterModelChange={onFilterModelChange}
          slots={{
            toolbar: toolbar
          }}
        />
      </Box>
    );
  }