import { useState } from "react";
import { Container, Box, Fab, Stack, IconButton, Tooltip, Typography, CircularProgress, Button, Paper, styled } from "@mui/material"
import DefaultContainer from "../shared/DefaultContainer"
import ModalContainer from "../shared/ModalContainer"
import Title from "../shared/Title"
import SpecialistList from "./SpecialistList"
import AddIcon from '@mui/icons-material/Add';
import SpecialistForm from "./SpecialistForm"
import Modal from '@mui/material/Modal';
import { useMutation, useQuery } from '@apollo/client';
import {DELETE_SPECIALISTS} from '../graphql/mutations';
import { GET_SPECIALISTS } from '../graphql/queries';
import Confirmation from "../shared/Confirmation";
import { HasAccessTo } from "../auth/hooks";
import CustomToolbar from "../shared/CustomToolbar";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useTenantId } from "../tenant/hooks";

const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
};


const SpecialistModal = ({open, onClose, onAdded, specialistId}) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <ModalContainer width={610} sx={{padding: 0}}>
                <SpecialistForm 
                    specialistId={specialistId}
                    onCancel={onClose}
                    onAdded={onAdded}/>
            </ModalContainer>
        </Modal>
    )
}

export default ({}) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    
    const [tenantId] = useTenantId();

    const [open,setOpen] = useState(false);

    const [openDeleteConfirmation,setOpenDeleteConfirmation] = useState(false);

    const [selecteds,setSelecteds] = useState([]);

    const {data, loading} = useQuery(GET_SPECIALISTS, {
        variables: {
            tenants: [tenantId]
        }
    });

    const [deleteSpecialists, {loading:ldSpecialists}] = useMutation(DELETE_SPECIALISTS, {
        refetchQueries: [
            GET_SPECIALISTS
        ],
        onCompleted: ()=>{
            enqueueSnackbar(t("OperationDoneSuccessfully"), {variant: "success"});
        }
    });
    const [editSpecialistId,setEditSpecialistId] = useState(undefined);

    const switchOpen = () => {
        setOpen(!open);
        if (!!open) {
            setEditSpecialistId(undefined);
        }
    }

    const handleRowSelectedChanged = (value) => {
        setSelecteds(value);
    }

    const switchDeleteConfirmation = () => {
        setOpenDeleteConfirmation(!openDeleteConfirmation);
    }

    const handleDeleteSpecialists = async () => {
        switchDeleteConfirmation();
        await deleteSpecialists({
            variables: {
                specialistIds: selecteds
            }
        });
    }

    const handleEditSpecialist = () => {
        setEditSpecialistId(selecteds[0]);
        switchOpen();
    }

    return (
        <>
            <Confirmation 
                open={openDeleteConfirmation}
                title={t("Confirmation")}
                description={t("AreYouSureToDeleteTheseSpecialistsQuestion")}
                onClose={switchDeleteConfirmation}
                onNo={switchDeleteConfirmation}
                onYes={handleDeleteSpecialists}
            />
            <SpecialistModal 
                onClose={switchOpen}
                onAdded={switchOpen}
                specialistId={editSpecialistId}
                open={open}/>
            <Container>
                <Box pt={2}>
                    <Title>{t('Specialists')}</Title>
                </Box>
            </Container>

            <DefaultContainer sx={{mt:0}} plain>
                <Paper>
                    <SpecialistList 
                        loading={loading}
                        rows={data?.getSpecialists || []}
                        onRowSelectedChanged={handleRowSelectedChanged}
                        toolbar={()=><CustomToolbar
                            entity="Specialist"
                            selecteds={selecteds} 
                            deleting={ldSpecialists} 
                            onEdit={handleEditSpecialist}
                            onDelete={switchDeleteConfirmation}
                        />}
                    />
                </Paper>
            </DefaultContainer>
       
            <HasAccessTo entity="Specialist" code="CREATE">
                <Fab 
                    onClick={switchOpen}
                    color="primary" 
                    sx={fabStyle}>
                    <AddIcon />
                </Fab>
            </HasAccessTo>
        </>
    )
}