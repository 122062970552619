import {useState} from "react";
import {Container, Box, Fab, Paper, Fade} from "@mui/material"
import DefaultContainer from "../shared/DefaultContainer"
import ModalContainer from "../shared/ModalContainer"
import Title from "../shared/Title"
import ServiceList from "./ServiceList"
import AddIcon from '@mui/icons-material/Add';
import ServiceForm from "./ServiceForm"
import Modal from '@mui/material/Modal';
import {useMutation, useQuery} from '@apollo/client';
import {DELETE_SERVICES} from '../graphql/mutations';
import {GET_SERVICES} from '../graphql/queries';
import Confirmation from "../shared/Confirmation";
import CustomToolbar from "../shared/CustomToolbar";
import {HasAccessTo} from "../auth/hooks";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";
import {useTenantId} from "../tenant/hooks";

const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
};

const ServiceModal = ({open, onClose, onAdded, serviceId}) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            closeAfterTransition
        >
            <Fade in={open}>
                <ModalContainer width={500}>
                    <ServiceForm
                        serviceId={serviceId}
                        onCancel={onClose}
                        onAdded={onAdded}/>
                </ModalContainer>
            </Fade>
        </Modal>
    )
}

export default ({}) => {
    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();

    const [tenantId] = useTenantId();

    const [open, setOpen] = useState(false);

    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

    const [selecteds, setSelecteds] = useState([]);

    const {data, loading} = useQuery(GET_SERVICES, {
        variables: {
            tenants: [tenantId]
        }
    });

    const [deleteServices, {loading: ldServices}] = useMutation(DELETE_SERVICES, {
        refetchQueries: [
            GET_SERVICES
        ],
        onCompleted: () => {
            enqueueSnackbar(t("OperationDoneSuccessfully"), {variant: "success"});
        }
    });
    const [editServiceId, setEditServiceId] = useState(undefined);

    const switchOpen = () => {
        setOpen(!open);
        if (!!open) {
            setEditServiceId(undefined);
        }
    }

    const handleRowSelectedChanged = (value) => {
        setSelecteds(value);
    }

    const switchDeleteConfirmation = () => {
        setOpenDeleteConfirmation(!openDeleteConfirmation);
    }

    const handleDeleteServices = async () => {
        switchDeleteConfirmation();
        await deleteServices({
            variables: {
                serviceIds: selecteds
            }
        });
    }

    const handleEditService = () => {
        setEditServiceId(selecteds[0]);
        switchOpen();
    }

    return (
        <>
            <Confirmation
                open={openDeleteConfirmation}
                title={t("Confirmation")}
                description={t("AreYouSureToDeleteTheseServicesQuestion")}
                onClose={switchDeleteConfirmation}
                onNo={switchDeleteConfirmation}
                onYes={handleDeleteServices}
            />
            <ServiceModal
                onClose={switchOpen}
                onAdded={switchOpen}
                serviceId={editServiceId}
                open={open}/>
            <Container>
                <Box pt={2}>
                    <Title>{t('Services')}</Title>
                </Box>
            </Container>

            <DefaultContainer sx={{mt: 0}} plain>
                <Paper>
                    <ServiceList
                        loading={loading}
                        rows={data?.getServices || []}
                        onRowSelectedChanged={handleRowSelectedChanged}
                        toolbar={() => <CustomToolbar
                            entity="Service"
                            selecteds={selecteds}
                            deleting={ldServices}
                            onEdit={handleEditService}
                            onDelete={switchDeleteConfirmation}
                        />}
                    />
                </Paper>
            </DefaultContainer>

            <HasAccessTo entity="Service" code="CREATE">
                <Fab
                    onClick={switchOpen}
                    color="primary"
                    sx={fabStyle}>
                    <AddIcon/>
                </Fab>
            </HasAccessTo>
        </>
    )
}