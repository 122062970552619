import {useContext, useMemo, useState} from 'react';
import IconButton from '@mui/material/IconButton';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import {useTheme} from "@mui/material";
import ColorModeContext from "./ThemeSettings";

const ThemeToggleButton = () => {
    const { toggleColorMode } = useContext(ColorModeContext);
    const theme = useTheme();

    return (
        <IconButton onClick={toggleColorMode} sx={{mr: 0}} size="medium">
            {theme.palette.mode === 'dark' ? <Brightness7Icon fontSize="medium" /> : <Brightness4Icon fontSize="medium"/>}
        </IconButton>
    );
};


export default ThemeToggleButton;