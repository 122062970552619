import { createContext, useContext, useEffect, useState } from "react";
import { useUser } from "../auth/hooks";

const TENANT_TOKEN_KEY = 'currentTenantId';

export const TenantContext = createContext({
    tenantId: '',
    setTenantId: () => {},
    tenant: undefined
});

export const useTenantId = () => {
    const {tenantId, setTenantId, tenant} = useContext(TenantContext);
    return [tenantId, setTenantId];
}

export const useTenant = () => {
    const {tenant} = useContext(TenantContext);
    return [tenant];
}

export const TenantProvider = ({children}) => {
    const [user] = useUser();
    const [tenantId, setTenantId] = useState(localStorage.getItem(TENANT_TOKEN_KEY));
    const [tenant, setTenant] = useState(undefined);
    const value = {
        tenant,
        tenantId,
        setTenantId
    };

    useEffect(()=>{
        if (!user) {
            return;
        }

        if (user.tenants.length==0) {
            return;
        }

        let currentTenant = user.tenants.find(t => t._id == tenantId);

        if (!currentTenant) {
            currentTenant = user.tenants[0];
            setTenantId(currentTenant._id);
        }

        setTenant(currentTenant);
    }, [user, tenantId]);

    useEffect(()=>{
        localStorage.setItem(TENANT_TOKEN_KEY, tenantId);
    }, [tenantId]);

    return (
        <TenantContext.Provider value={value}>
            {children}
        </TenantContext.Provider>
    )
}
