import { Paper, Slide } from '@mui/material';
import Box from '@mui/material/Box';

import { Stack } from '@mui/system';
import {  useEffect, useState } from 'react';
import MainBox from '../shared/MainBox';
import SendSignUpCodeForm from './sign-up/SendSignUpCodeForm';
import LoginForm from './login/LoginForm';
import SendResetPasswordCodeForm from './reset-password/SendResetPasswordCodeForm';
import FooterSignature from '../shared/FooterSignature';
import { useNavigate } from 'react-router-dom';

export const FORM_LOGIN = 'LOGIN';
export const FORM_RESET_PASSWORD = 'RESET_PASSWORD';
export const FORM_SIGN_UP = 'SIGN_UP';

export default ({formMode = FORM_LOGIN}) => {
    const [form, setForm] = useState(formMode);
    const navigate = useNavigate();

    useEffect(()=>{
        if (form == FORM_LOGIN  && formMode!=FORM_LOGIN) {
            navigate('/');
        }
    }, [form]);

    const setFormByName = (name) => () => {
        setForm(name);
    }

    return <MainBox alignItems="center" justifyContent="center">
            <Slide direction='right' in={true} mountOnEnter timeout={1000}>
                <Stack sx={{width: '300px'}} alignItems="center">
                    <Paper sx={{width:'100%'}} elevation={3}>
                        {form == FORM_LOGIN && <LoginForm 
                            switchToResetPassword={setFormByName(FORM_RESET_PASSWORD)} 
                            switchToSignUp={setFormByName(FORM_SIGN_UP)}
                        /> }
                        {form == FORM_RESET_PASSWORD && <SendResetPasswordCodeForm switchToLogin={setFormByName(FORM_LOGIN)} /> }
                        {form == FORM_SIGN_UP && <SendSignUpCodeForm switchToLogin={setFormByName(FORM_LOGIN)} /> }
                    </Paper>
                   <Box p={2}>
                        <FooterSignature />
                    </Box>
                </Stack>
            </Slide>
        </MainBox>
}