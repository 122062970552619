import Layout from './shared/Layout'
import AppointmentPage from './appointment/AppointmentPage';
import DayTurnListPage from './turn/DayTurnListPage';

import {
    Route,
    Routes,
    
} from "react-router-dom";
import GeneralReportPage from './report/GeneralReportPage';
import { HasAccessTo, useUser } from './auth/hooks';
import WorkspacePage from './workspace/WorkspacePage';
import Box from '@mui/material/Box';
import UserMaintenance from './auth/user/UserMaintenance';
import GroupMaintenance from './auth/group/GroupMaintenance';
import PatientMaintenance from './patient/PatientMaintenance';
import OfficeMaintenance from './office/OfficeMaintenance';
import SpecialtyMaintenance from './specialty/SpecialtyMaintenance';
import MedicalTestMaintenance from './medical-test/MedicalTestMaintenance';
import SpecialistMaintenance from './specialist/SpecialistMaintenance';
import DiscountMaintenance from './discount/DiscountMaintenance';
import ServiceMaintenance from './service/ServiceMaintenance';
import AppointmentMaintenance from './appointment/AppointmentMaintenance';
import AppointmentExternalConfirmationView from './appointment/AppointmentExternalConfirmationView';
import InsuranceMaintenance from './insurance/InsuranceMaintenance';
import TurnDashboard from './turn/TurnBoard';
import NotFoundPage from './shared/pages/NotFoundPage';
import UnauthorizedPage from './shared/pages/UnauthorizedPage';
import TimeOffMaintenance from './timeOff/TimeOffMaintenance';
import Dashboard from './dashboard/Dashboard';
import { Slide } from '@mui/material';
import TenantForm from './tenant/TenantForm';
import EntryForm, { FORM_SIGN_UP } from './auth/EntryForm';
import ResetPasswordForm from './auth/reset-password/ResetPasswordForm';
import SignUpForm from './auth/sign-up/SignUpForm';
import NetworkErrorPage from './shared/pages/NetworkErrorPage';
import MainBox from './shared/MainBox';
import LoadingPage from './shared/pages/LoadingPage';
import AccountVerification from './auth/AccountVerification';

const ProtectedRoute = ({entity, code, any, element, }) => {
  return (<Layout>
    <HasAccessTo 
      entity={entity} 
      code={code}
      any={any}
      unauthorizedElement={<UnauthorizedPage />}
    >
        {element}
    </HasAccessTo>
  </Layout>);
}

const globalUrls = [
  <Route key="0" path="reset-password/:resetPasswordCode" element={<ResetPasswordForm />} />,
  <Route key="1" path="appointment-confirmation/:confirmationAccessToken" element={<AppointmentExternalConfirmationView />} />,
  <Route key="2" path="sign-up/:signUpCode" element={<SignUpForm />} />,
  <Route key="3" path="account-verification/:verificationCode" element={<AccountVerification />} />,
]

export default ({authenticated}) => {
    const [user, _, loading] = useUser();
   
    if (!authenticated) {
      return (<Routes>
        {globalUrls}
        <Route path="*" element={<EntryForm />} />,
        <Route key="4" path="sign-up-account" element={<EntryForm formMode={FORM_SIGN_UP} />} />
      </Routes>)
    }

    if (loading) {
      return (<Routes>
        {globalUrls}
        <Route path="*" element={
          <LoadingPage />
        } />
      </Routes>)
    }

    if (!user) {
      return (<Routes>
        {globalUrls}
        <Route path="*" element={
          <NetworkErrorPage />
        } />
      </Routes>)
    }

    if (user.tenants.length==0) {
      return (
        <Routes>
           <Route path="*" element={
              <MainBox alignItems="center" justifyContent="center">
                <Slide direction='right' in={true} mountOnEnter timeout={1000}>
                  <Box sx={{'maxWidth': '500px', 'width':'100%'}}>
                    <TenantForm isCreating={true} />
                  </Box>
                </Slide>
            </MainBox>
          } />
        </Routes>
      )
    }

    return (
        <>
          <Routes>
            <Route path="/" element={<Layout><Dashboard /></Layout>} />
            <Route path="users" element={
              <ProtectedRoute
                entity="User"
                element={<UserMaintenance />} />}
            />
            <Route path="groups" element={
              <ProtectedRoute
                entity="Group"
                element={<GroupMaintenance />} />}
            />
            <Route path="offices" element={
              <ProtectedRoute
                entity="Office"
                element={<OfficeMaintenance />} />}
            />
            <Route path="specialists" element={
              <ProtectedRoute
                entity="Specialist"
                element={<SpecialistMaintenance />} />}
            />
            <Route path="specialties" element={
              <ProtectedRoute
                entity="Specialty"
                element={<SpecialtyMaintenance />} />}
            />
            <Route path="timeOffs" element={
              <ProtectedRoute
                entity="TimeOff"
                element={<TimeOffMaintenance />} />}
            />
            <Route path="medical-tests" element={
              <ProtectedRoute
                entity="MedicalTest"
                element={<MedicalTestMaintenance />} />}
            />
            <Route path="discounts" element={
              <ProtectedRoute
                entity="Discount"
                element={<DiscountMaintenance />} />}
            />
            <Route path="services" element={
              <ProtectedRoute
                entity="Service"
                element={<ServiceMaintenance />} />}
            />
            <Route path="appointments" element={
              <ProtectedRoute
                entity="Appointment"
                element={<AppointmentMaintenance />} />}
            />
            <Route path="insurances" element={
              <ProtectedRoute
                entity="Insurance"
                element={<InsuranceMaintenance />} />}
            />
            <Route path='calendar' element={
              <ProtectedRoute
                entity="Appointment"
                code="CALENDAR"
                element={
                  <AppointmentPage />
                }
              />
            }/>
            <Route path='workspace' element={
              <ProtectedRoute
                  entity="Specialist"
                  code="WORKSPACE"
                  element={<WorkspacePage />}
                />
            }/>
            <Route path='general-report' element={
              <ProtectedRoute
                entity="Report"
                code="GENERAL"
                element={<GeneralReportPage />}
              />
            }/>
            <Route path='turns' element={
              <ProtectedRoute
                entity="Turn"
                code="HANDLE"
                element={<DayTurnListPage />}
              />
            }/>
            <Route path='patients' element={
              <ProtectedRoute
                entity="Patient"
                element={<PatientMaintenance />}
              />
            } />
            <Route path="patients/:patientId/appointments" element={
              <ProtectedRoute
                entity="Appointment"
                element={<AppointmentMaintenance />} />}
            />
            <Route path="turn-board" element={
              <HasAccessTo
                entity="Turn"
                code="BOARD"
                unauthorizedElement={<UnauthorizedPage />}
              >
                <TurnDashboard />
              </HasAccessTo>
            } />
            {globalUrls}
            <Route path="*" element={
                <Layout>
                  <NotFoundPage />
                </Layout>
              } />
          </Routes>
        </>
    );
}