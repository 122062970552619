import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {LocalHospital } from "@mui/icons-material";
import {Link} from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";

function ResponsiveAppBar({onOpenSidebar}) {
    return <AppBar position="fixed" sx={{boxShadow: 'none'}}>
            <Toolbar sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="end"
                        onClick={onOpenSidebar}
                    >
                        <MenuIcon />
                    </IconButton>
                    <LocalHospital sx={{mr: 1, ml: 3}}/>
                    <Typography
                        variant="h6"
                        noWrap
                        component={Link}
                        to="/"
                        sx={{
                            mr: 2,
                            display: {md: 'flex'},
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.2rem',
                            color: 'inherit',
                            textDecoration: 'none',
                            flexGrow: 0
                        }}
                    >
                        ClinicLink
                    </Typography>
                </Box>
            </Toolbar>
        </AppBar>;
}

export default ResponsiveAppBar;