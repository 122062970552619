import { gql } from '@apollo/client'

export const SEARCH_PATIENTS = gql`
    query searchPatients($tenants: [String!]!, $searchText: String!) {
        searchPatients(tenants: $tenants, searchText: $searchText) {
            _id,
            patientName,
            patientBirthday,
            tutorPhoneNumber,
            tutorFirstName,
            tutorLastName, 
            tutorIdentification,
        }
    }
`;

export const GET_SERVICES = gql`
    query getServices($tenants: [String!]!, $specialistIds: [String!]) {
        getServices(tenants: $tenants, specialistIds: $specialistIds) {
            _id,
            name,
            amount,
            amountWithInsurance,
            commissionPercent,
            commissionAmount,
            commissionMode,
            previousAppointmentRequired,
            requiredMedicalTests {
                _id,
                name,
                enabled,
                metadata {
                    color
                }
            },
            specialist {
                _id,
                name,
                office {
                    _id
                },
                specialty {
                    _id,
                    name
                }
            }
        }
    }
`;

export const FIND_SERVICE_BY_ID = gql`
 query findService($serviceId: String!) {
    findService(serviceId: $serviceId) {
        _id,
        name,
        amount,
        amountWithInsurance,
        enabled,
        commissionPercent,
        commissionAmount,
        commissionMode,
        previousAppointmentRequired,
        requiredMedicalTests {
            _id,
            name,
            enabled,
            metadata {
                color
            }
        },
        specialist {
            _id,
            name,
            office {
                _id
            },
            specialty {
                _id,
                name
            }
        }
    }
 }
`;

export const GET_SPECIALISTS = gql`
    query getSpecialists($tenants: [String!]!) {
        getSpecialists(tenants: $tenants) {
            _id,
            name,
            code,
            commissionPercent,
            specialty {
                _id,
                name,
                code
            },
            office {
                _id,
                name
            },
            schedule {
                monday {
                    name,
                    from,
                    to,
                    count
                },
                tuesday {
                    name,
                    from,
                    to,
                    count
                },
                wednesday {
                    name,
                    from,
                    to,
                    count
                },
                thursday {
                    name,
                    from,
                    to,
                    count
                },
                friday {
                    name,
                    from,
                    to,
                    count
                },
                saturday {
                    name,
                    from,
                    to,
                    count
                },
                sunday {
                    name,
                    from,
                    to,
                    count
                },
            }
        }
    }
`;

export const FIND_SPECIALIST_BY_ID = gql`
    query findSpecialist($specialistId: String!) {
        findSpecialist(specialistId: $specialistId) {
            _id,
            name,
            code,
            commissionPercent,
            specialty {
                _id,
                name,
                code
            },
            office {
                _id,
                name
            },
            schedule {
                monday {
                    name,
                    from,
                    to,
                    count
                },
                tuesday {
                    name,
                    from,
                    to,
                    count
                },
                wednesday {
                    name,
                    from,
                    to,
                    count
                },
                thursday {
                    name,
                    from,
                    to,
                    count
                },
                friday {
                    name,
                    from,
                    to,
                    count
                },
                saturday {
                    name,
                    from,
                    to,
                    count
                },
                sunday {
                    name,
                    from,
                    to,
                    count
                },
            }
        }
    }
`;

export const GET_APPOINTMENTS_BY_PATIENT_ID = gql`
    query getAppointments($tenants:[String!]!, $patientId: String) {
        getAppointments(tenants: $tenants,patientId: $patientId) {
            _id,
            time,
            date, 
            status,
            code,
            createdAt,
            service {
                _id,
                name,
                amount,
                specialist {
                    _id,
                    name,
                    specialty {
                        _id,
                        name
                    }
                }
            },
            invoice {
                _id,
                status,
                totalAmount,
                subTotalAmount,
                discountAmount,
                insuranceAmount
            },
            turn {
                _id,
                status
            },
            comments {
                comment,
                createdAt,
                wroteBy {
                    fullName
                }
            },
            requiredMedicalTests {
                _id,
                name,
                enabled,
                metadata {
                    color
                }
            },
            doneMedicalTests {
                _id,
                name,
                enabled,
                metadata {
                    color
                }
            },
            requestedMedicalTests {
                _id,
                name,
                enabled,
                metadata {
                    color
                }
            },
            tags {
                _id,
                name,
                description
            }
        }
    }
`;

export const GET_APPOINTMENTS_BY_DAY_AND_SPECIALIST_IDS = gql`
    query getAppointments($tenants: [String!]!, $specialistIds: [String!], $date: DateTime) {
        getAppointments(tenants: $tenants, specialistIds: $specialistIds, to: $date) {
            _id,
            time,
            status,
            date, 
            code,
            totalAmount,
            createdAt,
            patient {
                _id,
                patientName,
                patientBirthday,
                tutorFirstName,
                tutorLastName,
                tutorPhoneNumber,
                tutorIdentification
            },
            service {
                _id,
                name,
                specialist {
                    _id,
                    name,
                    office {
                        _id
                    },
                    specialty {
                        _id,
                        name
                    }
                }
            },
            turn {
                _id,
                status,
                queuedAt,
                startedAt,
                completeAt
            },
            specialist {
                _id,
                name,
                office {
                    _id
                },
                specialty {
                    _id,
                    name
                }
            },
            tags {
                _id,
                name,
                description
            }
        }
    }
`;


export const GET_QUEUED_APPOINTMENTS = gql`
    query getQueuedAppointments($tenants: [String!]!, $specialistIds: [String!]!) {
        getQueuedAppointments(tenants: $tenants, specialistIds: $specialistIds) {
            _id,
            time,
            status,
            date, 
            totalAmount,
            code,
            cancelledAt,
            createdAt,
            confirmed,
            patient {
                _id,
                patientName,
                patientBirthday,
                tutorFirstName,
                tutorLastName,
                tutorPhoneNumber,
                tutorIdentification
            },
            service {
                _id,
                name,
                amount,
                specialist {
                    code,
                    _id,
                    name,
                    office {
                        _id
                    },
                    specialty {
                        _id,
                        name
                    }
                }
            },
            turn {
                _id,
                status,
                queuedAt,
                startedAt,
                code,
                priority,
                completeAt,
                availableAt,
                cancelledAt
            },
            specialist {
                _id,
                name,
                office {
                    _id
                },
                specialty {
                    _id,
                    name
                }
            },
            invoice {
                _id,
                totalAmount,
                commissionAmount
            },
            tags {
                _id,
                name,
                description
            }
        }
    }
`;

export const GET_PAGINATED_APPOINTMENTS = gql`
    query paginatedResults($tenants: [String!]!, $skip:Int,$limit:Int, $sort:SortAppointmentFieldsInput, $filter:PaginatedAppointmentsFilter) {
        paginatedResults: getPaginatedAppointments(tenants: $tenants, skip: $skip, limit: $limit, sort: $sort, filter: $filter) {
            count,
            rows {
                _id,
                time,
                status,
                code,
                date, 
                totalAmount,
                insuranceAmount,
                createdAt,
                confirmed,
                patient {
                    _id,
                    patientName,
                    patientBirthday,
                    tutorFirstName,
                    tutorLastName,
                    tutorPhoneNumber,
                    tutorIdentification
                },
                service {
                    _id,
                    name
                },
                specialist {
                    _id,
                    name,
                    office {
                        _id
                    },
                    specialty {
                        _id,
                        name
                    }
                },
                turn {
                    _id,
                    status,
                    queuedAt,
                    startedAt,
                    completeAt
                },
                specialist {
                    _id,
                    name,
                    office {
                        _id
                    },
                    specialty {
                        _id,
                        name
                    }
                },
                requestedMedicalTests {
                    _id,
                    name,
                    enabled,
                    metadata {
                        color
                    }
                },
                requiredMedicalTests {
                    _id,
                    name,
                    enabled,
                    metadata {
                        color
                    }
                },
                doneMedicalTests {
                    _id,
                    name,
                    enabled,
                    metadata {
                        color
                    }
                },
                insurance {
                    _id,
                    name
                },
                tags {
                    _id,
                    name,
                    description
                }
            }
        }
    }
`;

export const FIND_APPOINTMENT_BY_ID = gql`
 query findAppointment($appointmentId: String!) {
    findAppointment(appointmentId: $appointmentId) {
        _id,
        time,
        status,
        date, 
        code,
        confirmed,
        comments {
            comment,
            createdAt,
            wroteBy {
                fullName
            }
        },
        patient {
            _id,
            patientName,
            patientBirthday,
            tutorFirstName,
            tutorLastName,
            tutorPhoneNumber,
            tutorIdentification
        },
        service {
            _id,
            name,
            amount,
            amountWithInsurance,
            previousAppointmentRequired,
            specialist {
                _id,
                name,
                office {
                    _id
                },
                specialty {
                    _id,
                    name
                }
            }
        },
        turn {
            _id,
            status,
            code,
            queuedAt,
            startedAt,
            completeAt
        },
        specialist {
            _id,
            name,
            office {
                _id
            },
            specialty {
                _id,
                name
            }
        },
        discount {
            _id,
            name,
            percent,
            enabled
        },
        totalAmount,
        insuranceAmount,
        insurance {
            _id,
            name
        },
        previousAppointment {
            _id,
            time,
            status,
            date
        },
        requiredMedicalTests {
            _id,
            name,
            enabled,
            metadata {
                color
            }
        },
        doneMedicalTests {
            _id,
            name,
            enabled,
            metadata {
                color
            }
        },
        tags {
            _id,
            name,
            description
        }
    }
 }
`;

export const GET_INSURANCES = gql`
    query getInsurances($tenants: [String!]!) {
        getInsurances(tenants: $tenants) {
            _id,
            name,
            code
        }
    }
`;

export const FIND_INSURANCE_BY_ID = gql`
 query findInsurance($insuranceId: String!) {
    findInsurance(insuranceId: $insuranceId) {
        _id,
        name,
        code
    }
 }
`;

export const GET_DISCOUNTS = gql`
    query getDiscounts($tenants: [String!]!) {
        getDiscounts(tenants: $tenants) {
            _id,
            name,
            enabled,
            code,
            percent
        }
    }
`;

export const FIND_DISCOUNT_BY_ID = gql`
 query findDiscount($discountId: String!) {
    findDiscount(discountId: $discountId) {
        _id,
        name,
        percent,
        enabled
    }
 }
`;

export const GET_AVAILABILITIES = gql`
    query getAvailabilities($specialistId: String!, $date: DateTime!) {
        getAvailabilities(specialistId: $specialistId ,date: $date)
        {
            time,
            count,
            available,
            timeOffs {
                _id,
                dateFrom,
                dateTo,
                reason
            },
            appointments {
                _id,
                date,
                time,
                confirmed,
                code,
                status,
                specialist {
                    _id
                },
                service {
                    _id,
                    name
                },
                patient {
                    patientName,
                    patientBirthday,
                    tutorFirstName,
                    tutorLastName,
                    tutorPhoneNumber,
                    tutorIdentification
                }
            }
        }
    }
`;

export const GET_CALENDAR_AVAILABILITIES = gql`
    query getCalendarAvailabilities($specialistIds: [String!]!, $from: DateTime!, $to: DateTime!) {
        getCalendarAvailabilities(specialistIds: $specialistIds, from: $from, to: $to) {
            date,
            specialistAvailabilities {
                specialist {
                    _id,
                    name,
                    code
                },
                count,
                total
            }
        }
    }
`;

export const GET_GENERAL_REPORT = gql`
    query getGeneralReport($tenants: [String!]!, $from: DateTime!, $to: DateTime!) {
        getGeneralReport (tenants: $tenants, from: $from, to: $to) {
            summary{
                subTotal,
                insurance,
                discount, 
                total,
                tax,
                commissionAmount
            },

            dates,
            specialties,
            specialists,
            services,
            invoices,

            rows {
                key,
                summary{
                    subTotal,
                    insurance,
                    discount, 
                    total,
                    tax,
                    commissionAmount
                },

                specialties,
                specialists,
                services,
                invoices,

                rows {
                    key {
                        _id,
                        name
                    },
                    summary{
                        subTotal,
                        insurance,
                        discount, 
                        total,
                        tax,
                        commissionAmount
                    }

                    specialists,
                    services,
                    invoices,

                    rows {
                        key {
                            _id,
                            name
                        },
                        summary{
                            subTotal,
                            insurance,
                            discount, 
                            total,
                            tax,
                            commissionAmount 
                        },

                        services,
                        invoices,

                        rows {
                            key {
                                _id,
                                name
                            },
                            summary{
                                subTotal,
                                insurance,
                                discount, 
                                total,
                                tax,
                                commissionAmount
                            },

                            invoices,

                            rows {
                                key {
                                    _id
                                },
                                summary {
                                    subTotal,
                                    insurance,
                                    discount, 
                                    total,
                                    tax,
                                    commissionAmount
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export const GET_CURRENT_USER = gql`
    query {
        getCurrentUser {
                _id,
                fullName,
                username,
                access {
                    entityConstraintRefKeys,
                    action {
                        name,
                        entity,
                        description,
                        entityConstraints,
                        code
                    }
                },
                groups {
                    _id,
                    name,
                    access {
                        entityConstraintRefKeys,
                        action {
                            entity,
                            name,
                            description,
                            entityConstraints,
                            code
                        }
                    },
                    entityConstraintRefs {
                        key,
                        entity,
                        description,
                        entityRef
                    }
                },
                entityConstraintRefs {
                    key,
                    entity,
                    description,
                    entityRef
                },
                tenants {
                    _id,
                    name,
                    settings {
                        patientSettings {
                            phoneNumberRequired
                        },
                        whatsAppSettings {
                            enabled,
                            integrationType
                        }
                    }
                }
            }
        }
`;


export const FIND_USER_BY_ID = gql`
    query findUser($userId: String!) {
        findUser(userId: $userId) {
                _id,
                fullName,
                username,
                email,
                status,
                access {
                    entityConstraintRefKeys,
                    action {
                        _id,
                        name,
                        entity,
                        description,
                        code,
                        entityConstraints
                    }
                },
                groups {
                    _id,
                    name,
                    access {
                        entityConstraintRefKeys,
                        action {
                            _id,
                            name,
                            entity,
                            description,
                            entityConstraints,
                            code
                        }
                    },
                    entityConstraintRefs {
                        key,
                        entity,
                        description,
                        entityRef
                    }
                },
                entityConstraintRefs {
                    key,
                    entity,
                    description,
                    entityRef
                }
            }
        }
`;

export const GET_USERS = gql`
    query getUsers($tenants: [String!]!) {
        getUsers(tenants: $tenants) {
            _id,
            fullName,
            username,
            email,
            status,
            groups {
                _id,
                name
            }
        }
    }
`;

export const GET_GROUPS = gql`
    query getGroups($tenants: [String!]!) {
        getGroups(tenants: $tenants) {
            _id,
            name
        }
    }
`;

export const FIND_GROUP_BY_ID = gql`
    query findGroup($groupId: String!) {
        findGroup(groupId: $groupId) {
                _id,
                name,
                access {
                    action {
                        _id,
                        name,
                        entity,
                        description,
                        entityConstraints,
                        code
                    }
                },
                entityConstraintRefs {
                    key,
                    entity,
                    description,
                    entityRef
                }
            }
        }
`;

export const GET_ACTIONS = gql`
    query {
        getActions {
            _id,
            name,
            description,
            entity,
            code,
            entityConstraints,
        }
    }
`;

export const FIND_PATIENT_BY_ID = gql`
 query findPatient($patientId: String!) {
    findPatient(patientId: $patientId) {
        _id,
        patientName,
        patientBirthday,
        tutorFirstName,
        tutorLastName,
        tutorPhoneNumber,
        tutorIdentification
    }
 }
`;

export const GET_PAGINATED_PATIENTS = gql`
    query paginatedResults($tenants: [String!]!, $skip:Int,$limit:Int, $filter: PaginatedPatientsFilter) {
        paginatedResults: getPaginatedPatients(tenants: $tenants, skip: $skip, limit: $limit, filter: $filter) {
            count,
            rows {
                _id,
                patientName,
                patientBirthday,
                tutorFirstName,
                tutorLastName,
                tutorPhoneNumber,
                tutorIdentification
            }
        }
    }
`;

export const FIND_OFFICE_BY_ID = gql`
 query findOffice($officeId: String!) {
    findOffice(officeId: $officeId) {
        _id,
        name
    }
 }
`;

export const GET_OFFICES = gql`
    query getOffices($tenants: [String!]!) {
        getOffices(tenants: $tenants) {
                _id,
                name
        }
    }
`;

export const FIND_SPECIALTY_BY_ID = gql`
 query findSpecialty($specialtyId: String!) {
    findSpecialty(specialtyId: $specialtyId) {
        _id,
        name
    }
 }
`;

export const GET_SPECIALTIES = gql`
    query getSpecialties($tenants: [String!]!){
        getSpecialties(tenants: $tenants) {
            _id,
            name,
            code
        }
    }
`;

export const FIND_MEDICAL_TEST_BY_ID = gql`
    query findMedicalTest($medicalTestId: String!) {
        findMedicalTest(medicalTestId: $medicalTestId) {
            _id,
            name,
            enabled, 
            metadata {
                color
            }
        }
    }
`;

export const GET_MEDICAL_TESTS = gql`
    query getMedicalTests($tenants: [String!]!) {
        getMedicalTests(tenants: $tenants) {
            _id,
            name,
            enabled, 
            metadata {
                color
            }
        }
    }
`;


export const GET_ACTIVE_TURNS = gql`
    query getActiveTurns($tenants: [String!]!) {
        getActiveTurns(tenants: $tenants) {
            _id,
            status,
            code,
            description,
            queuedAt,
            startedAt
        }
    }
`;


export const GET_PAGINATED_TIME_OFFS = gql`
    query paginatedResults($tenants: [String!]!, $skip:Int,$limit:Int, $sort:SortTimeOffFieldsInput, $filter:PaginatedTimeOffsFilter) {
        paginatedResults: getPaginatedTimeOffs(tenants: $tenants, skip: $skip, limit: $limit, sort: $sort, filter: $filter) {
            count,
            rows {
                _id,
                dateFrom,
                dateTo,
                specialist {
                    _id,
                    name,
                    specialty {
                        _id,
                        name
                    }
                },
                reason
            }
        }
    }
`;

export const FIND_TIME_OFF_BY_ID = gql`
 query findTimeOff($timeOffId: String!) {
    findTimeOff(timeOffId: $timeOffId) {
        _id,
        dateFrom,
        dateTo,
        specialist {
            _id,
            name,
            specialty {
                _id,
                name
            }
        },
        reason
    }
 }
`;

export const GET_TAGS = gql`
    query getTags($tenants: [String!]!, $categories: [String!]!) {
        getTags(tenants: $tenants, categories: $categories) {
            _id,
            name,
            description
        }
    }
`;


export const FIND_TENANT_BY_ID = gql`
 query findTenant($tenantId: String!) {
    findTenant(tenantId: $tenantId) {
        _id,
        name,
        address,
        phoneNumber,
        registryCode,
        email,
        settings {
            patientSettings {
                phoneNumberRequired,
                requiresGuardian,
                showPatientNotes
            },

            whatsAppSettings {
                enabled,
                integrationType,
                phoneNumber,
                initialized,
                linkStatus,
                qrCode
            },

            generalSettings {
                country,
                currencyFormat,
                weekOffDays,
                dateFormat,
                notificationSchedule
            }
        }
    }
 }
`;


export const GET_TENANT_WHATSAPP_INFO_BY_ID = gql`
 query findTenant($tenantId: String!) {
    findTenant(tenantId: $tenantId) {
        _id,
        settings {
            whatsAppSettings {
                linkStatus,
                initialized,
                qrCode
            }
        }
    }
 }
`;


export const FIND_APPOINTMENT_BY_CONFIRMATION_ACCESS_TOKEN = gql`
 query findAppointmentByConfirmationAccessToken($confirmationAccessToken: String!) {
    findAppointmentByConfirmationAccessToken(confirmationAccessToken: $confirmationAccessToken) {
        _id,
        time,
        status,
        date, 
        code,
        confirmed,
        comments {
            comment,
            createdAt,
            wroteBy {
                fullName
            }
        },
        patient {
            _id,
            patientName,
            patientBirthday,
            tutorFirstName,
            tutorLastName,
            tutorPhoneNumber,
            tutorIdentification
        },
        service {
            _id,
            name,
            amount,
            amountWithInsurance,
            previousAppointmentRequired,
            specialist {
                _id,
                name,
                office {
                    _id
                },
                specialty {
                    _id,
                    name
                }
            }
        },
        turn {
            _id,
            status,
            code,
            queuedAt,
            startedAt,
            completeAt
        },
        specialist {
            _id,
            name,
            office {
                _id
            },
            specialty {
                _id,
                name
            }
        },
        discount {
            _id,
            name,
            percent,
            enabled
        },
        totalAmount,
        insuranceAmount,
        insurance {
            _id,
            name
        },
        previousAppointment {
            _id,
            time,
            status,
            date
        },
        requiredMedicalTests {
            _id,
            name,
            enabled,
            metadata {
                color
            }
        },
        doneMedicalTests {
            _id,
            name,
            enabled,
            metadata {
                color
            }
        },
        tags {
            _id,
            name,
            description
        }
    }
 }
`;
