import { Box, Button, Divider, Paper, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { useForm } from "react-hook-form";
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next'
import Title from '../shared/Title';
import { FormTextField } from '../shared/form-components';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@apollo/client';
import { FIND_OFFICE_BY_ID, GET_OFFICES } from '../graphql/queries';
import { CREATE_OFFICE, UPDATE_OFFICE } from '../graphql/mutations';
import { useSnackbar } from 'notistack';
import { useTenantId } from '../tenant/hooks';

export default ({officeId, onCancel, onAdded}) => {
    const isNew = !officeId;

    const [tenantId] = useTenantId();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [ createOffice] = useMutation(isNew ? CREATE_OFFICE : UPDATE_OFFICE,{
        refetchQueries: [
            GET_OFFICES,
            FIND_OFFICE_BY_ID
        ]
    })
 
    const {data, loading} = useQuery(FIND_OFFICE_BY_ID, {
        variables: {
            officeId: officeId
        },
        skip: isNew
    });

    const schema = yup.object({
        _id: yup.string(),
        name: yup.string().required(t('RequiredField', {Field: t('Name')}))
    });
    
    const { setValue, control, handleSubmit, formState: { isSubmitting } } = useForm({
        resolver: yupResolver(schema),
        mode: 'all',
        defaultValues: {
            _id: officeId
        }
    });

    const onSubmit = async data => {
        const response = await createOffice({
            variables: {
                input: {
                    ...data, 
                },
                ...(isNew?{tenants: [tenantId]}:{})
            }
        });
        onAdded && onAdded({
            ...data,
            ...response.data.createOffice
        });

        enqueueSnackbar(t("OperationDoneSuccessfully"), {variant: "success"});
    }

    useEffect(()=>{
        if (!data?.findOffice) {
            return;
        }

        setValue("name", data.findOffice.name);
    }, [data]);

    return (
        <Paper>
            <Box p={2}>
                <Title sx={{marginBottom: 0}}>{t(isNew?"CreateOffice":"EditOffice")}</Title>
            </Box>
            <Divider />
            <Box p={2}>
                <Stack spacing={2}>
                    <FormTextField 
                        label={t('Name')}
                        name="name"
                        variant="outlined"
                        control={control}
                        fullWidth
                    />
                </Stack>
            </Box>
            <Divider />
            <Box p={2}>
                <Stack direction="row" justifyContent="space-between">
                    <Button variant='contained' color="grey" onClick={onCancel} disabled={isSubmitting}>{t('Cancel')}</Button>
                    <LoadingButton variant='contained' color='primary' onClick={handleSubmit(onSubmit)} loading={isSubmitting}>{t('Submit')}</LoadingButton>
                </Stack>
            </Box>
        </Paper>
    )
}