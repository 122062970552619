import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Avatar, Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Stack } from '@mui/system';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { FormTextField } from '../../shared/form-components';
import Title from '../../shared/Title';
import { useMutation } from '@apollo/client';
import { GENERATE_RESET_PASSWORD_CODE } from '../../graphql/mutations';
import { useTranslation } from 'react-i18next';
import { Email, Lock } from '@mui/icons-material';
import { blue } from '@mui/material/colors';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { useState } from 'react';

const resetPasswordSchema = Yup.object({
    email: Yup.string().email().required()
});

const SendResetPasswordCodeForm = ({switchToLogin}) => {
    const {t} = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [ checkEmail, setCheckEmail ] = useState(false);

    const {control, handleSubmit, formState: {isLoading}, watch } = useForm({
        resolver: yupResolver(resetPasswordSchema)
    });

    const [resetPasswordFnc] = useMutation(GENERATE_RESET_PASSWORD_CODE, {
        onCompleted: ()=>{
            enqueueSnackbar(t("ResetPasswordEmailSent"), {variant: "success"});
        }
    });

    const resetPassword = async (data) =>{
        await resetPasswordFnc({
            variables: {
                ...data
            }
        });

        setCheckEmail(true);
    }

    const handleEmailKeyDown = (event) => {
        if (event.keyCode === 13) {
            handleSubmit(resetPassword)();
        }
    }
            
    const retryResend = () => {
        setCheckEmail(false);
    }
                
    const email = watch('email');
    return (<Box p={2}>
                {  !checkEmail && 
                   (<Stack spacing={2}>
                        <Stack p={2} alignItems="center">
                            <Avatar sx={{ bgcolor: blue[500] }}>
                                <Lock />
                            </Avatar>
                            <Title sx={{marginBottom:0}}>{t('Reset Password')}</Title>
                        </Stack>
                        <FormTextField 
                            onKeyDown={handleEmailKeyDown}
                            control={control}
                            label={t("Email")}
                            name="email"
                            />
                        <Stack direction="row" spacing={2}>
                            <Button 
                                size='small' 
                                disabled={isLoading} 
                                color='primary' 
                                variant='text'
                                onClick={switchToLogin}>
                                    {t('Back')}
                            </Button>
                            <LoadingButton 
                                fullWidth
                                color="primary" 
                                variant="contained"
                                loading={isLoading}
                                onClick={handleSubmit(resetPassword)}>{t('Reset Password')}</LoadingButton>
                        </Stack>
                    </Stack>)
                }
                { 
                    checkEmail && 
                    (<Stack spacing={2}>
                        <Stack p={2} alignItems="center">
                            <Avatar sx={{ bgcolor: blue[500] }}>
                                <Email />
                            </Avatar>
                            <Title sx={{marginBottom:0}}>{t('Check your inbox.')}</Title>
                        </Stack>
                        <Typography align='center'>
                            {t('ResetPasswordDescription1', {
                                email: email
                            })}
                        </Typography>
                        <Typography align='center'>
                            {t('ResetPasswordDescription2')}
                            <Link onClick={retryResend}>
                                {t('ResetPasswordRetryLink')}
                            </Link>.
                        </Typography>
                    </Stack>)
                }
            </Box>);
}

export default SendResetPasswordCodeForm