import { Box, Button } from '@mui/material';
import {DataGrid, getGridStringOperators} from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next';
import {NavLink} from "react-router-dom";
import Switch from "@mui/material/Switch";
import * as moment from "moment/moment";

const buildColumns = (t) => ([
    { 
      filterable: false,
      sortable: false,
      field: '_id', 
      headerName: 'ID', 
      width: 100,
    },
    { 
      field: 'patientName', 
      headerName: t('PatientName'), 
      width: 200,
      filterOperators: getGridStringOperators().filter(x=>x.value === 'contains')
    },
    { 
      filterable: false,
      sortable: false,
      field: 'patientBirthday', 
      headerName: t('PatientBirthday'), 
      width: 150,
      renderCell: ({value}) =>  moment(value).format('DD-MM-yyyy')
    },
    { 
      field: 'tutorFirstName', 
      headerName: t('TutorFirstName'),
      width: 140,
      filterOperators: getGridStringOperators().filter(x=>x.value === 'contains')
    },
    { 
      field: 'tutorLastName', 
      headerName: t('TutorLastName'), 
      width: 140,
      filterOperators: getGridStringOperators().filter(x=>x.value === 'contains')
    },
    { 
      field: 'tutorPhoneNumber', 
      headerName: t('TutorPhoneNumber'), 
      width: 140,
      filterOperators: getGridStringOperators().filter(x=>x.value === 'contains')
    },
    { 
      field: 'tutorIdentification', 
      headerName: t('TutorIdentification'), width: 140,
      filterOperators: getGridStringOperators().filter(x=>x.value === 'contains') 
    },
    { 
      filterable: false,
      sortable: false,
      field: 'patientUrl', 
      headerName: t('Appointments'), 
      width: 170,
      renderCell: ({row}) => {
        return <Button variant='text' color='primary' size="small" component={NavLink} to={`./${row._id}/appointments`}>
          {t('ViewAppointments')}
        </Button>
      }
    },
  ]);
  
  export default ({rows, count, loading, onFilterModelChange, onSortModelChange, onPaginationModelChange, onRowSelectedChanged, toolbar}) => {
    const {t} = useTranslation();
    const columns = buildColumns(t);

    return (
      <Box sx={{ height: 450, width: '100%' }}>
        <DataGrid
          data-testid="patient-list"
          density='compact'
          rows={rows}
          columns={columns}
          rowCount={count}
          loading={loading}
          getRowId={(r)=>r._id}
          onRowSelectionModelChange={onRowSelectedChanged}
          initialState={{
            columns: {
              columnVisibilityModel: {
                _id: false
              }
            },
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 15, 20]}
          onPaginationModelChange={onPaginationModelChange}
          checkboxSelection
          onSortModelChange={onSortModelChange}
          paginationMode="server"
          onFilterModelChange={onFilterModelChange}
          filterMode="server"
          slots={{
            toolbar: toolbar
          }}
        />
      </Box>
    );
  }