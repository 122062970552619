import React from 'react'

import Container from '@mui/material/Container';
import { Paper } from '@mui/material';

export default ({children, plain, sx}) => {
    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4, ...(sx||{})}}>
            {
                (!plain) ? (
                    <Paper sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        {children}
                    </Paper>
                ) : children
            }
        </Container>
    )
}