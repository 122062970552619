import {DataGrid} from '@mui/x-data-grid'
import Chip from '@mui/material/Chip'
import { useTranslation } from 'react-i18next';
import { formatMoney } from '../shared/format-helpers';
import { Box } from '@mui/material';

const buildColumns = (t) => ([
    { field: '_id', headerName: t('ID'), width: 60, },
    { field: 'name', headerName: t('Name'), width: 180 },
    { 
      field: 'specialist', 
      headerName: t('Specialist'), 
      width: 200,
      valueGetter: ({value})=>value?.name
    }, 
    { field: 'amount', headerName: t('Amount'), width: 150, valueGetter: ({value}) => formatMoney(value)},
    { field: 'amountWithInsurance', headerName: t('AmountWithInsurance'), width: 150, valueGetter: ({value}) => formatMoney(value)},
    { 
      field: 'requiredMedicalTests', 
      headerName: t('RequiredMedicalTests'), 
      width: 250,
      renderCell: ({value})=>value?.map((g,index)=><Chip size="small" key={index} label={g.name} />)
    }
   ]);
  
  export default ({
      rows, 
      count, 
      loading, 
      onFilterModelChange, 
      onSortModelChange, 
      onPaginationModelChange, 
      onRowSelectedChanged,
      toolbar
    }) => {
    const {t} = useTranslation();
    const columns = buildColumns(t);
    return (
      <Box sx={{ height: 450, width: '100%' }}>
        <DataGrid
          density='compact'
          rows={rows}
          columns={columns}
          rowCount={count}
          loading={loading}
          getRowId={(r)=>r._id}
          onRowSelectionModelChange={onRowSelectedChanged}
          initialState={{
            columns: {
              columnVisibilityModel: {
                _id: false
              }
            },
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 15, 20]}
          onPaginationModelChange={onPaginationModelChange}
          checkboxSelection
          onSortModelChange={onSortModelChange}
          onFilterModelChange={onFilterModelChange}
          slots={{
            toolbar: toolbar
          }}
        />
      </Box>
    );
  }