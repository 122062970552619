import { useState } from "react";
import { Container, Box, Fab, Stack, Button, IconButton, Tooltip, Typography, CircularProgress, Paper, Fade, Backdrop } from "@mui/material"
import DefaultContainer from "../../shared/DefaultContainer"
import ModalContainer from "../../shared/ModalContainer"
import Title from "../../shared/Title"
import UserList from "./UserList"
import AddIcon from '@mui/icons-material/Add';
import UserForm from "./UserForm"
import Modal from '@mui/material/Modal';
import { useMutation, useQuery } from '@apollo/client';
import {GET_USERS} from '../../graphql/queries';
import Confirmation from "../../shared/Confirmation";
import { DELETE_USERS } from "../../graphql/mutations";
import CustomToolbar from "../../shared/CustomToolbar";
import { HasAccessTo } from "../hooks";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useTenantId } from "../../tenant/hooks";

const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
};

const UserModal = ({open, onClose, onAdded, userId}) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            closeAfterTransition
        >
             <Fade in={open}>
                <ModalContainer width={600} sx={{padding: 0}}>
                    <UserForm 
                        userId={userId}
                        onCancel={onClose}
                        onAdded={onAdded}/>
                </ModalContainer>
            </Fade>
        </Modal>
    )
}



export default ({}) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [tenantId] = useTenantId();

    const [open,setOpen] = useState(false);

    const [openDeleteConfirmation,setOpenDeleteConfirmation] = useState(false);

    const [selecteds,setSelecteds] = useState([]);

    const {data, loading, refetch} = useQuery(GET_USERS, {
        variables: {
            tenants: [tenantId]
        }
    });

    const [deleteUsers, {loading:ldUsers}] = useMutation(DELETE_USERS, {
        refetchQueries: [
            GET_USERS
        ],
        onCompleted: ()=>{
            enqueueSnackbar(t("OperationDoneSuccessfully"), {variant: "success"});
        }
    });
    const [editUserId,setEditUserId] = useState(undefined);

    const switchOpen = () => {
        setOpen(!open);
        if (!!open) {
            setEditUserId(undefined);
        }
    }

    const handleRowSelectedChanged = (value) => {
        setSelecteds(value);
    }

    const switchDeleteConfirmation = () => {
        setOpenDeleteConfirmation(!openDeleteConfirmation);
    }

    const handleDeleteUsers = async () => {
        switchDeleteConfirmation();
        await deleteUsers({
            variables: {
                userIds: selecteds
            }
        });
    }

    const handleEditUser = () => {
        setEditUserId(selecteds[0]);
        switchOpen();
    }

    return (
        <>
            <Confirmation 
                open={openDeleteConfirmation}
                title={t("Confirmation")}
                description={t("AreYouSureToDeleteTheseUsersQuestion")}
                onClose={switchDeleteConfirmation}
                onNo={switchDeleteConfirmation}
                onYes={handleDeleteUsers}
            />
            <UserModal 
                onClose={switchOpen}
                onAdded={switchOpen}
                userId={editUserId}
                open={open}/>
            <Container>
                <Box pt={2}>
                    <Title>{t('Users')}</Title>
                </Box>
            </Container>
            <DefaultContainer sx={{mt:0}} plain>
                <Paper>
                    <UserList 
                        loading={loading}
                        rows={data?.getUsers || []}
                        onRowSelectedChanged={handleRowSelectedChanged}
                        toolbar={()=><CustomToolbar 
                            entity='User'
                            selecteds={selecteds} 
                            deleting={ldUsers} 
                            onEdit={handleEditUser}
                            onDelete={switchDeleteConfirmation}
                        />}
                    />
                </Paper>
            </DefaultContainer>
       
            <HasAccessTo entity="User" code="CREATE">
                <Fab 
                    onClick={switchOpen}
                    color="primary" 
                    sx={fabStyle}>
                    <AddIcon />
                </Fab>
            </HasAccessTo>
        </>
    )
}