import { Box, Button, Divider, Paper, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from "react-hook-form";
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next'
import Title from '../shared/Title';
import { FormTextField } from '../shared/form-components';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@apollo/client';
import { FIND_INSURANCE_BY_ID, GET_INSURANCES } from '../graphql/queries';
import { CREATE_INSURANCE, UPDATE_INSURANCE } from '../graphql/mutations';
import { useSnackbar } from 'notistack';
import { useTenantId } from '../tenant/hooks';

export default ({insuranceId, onCancel, onAdded}) => {
    const isNew = !insuranceId;

    const [tenantId] = useTenantId();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [ createInsurance] = useMutation(isNew ? CREATE_INSURANCE : UPDATE_INSURANCE,{
        refetchQueries: [
            GET_INSURANCES
        ]
    })
 
    const {data, loading} = useQuery(FIND_INSURANCE_BY_ID, {
        variables: {
            insuranceId: insuranceId
        },
        skip: isNew
    });

    const schema = yup.object({
        _id: yup.string(),
        name: yup.string().required(t('RequiredField', {Field: t('Name')})),
        code: yup.string().nullable(true)
    });
    
    const methods = useForm({
        resolver: yupResolver(schema),
        mode: 'all',
        defaultValues: {
            _id: insuranceId
        }
    });

    const { setValue, control, handleSubmit, formState: { isSubmitting } } = methods;

    const onSubmit = async data => {
        const response = await createInsurance({
            variables: {
                input: data,
                ...(isNew ? {tenants:[tenantId]}: {})
            }
        });
        onAdded && onAdded({
            ...data,
            ...response.data.createInsurance
        });

        enqueueSnackbar(t("OperationDoneSuccessfully"), {variant: "success"});
    }

    useEffect(()=>{
        if (!data?.findInsurance) {
            return;
        }

        setValue("name", data.findInsurance.name);
        setValue("code", data.findInsurance.code);
    }, [data]);

    return (
        <Paper>
            <FormProvider {...methods}>
                <Box p={2}>
                    <Title sx={{marginBottom: 0}}>{t(isNew?"CreateInsurance":"EditInsurance")}</Title>
                </Box>
                <Divider />
                <Box p={2}>
                    <Stack spacing={2}>
                        <FormTextField 
                            label={t('Name')}
                            name="name"
                            variant="outlined"
                            fullWidth
                        />

                        <FormTextField 
                            label={t('Code')}
                            name="code"
                            variant="outlined"
                            fullWidth
                        />
                    </Stack>
                </Box>
                <Divider />
                <Box p={2}>
                    <Stack direction="row" justifyContent="space-between">
                        <Button variant='contained' color="grey" onClick={onCancel} disabled={isSubmitting}>{t('Cancel')}</Button>
                        <LoadingButton variant='contained' color='primary' onClick={handleSubmit(onSubmit)} loading={isSubmitting}>{t('Submit')}</LoadingButton>
                    </Stack>
                </Box>
            </FormProvider>
        </Paper>
    )
}