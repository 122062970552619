import { 
    Stack, 
    Autocomplete, 
    TextField, 
    Box, 
    Paper,  
    Typography,
    Fab,
    Button,
    TableCell,
    TableRow,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    Collapse,
    Zoom
} from '@mui/material';
import React from 'react';
import { useFormContext, useFieldArray } from "react-hook-form";
import * as yup from 'yup';
import { 
    FormSelect,
    FormSwitchs,
    FormTextField,
} from '../shared/form-components';
import { graphql } from '@apollo/client/react/hoc';
import {
    GET_ACTIONS
} from '../graphql/queries'
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { FormAutocompleteGetSpecialistsQuery } from '../specialist/form-components';
import { 
    FormSelectMultiple,
} from '../shared/form-components';
import {
    GET_GROUPS,
} from '../graphql/queries'
import { Masonry } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { useTenantId } from '../tenant/hooks';
import { TransitionGroup } from 'react-transition-group';

const getRandomSymbol = (symbol) => {
    var array;
    if (symbol === 'y') {
        array = ['8', '9', 'a', 'b'];
        return array[Math.floor(Math.random() * array.length)];
    }
    array = new Uint8Array(1);
    crypto.getRandomValues(array);
    return (array[0] % 16).toString(16);
}

const uuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, getRandomSymbol);
}

export const FormSelectMultipleGroupsQuery = graphql(GET_GROUPS, {
    props: ({ data: { getGroups , loading } }) => ({
        options: getGroups || [],
        loading: loading,
        getOptionLabel: (t)=> t.name,
        getOptionValue: (t)=> t._id
    }),
    options: ({tenantId})=> ({
        variables: {
            searchText: '',
            tenants: [tenantId]
        }
    })
})(FormSelectMultiple);

export const SearchAutocomplete = ({options, loading, onSelected, label}) => {
    const handleSelected = ($event, value) => {
        onSelected && onSelected(value);
    }
    return (
        <Autocomplete
            disablePortal
            loading={loading}
            onChange={handleSelected}
            clearOnEscape={true}
            clearOnBlur={true}
            selectOnFocus={true}
            renderInput={(params) => 
                <TextField
                    {...params}  
                    label={label}
                />}
            getOptionLabel={(a)=>`${a.name} | ${a.description}`}
            options={options}
        />
    );
}

export const SelectFilterActionsQuery = graphql(GET_ACTIONS, {
    props: ({ data: { getActions , refetch, loading } }) => ({
        options: getActions || [],
        loading: loading,
    })
})(SearchAutocomplete);

export const accessSchema = yup.object().shape({
    actionId: yup.string().required(),
    action: yup.object(),
    entityConstraintRefKeys: yup.array().of(yup.string()).nullable()
});

export const entityConstraintRefSchema = yup.object().shape({
    key: yup.string().required(), 
    entity: yup.string().required(),
    description: yup.string().required(),
    entityRef: yup.string().required()
});

const deleteBtnStyle = {
    position: 'absolute',
    bottom: '2px',
    right: '2px',
    minWidth: '25px',
    minHeight: '25px',
    width:'25px',
    height:'25px', 
};

export const AccessRow = ({
    name,
    access, 
    onDelete, 
    index,
    entityConstraintRefs
}) => {
    const {setValue, watch, control, getValues} = useFormContext();
    const fieldName = `${name}.${index}.entityConstraintRefKeys`;

    const {t} = useTranslation();
    return (<Paper>
        <Box p={1} sx={{position: 'relative'}}>   
            <Stack spacing={0}>
                <Typography variant='caption' fontWeight='bold' >
                    {t(access.action.entity)} - {access.action.name}
                </Typography>
                <Typography variant='caption' mt={1}>
                    {access.action.description}
                </Typography>
                {entityConstraintRefs?.length>0 && access.action.entityConstraints?.length>0 && (
                    <Box p={1}>
                        <FormSwitchs
                            options={entityConstraintRefs}
                            control={control}
                            noDataMessage={t('ThereIsNotData')}
                            getOptionValue={opt=>(opt?.key ?? opt)}
                            getOptionKey={opt=>(opt?.key ?? opt)}
                            getOptionLabel={opt=>opt.description}
                            name={fieldName}
                        />
                    </Box>
                )} 
            </Stack>
            <Fab sx={deleteBtnStyle} size='small' type='button' color='error' onClick={onDelete}>
                <DeleteIcon fontSize='small'/>
            </Fab>
        </Box>
    </Paper>)
}

export const ActionRow = ({
    action, 
    onAdd,
}) => {
    const {t} = useTranslation();

    const handleClick = () => {
        onAdd && onAdd(action);
    }
    return (<Paper>
        <Box p={1} sx={{position: 'relative'}}>   
            <Stack spacing={0}>
                <Typography variant='caption' fontWeight='bold' >
                    {t(action.entity)} - {action.name}
                </Typography>
                <Typography variant='caption' mt={1}>
                    {action.description}
                </Typography>
            </Stack>
            <Fab sx={deleteBtnStyle} size='small' type='button' color='primary' onClick={handleClick}>
                <AddIcon fontSize='small'/>
            </Fab>
        </Box>
    </Paper>)
}

export const EntityConstraintRefRow = ({name, index, entities, onDelete}) => {
    const {t} = useTranslation();
    const [tenantId] = useTenantId();
    
    const { watch } = useFormContext();

    const handleDelete = () => {
        onDelete && onDelete(index);
    }

    const fieldName = `${name}.${index}`

    const entity = watch(`${fieldName}.entity`);
    return (
        <TableRow>
            <TableCell component="th" scope="row">
                <FormSelect
                    fullWidth
                    label={t("Entity")}
                    name={`${fieldName}.entity`}
                    options={entities}
                    getOptionLabel={(o)=>o}
                    getOptionValue={(o)=>o}
                    />
            </TableCell>
            <TableCell>
                <FormTextField
                    fullWidth   
                    label={t("Description")}
                    name={`${fieldName}.description`}
                />
            </TableCell>
            <TableCell>
                {entity=='Specialist' && (
                    <FormAutocompleteGetSpecialistsQuery
                        label={t("Specialist")}
                        tenantId={tenantId}
                        name={`${fieldName}.entityRef`}
                    />
                ) }
            </TableCell>
            <TableCell>
                <Fab size='small' type='button' color='error' onClick={handleDelete}>
                    <DeleteIcon fontSize='small'/>
                </Fab>
            </TableCell>
        </TableRow>
    );
}
  
export const EntityConstraintRefSection = ({name, entities}) => {
    const {t} = useTranslation();

    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        name: name, // unique name for your Field Array
    });

    const handleAdd = () => {
        append({
            key: uuid()
        })
    }

    const handleDelete = (index) => {
        remove(index);
    }

    return (
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Entity</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>EntityRef</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {fields.map((row, index) => (
                   <EntityConstraintRefRow 
                        name={name} 
                        entities={entities} 
                        key={row.key} 
                        index={index} 
                        onDelete={handleDelete} 
                    />
                ))}
                    <TableRow>
                        <TableCell colSpan={4} >
                            <Button onClick={handleAdd} fullWidth variant='outlined'>
                                Add
                            </Button>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const searchFunc = (f, search) => {
    return f?.name?.toLowerCase()?.indexOf(search.toLowerCase())>=0 || f?.description?.toLowerCase()?.indexOf(search.toLowerCase())>=0;
}

export const AccessSection = ({name, entityConstraintRefs}) => {
    const {t} = useTranslation();
    const { control } = useFormContext();

    const { data } = useQuery(GET_ACTIONS);

    const [search, setSearch] = useState('');

    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: name, // unique name for your Field Array
    });
    
    const handleOnSelected = (value) => {
        if (!value) {
            return;
        }

        append({actionId: value._id, action: value});
    }

    const handleDelete = (index) => () => {
        remove(index);
    }

    const handleChange = ($event) => {
        setSearch($event.currentTarget.value);
    }

    return (
        <Stack spacing={1}>
            <TextField size='small' onChange={handleChange} value={search} placeholder={t('Search')}/>
            <Masonry columns={2}>
               {fields.filter(f => searchFunc(f?.action, search))
                    .map((a, i)=>
                        (<AccessRow 
                            entityConstraintRefs={entityConstraintRefs}
                            name={name}
                            key={i}
                            control={control} 
                            access={a} 
                            onDelete={handleDelete(i)}
                            index={i}
                        />)
                    )}
            </Masonry>
            <Masonry columns={2}>
                {data?.getActions?.filter(f => searchFunc(f, search)).filter(a=>fields.findIndex(f=>f.action?._id == a._id)==-1)?.map(ga=> {
                    return <ActionRow 
                            action={ga}
                            onAdd={handleOnSelected}
                        />
                })}
            </Masonry>
        </Stack>
    );
}
