import {createTheme} from "@mui/material/styles";
import React from 'react';
import {Link as RouterLink} from "react-router-dom";
import {enUS, esES} from "@mui/x-data-grid";

const LinkBehavior = React.forwardRef<HTMLAnchorElement>((props, ref) => {
    const { href, ...other } = props;

    // Map href (Material UI) -> to (react-router)
    return <RouterLink ref={ref} to={href} {...other} />;
});

const getDesignTokens = (mode) => ({
    components: {
        MuiLink: {
            defaultProps: {
                component: LinkBehavior,
            },
        },
        MuiButtonBase: {
            defaultProps: {
                component: LinkBehavior,
            },
        }
    },
    palette: {
        mode,
        ...(mode === 'light'
            ? {
                // light theme settings
                primary: {
                    main: '#3980cb',
                    dark: '#286bad',
                    light: '#6699da',
                },
                secondary: {
                    main: '#e12772',
                },
                error: {
                    main: '#ce1f69',
                },
                background: {
                    default: '#f4f5f7',
                    paper: '#faf8f8',
                },
                text: {
                    primary: '#010e14',
                    secondary: 'rgb(128,131,135)'
                },
                success: {
                    main: '#2f8a2f',
                    light: '#a2d6a2',
                },
                warning: {
                    main: '#ecb83e',
                    light: '#ffe0b2',
                },
            }
            : {
                primary: {
                    main: '#9eccee',
                },
                background: {
                    default: 'rgb(57,70,84)',
                    paper: '#1f2b34',
                },
                text: {
                    primary: '#c3ddf3',
                    secondary: 'rgb(229,236,250)'
                },
                error: {
                    main: '#f3a3b0',
                },
                success: {
                    main: '#83c283',
                    light: '#216b21',
                },
                warning: {
                    main: '#b5720c',
                    light: '#a26448',
                }
            }),
    },
});

const createThemeMode = (mode, muiLocale) => createTheme(getDesignTokens(mode), (muiLocale === 'es' ? esES : enUS));
const ColorModeContext = React.createContext({ toggleColorMode: () => {}});
const LanguageModeContext = React.createContext({ changeLocale: () => {}});

export default ColorModeContext;
export { LanguageModeContext };
export { createThemeMode };