import { Box, Button, TextField, Grid, CircularProgress, Typography } from "@mui/material"
import Title from "../shared/Title"
import { GET_SPECIALISTS } from '../graphql/queries';
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { useState } from "react";
import { useTenantId } from "../tenant/hooks";

export default ({specialtyId,onSpecialistClick, onBack}) => {
    const [tenantId] = useTenantId();
    const { t } = useTranslation();
    const {data, loading} = useQuery(GET_SPECIALISTS, {
        variables: {
            enabled: true,
            tenants: [tenantId]
        }
    });

    const [search, setSearch] = useState('');

    const onSearchTextChanged = ($event) => {
        setSearch($event.target.value);
    }

    const onSpecialistButtonClicked = (specialty)=> {
        onSpecialistClick && onSpecialistClick(specialty) 
    }

    const list = data?.getSpecialists?.filter(s=> s.specialty._id == specialtyId && s.name?.toLowerCase()?.indexOf(search.toLowerCase())>=0);

    return (
        <>
            <Box pt={2} pb={2}>
                <Title>{t('Specialists')}</Title>
                <TextField value={search} onChange={onSearchTextChanged} fullWidth size="small" placeholder="search"/>
            </Box>
            {loading ? 
                <CircularProgress />
                :    
                <Grid container spacing={2}>
                    {
                        (list && list.length==0) && 
                        (<Grid item xs={12}>
                            <Typography>
                                {t('ThereIsNotData')}
                            </Typography>
                        </Grid>)
                    }
                    {list?.map(s=>{
                        return (
                            <Grid  key={s._id} item xs={3} md={4}>
                                <Button variant="contained" onClick={()=>onSpecialistButtonClicked(s)} fullWidth >
                                    <Box p={5}>
                                        {s.name}
                                    </Box>
                                </Button>
                            </Grid>
                        )
                    })}
                    <Grid item xs={12}>
                        <Button variant="outlined" color="error" onClick={onBack} fullWidth>
                            {t('Back')}
                        </Button>
                    </Grid>
                </Grid>
            }
        </>
    )
}