import React, { useEffect } from 'react';
import Box from "@mui/material/Box";
import { Button, CircularProgress, Paper, Slide, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { VERIFY_ACCOUNT } from '../graphql/mutations';
import MainBox from '../shared/MainBox';

export default () => {
    const {verificationCode} = useParams();
    const navigate = useNavigate();
    const handleGoToLogin = () => {
        navigate('/')
    }

    const [verify, {error, loading}] = useMutation(VERIFY_ACCOUNT)

    useEffect(()=>{
        verify({
            variables: {
                verificationCode: verificationCode
            }
        });
    },[])


    return (
        <MainBox alignItems="center" justifyContent="center">
            <Slide direction='right' in={true} mountOnEnter timeout={1000}>
                <Paper sx={{width:'300px'}} elevation={3}>
                    <Box textAlign='center' mb={5} mt={5}>
                        <Typography variant='subtitle1'>
                            Account Verification.
                        </Typography>
                        {loading && <CircularProgress />}
                        {!loading && error && 
                            <Typography variant='caption'>
                                Ups! invalid verification code.
                            </Typography>
                        }
                        {!loading && !error && 
                            <Typography variant='caption'>
                                Account Verified.
                            </Typography>
                        }
                        <Box>
                            <Button variant='text' color='info' onClick={handleGoToLogin} >Go to login</Button>
                        </Box>
                    </Box>
                </Paper>
            </Slide>
        </MainBox>);
}