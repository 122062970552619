import React from 'react';
import Box from "@mui/material/Box"
import { Typography } from '@mui/material';
import DefaultContainer from '../DefaultContainer';


export default () => {
    return (
    <DefaultContainer>
        <Box textAlign='center' mb={5} mt={5}>
            <Typography variant='caption'>
                Ups! you are not allow to be here.
            </Typography>
            <Typography variant='h1'>
                403
            </Typography>
            <Typography variant='subtitle1'>
                Unauthorized
            </Typography>
        </Box>
    </DefaultContainer>);
}