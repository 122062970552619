import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";


export default ({status}) => {
    const {t} = useTranslation();
    let color = 'primary';

    switch(status) {
        case 'CANCELLED':
        case 'BLOCKED':
            color = 'error';
            break;
        case 'QUEUED':
        case 'INCOMPLETE':
            color = 'warning';
            break;
        case 'WORKING':
        case 'PAYED':
            color = 'primary';
            break;
        case 'COMPLETE':
        case 'ENABLED':
            color = 'success';
            break;
        default:
            color = 'default';
    }

    if (!status) {
        return undefined;
    }
    return <Chip 
        color={color}
        label={t(status)}
        size='small'
    />
}