import { useState } from "react";
import { Container, Box, Fab, Stack, IconButton, Tooltip, Typography, CircularProgress, Button, Paper, styled } from "@mui/material"
import DefaultContainer from "../shared/DefaultContainer"
import ModalContainer from "../shared/ModalContainer"
import Title from "../shared/Title"
import DiscountList from "./DiscountList"
import AddIcon from '@mui/icons-material/Add';
import DiscountForm from "./DiscountForm"
import Modal from '@mui/material/Modal';
import { useMutation, useQuery } from '@apollo/client';
import {DELETE_DISCOUNTS} from '../graphql/mutations';
import { GET_DISCOUNTS } from '../graphql/queries';
import Confirmation from "../shared/Confirmation";
import CustomToolbar from "../shared/CustomToolbar";
import { HasAccessTo } from "../auth/hooks";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useTenantId } from "../tenant/hooks";

const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
};

const FloutingStack = styled(Stack)({
    position: 'absolute',
    width: '100%',
    top: '-26px'
})

const DiscountModal = ({open, onClose, onAdded, discountId}) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <ModalContainer sx={{padding: 0}}>
                <DiscountForm 
                    discountId={discountId}
                    onCancel={onClose}
                    onAdded={onAdded}/>
            </ModalContainer>
        </Modal>
    )
}

export default ({}) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [tenantId] = useTenantId();

    const [open,setOpen] = useState(false);

    const [openDeleteConfirmation,setOpenDeleteConfirmation] = useState(false);

    const [selecteds,setSelecteds] = useState([]);

    const {data, loading} = useQuery(GET_DISCOUNTS, {
        variables: {
            tenants: [tenantId]
        }
    });

    const [deleteDiscounts, {loading:ldDiscounts}] = useMutation(DELETE_DISCOUNTS, {
        refetchQueries: [
            GET_DISCOUNTS
        ],
        onCompleted: ()=>{
            enqueueSnackbar(t("Operation done successfully."), {variant: "success"});
        }
    });
    const [editDiscountId,setEditDiscountId] = useState(undefined);

    const switchOpen = () => {
        setOpen(!open);
        if (!!open) {
            setEditDiscountId(undefined);
        }
    }

    const handleRowSelectedChanged = (value) => {
        setSelecteds(value);
    }

    const switchDeleteConfirmation = () => {
        setOpenDeleteConfirmation(!openDeleteConfirmation);
    }

    const handleDeleteDiscounts = async () => {
        switchDeleteConfirmation();
        await deleteDiscounts({
            variables: {
                discountIds: selecteds
            }
        });
    }

    const handleEditDiscount = () => {
        setEditDiscountId(selecteds[0]);
        switchOpen();
    }

    return (
        <>
            <Confirmation 
                open={openDeleteConfirmation}
                title={t("Confirmation")}
                description={t("AreYouSureToDeleteTheseDiscountsQuestion")}
                onClose={switchDeleteConfirmation}
                onNo={switchDeleteConfirmation}
                onYes={handleDeleteDiscounts}
            />
            <DiscountModal 
                onClose={switchOpen}
                onAdded={switchOpen}
                discountId={editDiscountId}
                open={open}/>
            <Container>
                <Box pt={2}>
                    <Title>{t('Discounts')}</Title>
                </Box>
            </Container>

            <DefaultContainer sx={{mt:0}} plain>
                <Paper>
                    <DiscountList 
                        loading={loading}
                        rows={data?.getDiscounts || []}
                        onRowSelectedChanged={handleRowSelectedChanged}
                        toolbar={()=><CustomToolbar 
                            entity='Discount'
                            selecteds={selecteds} 
                            deleting={ldDiscounts} 
                            onEdit={handleEditDiscount}
                            onDelete={switchDeleteConfirmation}
                        />}
                        />
                </Paper>
            </DefaultContainer>
       

            <HasAccessTo entity="Discount" code="CREATE">
                <Fab 
                    onClick={switchOpen}
                    color="primary" 
                    sx={fabStyle}>
                    <AddIcon />
                </Fab>
            </HasAccessTo>
        </>
    )
}