

const weekDays = [
    {
        name: 'Monday',
        index: 1
    },
    {
        name: 'Tuesday',
        index: 2
    },
    {
        name: 'Wednesday',
        index: 3
    },
    {
        name: 'Thursday',
        index: 4
    },
    {
        name: 'Friday',
        index: 5
    },
    {
        name: 'Saturday',
        index: 6
    },
    {
        name: 'Sunday',
        index: 0
    }
]

export default weekDays;