import { useQuery } from "@apollo/client";
import { GetUserAccess } from "../auth/hooks"
import { GET_SPECIALISTS } from "../graphql/queries";
import { useTenantId } from "../tenant/hooks";


export const useWorkingSpecialistIds = () => {
    const [tenantId] = useTenantId();
    const {data, loading} = useQuery(GET_SPECIALISTS, {
        variables: {
            enabled: true,
            tenants: [tenantId]
        }
    });

    const access= GetUserAccess({
        entity: "Specialist",
        code: "WORKSPACE"
    });

    if (access.length>0 && access.some(a=>!a.constraintRef) && data?.getSpecialists){
        return data.getSpecialists.map(s=>s._id);
    }

    return access.filter(a=>a.constraintRef).map(a=>a.constraintRef);
}