import {Box, Paper, useMediaQuery, useTheme} from '@mui/material';
import { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import ResponsiveAppBar from './ResponsiveAppBar';

const drawerWidth = 260+60;

const LOCALSTORAGE_MENU_OPEN_KEY = "menu.Open";

const getMenuOpen = () => {
    try {
        return JSON.parse(localStorage.getItem(LOCALSTORAGE_MENU_OPEN_KEY)) || false
    }
    catch
    {
        return true;
    }
}

const setMenuOpen = (value) => {
    localStorage.setItem(LOCALSTORAGE_MENU_OPEN_KEY, JSON.parse(value));
}

export default ({children}) => {
    const [open, setOpen] = useState(getMenuOpen());
    const toggleDrawer = () => {
        setOpen(!open);
    }

    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

    useEffect(() => {
        setMenuOpen(open);
    }, [open]);

    useEffect(() => {
            setOpen(isLargeScreen);
    }, [isLargeScreen]);

    return (
        <>
            {!isLargeScreen && <ResponsiveAppBar onOpenSidebar={toggleDrawer} />}
            <Box sx={{ display: 'flex', marginTop: (isLargeScreen ? '0px' : '56px')}}>
                <Sidebar 
                    isTemporary={!isLargeScreen}
                    sidebarWidth={drawerWidth} 
                    open={open} 
                    toggleSidebar={toggleDrawer} 
                    onLinkClick={toggleDrawer} />
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                        backgroundColor: 'background.paper'
                        }}
                        >
                    {children}
                </Box>
            </Box>
        </>
    )
}