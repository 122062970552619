import { useState } from "react";
import { Container, Fab, Paper } from "@mui/material"
import ModalContainer from "../shared/ModalContainer"
import Title from "../shared/Title"
import OfficeList from "./OfficeList"
import AddIcon from '@mui/icons-material/Add';
import OfficeForm from "./OfficeForm"
import Modal from '@mui/material/Modal';
import { useMutation, useQuery } from '@apollo/client';
import {DELETE_OFFICES} from '../graphql/mutations';
import { GET_OFFICES } from '../graphql/queries';
import Confirmation from "../shared/Confirmation";
import CustomToolbar from "../shared/CustomToolbar";
import { HasAccessTo } from "../auth/hooks";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useTenantId } from "../tenant/hooks";

const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
};

const OfficeModal = ({open, onClose, onAdded, officeId}) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <ModalContainer sx={{padding: 0}}>
                <OfficeForm 
                    officeId={officeId}
                    onCancel={onClose}
                    onAdded={onAdded}/>
            </ModalContainer>
        </Modal>
    )
}

const PatientForm = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    
    const [tenantId] = useTenantId();

    const [open,setOpen] = useState(false);

    const [openDeleteConfirmation,setOpenDeleteConfirmation] = useState(false);

    const [selecteds,setSelecteds] = useState([]);

    const {data, loading} = useQuery(GET_OFFICES, {
        variables: {
            tenants: [tenantId]
        }
    });

    const [deleteOffices, {loading:ldOffices}] = useMutation(DELETE_OFFICES, {
        refetchQueries: [
            GET_OFFICES
        ],
        onCompleted: ()=>{
            enqueueSnackbar(t("OperationDoneSuccessfully"), {variant: "success"});
        }
    });
    const [editOfficeId,setEditOfficeId] = useState(undefined);

    const switchOpen = () => {
        setOpen(!open);
        if (!!open) {
            setEditOfficeId(undefined);
        }
    }

    const handleRowSelectedChanged = (value) => {
        setSelecteds(value);
    }

    const switchDeleteConfirmation = () => {
        setOpenDeleteConfirmation(!openDeleteConfirmation);
    }

    const handleDeleteOffices = async () => {
        switchDeleteConfirmation();
        await deleteOffices({
            variables: {
                officeIds: selecteds
            }
        });
    }

    const handleEditOffice = () => {
        setEditOfficeId(selecteds[0]);
        switchOpen();
    }

    return (
        <>
            <Confirmation 
                open={openDeleteConfirmation}
                title={t("Confirmation")}
                description={t("AreYouSureToDeleteTheseOfficesQuestion")}
                onClose={switchDeleteConfirmation}
                onNo={switchDeleteConfirmation}
                onYes={handleDeleteOffices}
            />
            <OfficeModal 
                onClose={switchOpen}
                onAdded={switchOpen}
                officeId={editOfficeId}
                open={open}/>
            <Container sx={{mt:2}}>
                <Title>{t('Offices')}</Title>
            </Container>
            <Container>
                <Paper>
                    <OfficeList 
                        entity="Office"
                        loading={loading}
                        rows={data?.getOffices || []}
                        onRowSelectedChanged={handleRowSelectedChanged}
                        toolbar={()=><CustomToolbar 
                            entity="Office"
                            selecteds={selecteds} 
                            deleting={ldOffices} 
                            onEdit={handleEditOffice}
                            onDelete={switchDeleteConfirmation}
                        />}
                    />
                </Paper>
            </Container>
       
            <HasAccessTo entity="Office" code="CREATE">
                <Fab 
                    onClick={switchOpen}
                    color="primary" 
                    sx={fabStyle}>
                    <AddIcon />
                </Fab>
            </HasAccessTo>
        </>
    )
}

export default PatientForm;