import React, { useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useQuery } from '@apollo/client';
import allLocales from '@fullcalendar/core/locales-all';

import LinearProgress from '@mui/material/LinearProgress';

import AppointmentDay from './AppointmentDay';
import {Box, Chip, Divider, Fab, Paper, Stack, Typography} from '@mui/material';
import { GET_CALENDAR_AVAILABILITIES } from '../graphql/queries'
import * as moment from 'moment';
import Masonry from '@mui/lab/Masonry';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import multiMonthPlugin from '@fullcalendar/multimonth'
import { useTenantId } from '../tenant/hooks';
import {useTranslation} from "react-i18next";

const WEEKDAYS = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

const AppointmentCalendar = ({specialists}) => {
    const [date, setDate] = React.useState(null);
    const [startDate, setStartDate] = React.useState();
    const [endDate, setEndDate] = React.useState();
    const [tenantId] = useTenantId();
    const [t] = useTranslation();
    const { i18n } = useTranslation();

    const { data: eventsData, loading: eventsLoading, refetch } = useQuery(GET_CALENDAR_AVAILABILITIES, {
        skip: specialists.length===0,
        variables: {
            specialistIds: specialists.map(s=>s._id),
            from: moment(startDate).startOf('day').utc(),
            to: moment(endDate).endOf('day').utc()
        }
    });

    useEffect(()=>{
        if (!startDate || !endDate || specialists.length===0) {
            return;
        }
        
        refetch({
            specialistIds: specialists.map(s=>s._id),
            from: moment(startDate).startOf('day').utc(),
            to: moment(endDate).endOf('day').utc()
        });
    },
    [specialists, startDate, endDate, refetch]);

    useEffect(()=>{
        setDate(null);
    }, [tenantId]);

    const handleDateClicked = (event) => {
        setDate(event.dateStr);
    }

    const unselectDate = () => {
        setDate(null);
    }

    const handleFullCalendarChange = ({start, end}) => {
        setStartDate(start);
        setEndDate(end);
    }

    const dayHeaderContent = ({text, dow}) => {
        const dayName = WEEKDAYS[dow].toLowerCase();
        return <Stack alignContent='center' spacing={2}>
            {text}
            <Stack direction='row' spacing={1} justifyContent='center'>
                {specialists?.filter(s => { return s.schedule && s.schedule[dayName] && s.schedule[dayName].length>0; })?.map(s => <Chip key={s._id} size="small" label={s.code}/>)}
            </Stack>
        </Stack>;
    }

    return (
        <>
            {(eventsLoading) && <LinearProgress /> }
            {date==null ? 
                <FullCalendar
                    plugins={[ dayGridPlugin, interactionPlugin, multiMonthPlugin ]}
                    initialView="dayGridMonth"
                    multiMonthMaxColumns={1} // force a single column
                    dateClick={handleDateClicked}
                    datesSet={handleFullCalendarChange}
                    dayHeaderContent={dayHeaderContent}
                    firstDay={7}
                    events={
                        (eventsData?.getCalendarAvailabilities?.map(ca => 
                            ca.specialistAvailabilities.map(sa => ({
                                title: `${sa.specialist.code} (${sa.count} / ${sa.total}) ${t('Consults')}`,
                                date: moment.utc(ca.date).local().format("yyyy-MM-DD")
                            })))?.reduce((r, arr)=> [...r, ...arr], []) ?? [])
                    }
                    businessHours={{
                        // days of week. an array of zero-based day of week integers (0=Sunday)
                        daysOfWeek: [ 1, 2, 3, 4, 5 ], // Monday - Thursday
                    }}
                    locales={allLocales}
                    locale={i18n.language}
                    views={{
                        dayGridMonth: {
                            dayHeaderFormat: {
                                weekday: 'long',
                            }
                        }
                    }}
                />
            :
                <Stack spacing={2}>
                    <Stack direction="row">
                        <Fab color='primary' size='small' variant='contained' onClick={unselectDate}>
                            <ArrowBackIcon />
                        </Fab>
                        <Typography sx={{flexGrow:1}} mt={1} textAlign="center" variant="h6" color="secondary" gutterBottom>
                            {date}
                        </Typography>
                    </Stack>
                    <Divider />
                    <Masonry columns={1}>
                        {specialists && specialists.length===0 && <Typography>N/A</Typography>}
                        { specialists && specialists.map(specialist=>{
                            return (
                                <AppointmentDay 
                                    key={specialist._id}
                                    specialist={specialist} 
                                    date={date} />
                                )
                        })}
                    </Masonry>
                </Stack>
            }
        </>
    )
}

export default AppointmentCalendar;