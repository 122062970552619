import { Button, IconButton, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client';
import {
    GET_AVAILABILITIES,
    GET_PAGINATED_APPOINTMENTS, GET_QUEUED_APPOINTMENTS
} from '../graphql/queries'
import { CANCEL_APPOINTMENT } from '../graphql/mutations'
import Confirmation from '../shared/Confirmation';
import { EventBusy } from '@mui/icons-material';

export default ({appointmentId, onCancel}) => {
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const { t } = useTranslation();

    const showConfirmation = (_, e) => {
        setOpenConfirmation(true);
    }

    const [ cancelAppointment ] = useMutation(CANCEL_APPOINTMENT, {
        refetchQueries: [
            GET_AVAILABILITIES,
            GET_PAGINATED_APPOINTMENTS,
            GET_QUEUED_APPOINTMENTS
        ]
    });

    const handleClose = () => {
        setOpenConfirmation(false);
    }

    const handleSubmit = async () => {
        const response = await cancelAppointment({
            variables: {
                appointmentId
            }
        });
        onCancel && onCancel();
    }

    return <>
        <Confirmation 
            open={openConfirmation}
            onNo={handleClose}
            onClose={handleClose}
            onYes={handleSubmit}
            title={t('Confirmation')}
            description={t('AreYouSureQuestion')}
        />
        <Tooltip title={t('Cancel')}>
            <IconButton size='small' variant='contained' color='error' onClick={showConfirmation} >
                <EventBusy />
            </IconButton>
        </Tooltip>
    </>
}
