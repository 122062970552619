import React from 'react';
import { useTranslation } from "react-i18next";
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';


export default ({medicalTests}) => {
    const { t } = useTranslation();

    return (
        <>
            {medicalTests && medicalTests.length > 0 ? 
                medicalTests.map((test, index) => <Chip size='small' color={(test?.metadata?.color) || 'default'} key={index} sx={{marginRight: '10px'}} label={test.name} />):
                <Typography>{t('ThereIsNotData')}</Typography>
            }
        </>
    )
};
