import { Box, Button, Divider, Paper, Skeleton, Slide, Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import MainBox from "../shared/MainBox";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { FIND_APPOINTMENT_BY_CONFIRMATION_ACCESS_TOKEN } from "../graphql/queries";
import { useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import { formatMoney } from "../shared/format-helpers";
import { useSnackbar } from "notistack";
import { CONFIRM_APPOINTMENT_BY_CONFIRMATION_ACCESS_TOKEN } from "../graphql/mutations";



const AppointmentExternalConfirmationView = () => {
    const { t } = useTranslation();
    const { confirmationAccessToken } = useParams();
    const { enqueueSnackbar } = useSnackbar();
  
    const {data, loading, error} = useQuery(FIND_APPOINTMENT_BY_CONFIRMATION_ACCESS_TOKEN, {
        variables: {
            confirmationAccessToken: confirmationAccessToken
        }
    });

    const [confirm, { loading: loadingMutation, data: dataMutation }] = useMutation(CONFIRM_APPOINTMENT_BY_CONFIRMATION_ACCESS_TOKEN, {
        refetchQueries: [
            FIND_APPOINTMENT_BY_CONFIRMATION_ACCESS_TOKEN
        ],
        onCompleted: ()=>{
            enqueueSnackbar(t("OperationDoneSuccessfully"), {variant: "success"});
        }
    });

    const handleRetry = () => {
        window.location.reload();
    }

    const handleConfirmation = (confirmResult) => () => {
        confirm({
            variables: {
                confirmationAccessToken: confirmationAccessToken,
                confirm: confirmResult
            }
        });
    }

    const dataOrLoading = (data || loading);

    return <MainBox alignItems="center" justifyContent="center">
            <Slide direction='right' in={true} mountOnEnter timeout={1000}>
                <Stack sx={{width: '300px'}} alignItems="center">
                    <Paper sx={{width:'100%'}} elevation={3}>
                        <Box p={2} pb={0}>
                            <Typography fontWeight='bold' width='100%' align="center">
                                {t('Appointment')}
                            </Typography>
                        </Box>
                        <Box p={2}>
                            <Stack>
                                { dataOrLoading ? 
                                <>
                                    <Stack direction='row' spacing={2}> 
                                        <Typography fontWeight="500">
                                        {t('Status')}:
                                        </Typography>
                                        { loading ? 
                                            <Skeleton width='100%'></Skeleton> : 
                                            <Typography width='100%' align="right">{t(data.findAppointmentByConfirmationAccessToken.status)}</Typography>
                                        }
                                    </Stack>
                                    <Stack direction='row' spacing={2}> 
                                        <Typography fontWeight="500">
                                        {t('Code')}:
                                        </Typography>
                                        { loading ? 
                                            <Skeleton width='100%'></Skeleton> : 
                                            <Typography width='100%' align="right">{t(data.findAppointmentByConfirmationAccessToken.code)}</Typography>
                                        }
                                    </Stack>
                                    <Stack direction='row' spacing={2}> 
                                        <Typography fontWeight="500">
                                        {t('Confirmed')}:
                                        </Typography>
                                        { loading ? 
                                            <Skeleton width='100%'></Skeleton> : 
                                            <Typography width='100%' align="right">{data.findAppointmentByConfirmationAccessToken.confirmed===true?t('Yes'): (data.findAppointmentByConfirmationAccessToken.confirmed===false? t('No'): t('N/A')) }</Typography>
                                        }
                                    </Stack>
                                    <Stack direction='row' spacing={2}> 
                                        <Typography fontWeight="500">
                                        {t('Date')}:
                                        </Typography>
                                        { loading ? 
                                            <Skeleton width='100%'></Skeleton> : 
                                            <Typography width='100%' align="right">{moment.utc(data.findAppointmentByConfirmationAccessToken.date).local().format('yyyy-MM-DD HH:mm')}</Typography>
                                        }
                                    </Stack>
                                    <Stack direction='row' spacing={2}> 
                                        <Typography fontWeight="500">
                                        {t('Specialist')}:
                                        </Typography>
                                        { loading ? 
                                            <Skeleton width='100%'></Skeleton> : 
                                            <Typography width='100%' align="right">{data.findAppointmentByConfirmationAccessToken.specialist.name}</Typography>
                                        }
                                    </Stack>
                                    <Stack direction='row' spacing={2}> 
                                        <Typography fontWeight="500">
                                        {t('Service')}:
                                        </Typography>
                                        { loading ? 
                                            <Skeleton width='100%'></Skeleton> : 
                                            <Typography width='100%' align="right">{data.findAppointmentByConfirmationAccessToken.service.name}</Typography>
                                        }
                                    </Stack>
                                    <Stack direction='row' spacing={2}> 
                                        <Typography fontWeight="500">
                                        {t('Amount')}:
                                        </Typography>
                                        { loading ? 
                                            <Skeleton width='100%'></Skeleton> : 
                                            <Typography width='100%' align="right">{formatMoney(data.findAppointmentByConfirmationAccessToken.totalAmount)}</Typography>
                                        }
                                    </Stack>
                                </>
                                :
                                <Typography width='100%' align="center">
                                    {t('Not Found Or Error')}
                                </Typography>
                                }
                            </Stack>
                        </Box>
                        <Box p={2} pt={0}>
                            {!loading && data && data.findAppointmentByConfirmationAccessToken.status==='PENDING' &&
                                <Stack spacing={2} direction='row'>
                                    <LoadingButton
                                        disabled={data.findAppointmentByConfirmationAccessToken.confirmed===false}
                                        fullWidth
                                        color="error" 
                                        variant="contained"
                                        loading={loadingMutation}
                                        onClick={handleConfirmation(false)}
                                        >{t('Unconfirm')}
                                    </LoadingButton>

                                    <LoadingButton
                                        disabled={data.findAppointmentByConfirmationAccessToken.confirmed===true}
                                        fullWidth
                                        color="primary" 
                                        variant="contained"
                                        loading={loadingMutation}
                                        onClick={handleConfirmation(true)}
                                        >{t('Confirm')}
                                    </LoadingButton>
                                    
                                </Stack>
                        }
                        
                        {!dataOrLoading && 
                            <Button 
                                fullWidth
                                variant="contained" 
                                color="primary" onClick={handleRetry}>
                                {t('Retry')}
                            </Button>
                        }
                        </Box>
                    </Paper>
                    <Box p={2}>
                        <Typography variant="caption" color="initial">
                            Copyright ©2023.
                        </Typography>
                    </Box>
                </Stack>
            </Slide>
        </MainBox>
    }

export default AppointmentExternalConfirmationView;