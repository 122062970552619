import {
    Button,
    Divider,
    Stack,
    Box,
    Paper,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useForm, FormProvider} from "react-hook-form";
import {useTranslation} from 'react-i18next'
import Title from '../../shared/Title';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {
    FormTextField,
} from '../../shared/form-components';
import {useMutation, useQuery} from '@apollo/client';
import {
    CREATE_GROUP, UPDATE_GROUP
} from '../../graphql/mutations'
import {
    FIND_GROUP_BY_ID, GET_GROUPS
} from '../../graphql/queries'
import Confirmation from '../../shared/Confirmation';
import {LoadingButton} from '@mui/lab';
import {AccessSection, accessSchema} from '../form-components';
import {useSnackbar} from 'notistack';
import {useTenantId} from '../../tenant/hooks';

export default ({
                    groupId,
                    onCancel,
                    onAdded
                }) => {

    const isNew = !groupId;
    const [tenantId] = useTenantId();
    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();

    const [saveGroup, {data, loading}] = useMutation(isNew ? CREATE_GROUP : UPDATE_GROUP, {
        refetchQueries: [FIND_GROUP_BY_ID, GET_GROUPS]
    })
    const {data: groupData} = useQuery(FIND_GROUP_BY_ID, {
        variables: {
            groupId: groupId
        },
        skip: isNew
    });

    const groupSchema = yup.object({
        _id: yup.string(),
        name: yup.string().required(t('RequiredField', {Field: t('Name')})),
        access: yup.array().of(accessSchema)
    }).transform((v) => {
        if (v._id) {
            return v;
        } else {
            const {_id, ...result} = v;
            return result;
        }
    });

    const methods = useForm({
        resolver: yupResolver(groupSchema),
        mode: 'all',
        defaultValues: {
            _id: groupId,
            access: []
        }
    });

    const {setValue, handleSubmit, control, formState: {errors}} = methods;
    const [openConfirmation, setOpenConfirmation] = useState(false);

    const onSubmit = async data => {
        data.access = data.access.map(a => ({entityRef: a.entityRef, actionId: a.actionId}));
        const response = await saveGroup({
            variables: {
                input: data,
                ...(isNew ? {tenants: [tenantId]} : {})
            }
        });
        onAdded && onAdded({
            ...data,
            ...response.data.group
        });

        enqueueSnackbar(t("OperationDoneSuccessfully"), {variant: "success"});
    }

    useEffect(() => {
        if (!groupData?.findGroup) {
            return;
        }

        setValue("name", groupData.findGroup.name);
        setValue("access", groupData.findGroup.access.map(a => {
            return {
                ...a,
                actionId: a?.action?._id
            }
        }));
    }, [groupData]);

    const handleClose = () => {
        setOpenConfirmation(false);
    }

    const showConfirmation = (_, e) => {
        setOpenConfirmation(true);
    }

    return (
        <Paper>
            <Confirmation
                open={openConfirmation}
                onNo={handleClose}
                onClose={handleClose}
                onYes={handleSubmit(onSubmit)}
                title={t('Confirmation')}
                description={t('AreYouSureQuestion')}
            />
            <FormProvider {...methods}>
                <Box p={2}>
                    <Title sx={{marginBottom: 0}}>{t(isNew ? "CreateGroup" : "EditGroup")}</Title>
                </Box>
                <Divider/>
                <Box p={2}>
                    <Stack spacing={2} sx={{overflowY: 'scroll', height: '50vh'}}>
                        <FormTextField
                            label={t('Name')}
                            control={control}
                            name="name"/>
                        <AccessSection name="access"/>
                    </Stack>
                </Box>
                <Divider/>
                <Box p={2}>
                    <Stack direction="row" justifyContent="space-between">
                        <Button
                            disabled={loading}
                            variant='contained'
                            color="grey"
                            onClick={onCancel}
                        >{t('Back')}</Button>
                        <LoadingButton
                            loading={loading}
                            variant='contained'
                            color='primary'
                            onClick={handleSubmit(showConfirmation)}
                        >{t('Submit')}</LoadingButton>
                    </Stack>
                </Box>
            </FormProvider>
        </Paper>
    )
}