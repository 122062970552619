import { Box, Chip } from '@mui/material';
import {DataGrid} from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next';
import StatusChip from '../../shared/StatusChip';


const buildColumns = (t) => ([
    { field: '_id', headerName: t('ID'), width: 70, },
    { field: 'status', headerName: t('Status'), width: 100,
      renderCell:({value})=> <StatusChip status={value} />
    },
    { field: 'fullName', headerName: t('Fullname'), width: 130 },
    { field: 'email', headerName: t('Email'), width: 130 },
    { field: 'username', headerName: t('Username'), width: 130 },
    { 
      field: 'groups', 
      headerName: t('Groups'), 
      width: 330,
      renderCell: (row)=>row.value.map((g,index)=><Chip size="small" key={index} label={g.name} />)
    }
  ]);
  
  export default ({rows, loading, onRowSelectedChanged, onPaginationModelChange, toolbar}) => {
    const {t} = useTranslation();
    const columns = buildColumns(t);

    return (
      <Box sx={{ height: 450, width: '100%' }}>
        <DataGrid
          density='compact'
          rows={rows}
          columns={columns}
          loading={loading}
          getRowId={(r)=>r._id}
          onRowSelectionModelChange={onRowSelectedChanged}
          initialState={{
            columns: {
              columnVisibilityModel: {
                _id: false
              }
            },
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 15, 20]} 
          onPaginationModelChange={onPaginationModelChange}
          checkboxSelection
          slots={{
            toolbar: toolbar
          }}
        />
      </Box>
    );
  }