import {useMemo, useState} from 'react';
import {createThemeMode} from "./ThemeSettings";
import {useTranslation} from "react-i18next";

export default function useThemeContext() {
    const [mode, setMode] = useState(() => {
        return localStorage.getItem('themeMode') || 'light';
    });

    const { i18n } = useTranslation();
    const [locale, setLocale] = useState(() => {
        return i18n.language;
    });

    const colorMode = useMemo(() => ({
        toggleColorMode: () => {
            setMode((prevMode) => {
                const newMode = prevMode === 'light' ? 'dark' : 'light';
                localStorage.setItem('themeMode', newMode);
                return newMode;
            });
        },
    }), []);

    const localeMode = useMemo(() => ({
        changeLocale: () => {
            setLocale((prevLocale) => {
                return prevLocale === 'en' ? 'es' : 'en';
            });
        },
    }), []);

    const theme = useMemo(() => createThemeMode(mode, locale), [mode, locale]);

    return [mode, colorMode, theme, localeMode];
}