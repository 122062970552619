import { Avatar, Divider, Fade, IconButton, ListItemIcon, Menu, MenuItem, Modal, Stack, Tooltip, Typography } from "@mui/material"
import ChangePassword from '../auth/ChangePassword';
import { HasAccessTo, useToken, useUser } from "../auth/hooks";
import ModalContainer from './ModalContainer';
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Logout, Password, Settings } from "@mui/icons-material";
import TenantForm from "../tenant/TenantForm";

const ChangePasswordModal = ({open, onClose }) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <ModalContainer sx={{padding: 2}} width={300}>
                <ChangePassword onCancel={onClose} onChanged={onClose} />
            </ModalContainer>
        </Modal>
    )
}

const TenantSettingsModal = ({open, onClose}) => {
    return (<Modal
        open={open}
        onClose={onClose}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
    >
        <Fade in={open}>
            <ModalContainer width={550}>
                <TenantForm
                    onBack={onClose}
                    onSaved={onClose}/>
            </ModalContainer>
        </Fade>
    </Modal>);
}

export default () => {
    const {t} = useTranslation();
    const [user, setUser] = useUser();
    const [_, setToken] = useToken();
    const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [openTenantModal, setOpenTenantModal] = useState(false);
   
    const handleLogout = () => {
        setUser("");
        setToken("");
    }

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleSettings = () => {
        setOpenTenantModal(true);
    }

    const open = Boolean(anchorElUser);

    const handleCloseChangePassword = () => {
        setOpenChangePasswordModal(false);
    }

    const handleOpenChangePassword = () => {
        setOpenChangePasswordModal(true);
    }

    return <>
            <ChangePasswordModal 
                open={openChangePasswordModal}
                onClose={handleCloseChangePassword}
            />

            <TenantSettingsModal 
                open={openTenantModal}
                onClose={()=>{ setOpenTenantModal(false) }}
            />
            <Tooltip title={user.fullName}>
                <IconButton onClick={handleOpenUserMenu}
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}>
                    <Avatar alt={user.fullName} sx={{width: 28, height: 28}}
                            src="/static/images/avatar/2.jpg"/>
                </IconButton>
            </Tooltip>
            <Menu
                id="account-menu"
                anchorEl={anchorElUser}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                transformOrigin={{horizontal: 'right', vertical: 'bottom'}}
                anchorOrigin={{horizontal: 'right', vertical: 'top'}}
            >
            <MenuItem onClick={handleCloseUserMenu}>
                <Stack spacing={2} direction='row' alignItems='center'>
                    <Avatar size="small"/> 
                    <Typography>
                        {t("Profile")}
                    </Typography>
                </Stack>
            </MenuItem>
            <Divider/>
            <HasAccessTo entity="Tenant" code="UPDATE">
                <MenuItem onClick={handleSettings}>
                    <ListItemIcon>
                        <Settings fontSize="small"/>
                    </ListItemIcon>
                    {t("Settings")}
                </MenuItem>
            </HasAccessTo>
            <MenuItem onClick={handleOpenChangePassword}>
                <ListItemIcon>
                    <Password fontSize="small"/>
                </ListItemIcon>
                {t("ChangePassword")}
            </MenuItem>
            <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                    <Logout fontSize="small"/>
                </ListItemIcon>
                {t("Logout")}
            </MenuItem>
        </Menu>
    </>
}