import { Box, Button, Divider, Stack, Paper } from '@mui/material';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from "react-hook-form";
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next'
import Title from '../shared/Title';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@apollo/client';
import { FIND_TIME_OFF_BY_ID, GET_PAGINATED_TIME_OFFS, GET_SPECIALISTS } from '../graphql/queries';
import { CREATE_TIME_OFF, UPDATE_TIME_OFF } from '../graphql/mutations';
import { FormAutocomplete, FormTextField, FormDateTimePicker } from '../shared/form-components';
import { getSpecialistText } from '../shared/label-helpers';
import { graphql } from '@apollo/client/react/hoc';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { useAccessConstraints } from '../auth/hooks';
import { useTenantId } from '../tenant/hooks';

const  FormAutocompleteGetSpecialistQuery = graphql(GET_SPECIALISTS, {
    props: ({ data: { getSpecialists , loading, tenantId}}) => ({
        options: getSpecialists || [],
        loading: loading
    }),
    skip : ({loading}) => loading,
    options: ({tenantId}) => ({
        variables: {
            enabled: true,
            tenants: [tenantId]
        }
    })
})(({...props})=><FormAutocomplete 
    getOptionLabel={getSpecialistText} 
    {...props} 
/>);

const TimeOffForm = ({timeOffId, onCancel, onAdded}) => {
    const isNew = !timeOffId;
    const [tenantId] = useTenantId();

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const specialistIds = useAccessConstraints({
        entity: 'TimeOff',
        code: isNew ? 'CREATE' : 'UPDATE',
        constraint: 'Specialist'
    });
    
    const [ saveTimeOff ] = useMutation(isNew ? CREATE_TIME_OFF : UPDATE_TIME_OFF, {
        refetchQueries: [
            GET_PAGINATED_TIME_OFFS,
            FIND_TIME_OFF_BY_ID
        ]
    });
 
    const {data, loading} = useQuery(FIND_TIME_OFF_BY_ID, {
        variables: {
            timeOffId: timeOffId
        },
        skip: isNew
    });

    const schema = yup.object({
        _id: yup.string(),
        specialistId: yup.string().required(t('RequiredField', {Field: t('Specialist')})),
        dateFrom: yup.date().typeError(t('InvalidDate')).required(),
        dateTo: yup.date().typeError(t('InvalidDate')).min(
            yup.ref('dateFrom'), t('DateFromMustBeLessThanDateTo')
        ).required(),
        reason: yup.string().required(t('RequiredField', {Field: t('Reason')}))
    });

    const methods = useForm({
        resolver: yupResolver(schema),
        mode: 'all',
        defaultValues: {
            _id: timeOffId,
            dateFrom: moment().startOf('day').utc(),
            dateTo: moment().endOf('day').utc(),
        }
    });
    const { setValue, handleSubmit, formState: { isSubmitting } } = methods;

    const onSubmit = async data => {
        const response = await saveTimeOff ({
            variables: {
                input: data,
                ...(isNew ? {tenants:[tenantId]}: {})
            }
        });
        onAdded && onAdded({
            ...data,
            ...response.data.saveTimeOff 
        });

        enqueueSnackbar(t("OperationDoneSuccessfully"), {variant: "success"});
    }
    
    useEffect(()=>{
        if (!data?.findTimeOff) {
            return;
        }

        setValue("specialistId", data.findTimeOff.specialist?._id);
        setValue("dateFrom", moment(data.findTimeOff.dateFrom));
        setValue("dateTo", moment(data.findTimeOff.dateTo));
        setValue("reason", data.findTimeOff.reason);
    }, [data, setValue]);

    return (
        <Paper>
            <FormProvider {...methods}>
                    <Box p={2}>
                        <Title sx={{marginBottom: 0}}>{t(isNew?"CreateTimeOff ":"EditTimeOff")}</Title>
                    </Box>
                    <Divider />
                    <Box p={2}>
                        <Stack spacing={2}>
                           <FormDateTimePicker
                                label={t('DateFrom')}
                                name="dateFrom"
                                variant="outlined"
                                size="small"
                                fullWidth
                            /> 
                            <FormDateTimePicker 
                                label={t('DateTo')}
                                name="dateTo"
                                variant="outlined"
                                size="small"
                                fullWidth
                            />
                            <FormAutocompleteGetSpecialistQuery 
                                label={t('Specialist')}
                                name="specialistId"
                                loading={loading}
                                tenantId={tenantId}
                                getOptionDisabled={(s)=>{
                                    return specialistIds.length>0 && specialistIds.indexOf(s._id)===-1;
                                }}
                                fullWidth
                            />
                             <FormTextField 
                                fullWidth
                                rows={3}
                                multiline={true} 
                                name="reason"
                                label={t("Reason")}
                                disabled={loading}
                            />
                        </Stack>
                    </Box>
                    <Divider />
                    <Box p={2}>
                        <Stack direction="row" justifyContent="space-between">
                            <Button variant='contained' color="grey" onClick={onCancel} disabled={isSubmitting}>{t('Cancel')}</Button>
                            <LoadingButton 
                                variant='contained' 
                                color='primary' 
                                loading={isSubmitting}
                                onClick={handleSubmit(onSubmit)}>{t('Submit')}</LoadingButton>
                        </Stack>
                    </Box>
            </FormProvider>
        </Paper>
    )
}

export default TimeOffForm;