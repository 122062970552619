import { useQuery } from "@apollo/client";
import { createContext, useContext } from "react";
import { useEffect, useState } from "react"
import { GET_CURRENT_USER } from "../graphql/queries";

const LS_TOKEN_KEY = 'token';

export const getStoredToken = () => {
    try {
        return JSON.parse(localStorage.getItem(LS_TOKEN_KEY)) ?? [];
    } catch {
        return [];
    }
}

export const AuthUserContext = createContext({
    user: '',
    setUser: () => {}
});

export const AuthTokenContext = createContext({
    token: '',
    setToken: () => {},
});

export const useUser = () => {
    const {user, setUser, loading} = useContext(AuthUserContext);
    return [user, setUser, loading];
}

export const getAllAccess = (obj) => {
    if (!obj) {
        return [];
    }

    return (obj.access?.map(a=>{
        if (!a.entityConstraintRefKeys || !obj.entityConstraintRefs || a.entityConstraintRefKeys.length==0) {
            return [
                {
                    code: a.action.code,
                    entity: a.action.entity
                }
            ]
        } else {
            return obj.entityConstraintRefs
                .filter(ecr => a.entityConstraintRefKeys.indexOf(ecr.key)>=0)
                .map(ecr => ({
                    code: a.action.code,
                    entity: a.action.entity,
                    constraint: ecr.entity,
                    constraintRef: ecr.entityRef
                }));
        }
    })??[]).reduce((a,c) => [...a, ...c], []);
}


export const getUserAccess = (user) => {
    if (!user) {
        return [];
    }

    const groupAccessTokens = (user.groups?.map(g=>getAllAccess(g))??[]).reduce((t,c)=>[...t,...c], []);
    return getAllAccess(user).concat(groupAccessTokens);
}

export const useCurrentUserAccess = () => {
    const [user] = useUser();
    if (!user) {
        return [];
    }

    return getUserAccess(user);
}

export const getAccess = (access) => ({entity, code, entityRef, constraint})=> {
    if (entity) {
        access = access.filter(a=>a.entity === entity);
    }

    if (code) {
        access = access.filter(a=>a.code === code);
    }

    if (constraint) {
        if (access.filter(a=>!a.constraint).length>0) {
            return access;
        }

        const isArray = Array.isArray(entityRef);
        if (entityRef && (!isArray || entityRef.length>0)) {
            access = access.filter(a=> isArray ? entityRef.indexOf(a.constraintRef)>=0 :a.constraintRef === entityRef); 
        }

        if (entityRef && isArray && entityRef.length>0 && !entityRef.every(e => access.find(a=> a.constraintRef === e))) {
            access = [];
        }
    }
    return access;
}

export const useAccessConstraints = ({entity, code, constraint}) => {
    const access = getAccess(useCurrentUserAccess())({entity, code, constraint});
    return access?.map(a=>a.constraintRef).filter(a=>a) ??[];
}

export const GetUserAccess = ({entity, code, entityRef, constraint}) => {
    let access = useCurrentUserAccess();
    return getAccess(access)({entity, code, entityRef, constraint});
}

export const hasAccess = (access) => (props) => {
    return getAccess(access)(props).length>0;
}

export const HasAccessTo = ({entity, code, constraint, entityRef, children, any, unauthorizedElement}) => {
    const access = useCurrentUserAccess();
    const accessFunc = hasAccess(access);
    const isVisisble = !!(
        (!any && !entity && !code && !entityRef && !constraint) ||
        (any && (any.some(a=>accessFunc(a)) || any.length==0)) || 
        ((entity || code) && accessFunc({entity, code, entityRef, constraint}))
    );
    
    if (children) {
        if (isVisisble) {
            return children
        } else {
            return unauthorizedElement;
        }
    } 
    return isVisisble;
}

export const useToken = () => {
    const {token, setToken} = useContext(AuthTokenContext);
    return [token, setToken];
}

export const AuthUserProvider = ({authenticated, children}) => {
    const [user, setUser] = useState('');
    const {data, refetch, loading} = useQuery(GET_CURRENT_USER);

    const value = {
        user,
        setUser,
        loading
    };

    useEffect(()=>{
        if (!data?.getCurrentUser) {
            return;
        }
        setUser(data.getCurrentUser);
    }, [data]);

    useEffect(() => {
        if (authenticated) {
            refetch();
        }
    }, [authenticated]);

    return (
        <AuthUserContext.Provider value={value}>
            {children}
        </AuthUserContext.Provider>
    )
}

export const AuthTokenProvider = ({children}) => {
    const [token, setToken] = useState(getStoredToken());
     const value = {
        token,
        setToken
     };

     useEffect(()=>{
        localStorage.setItem(LS_TOKEN_KEY,JSON.stringify(token));
     }, [token]);

    return (
        <AuthTokenContext.Provider value={value}>
            {children}
        </AuthTokenContext.Provider>
    )
}