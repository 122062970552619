import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client"
import { GET_ACTIVE_TURNS } from '../graphql/queries'
import { Box, Button, Divider, Grid, Paper, Skeleton, Stack, Typography } from "@mui/material";

import { red, common, yellow, blue } from '@mui/material/colors';
import moment from "moment";
import { useToken } from "../auth/hooks";
import { useAsyncTurns } from './hooks';
import { useTranslation } from "react-i18next";
import { useTenantId } from "../tenant/hooks";

const COLORS = {
    queued: {
        bgColor: yellow[500],
        textColor: common.black,
    },
    new: {
        bgColor: red[500],
        textColor: common.white
    },
    working: {
        bgColor: blue[500],
        textColor: common.white
    }
}

const MINUTES_DIFF = 5;

const TurnData = ({text, subText, bgColor, textColor}) => {
    return <Paper elevation={12} >
        <Box p={2} textAlign="center" bgcolor={bgColor} color={textColor}>
            <Typography variant="h4" >
                {text}
            </Typography>
        </Box>
        {subText && <>
            <Divider />
            <Box p={2} textAlign="center">
                <Typography variant="overline">
                    {subText}
                </Typography>
            </Box>
        </>}
    </Paper>
}

export default ({}) => {
    const {t} = useTranslation();
    const [tenantId] = useTenantId();
    const [turn, isConnected, reconnect] = useAsyncTurns();
    const {data, refetch} = useQuery(GET_ACTIVE_TURNS, {
        variables: {
            tenants: [tenantId]
        }
    });
    const [token] = useToken();
    const [working, setWorking] = useState([]);
    const [newWorking, setNewWorking] = useState([]);
    const [queued, setQueued] = useState([]);

    const setTurns = () => {
        if (!data?.getActiveTurns){
            return;
        }

        const workings =  data.getActiveTurns.filter(t=> t.status=="WORKING");
        const currentM = moment(new Date);
        const newWorkings = workings.filter(w=> moment(currentM).diff(w.startedAt, "minutes") <= MINUTES_DIFF);
        const oldWorkings = workings.filter(w=> moment(currentM).diff(w.startedAt, "minutes") > MINUTES_DIFF);

        setNewWorking(newWorkings);
        setWorking(oldWorkings);
        setQueued(data.getActiveTurns.filter(t=>t.status === "QUEUED"));
    }

    useEffect(()=>{
      setTurns();
    }, [data?.getActiveTurns]);

    useEffect(()=>{
        refetch();
    }, [turn]);

    return (
    <Box p={4}>
        <Box>
            {isConnected ? t("Connected") : 
                <Button variant="outlined" color="primary" onClick={reconnect}> {t('Reconnect')}</Button> }
        </Box>
        <Typography variant="h3"  textAlign="center" mt={2}>
            {t('Working')}
        </Typography>
        <Grid container spacing={2}>
            {newWorking.map(t=>{
                return <Grid key={t._id} item xs={3}>
                    <TurnData 
                        text={t.code} 
                        subText={t.description}
                        {...COLORS.new} 
                        />
                </Grid>
            })}
           {working.map(t=>{
                return <Grid key={t._id} item xs={3}>
                    <TurnData 
                        text={t.code} 
                        subText={t.description}
                        {...COLORS.working}
                        />
                </Grid>
            })}
            {!newWorking.length && !working.length && (
                <Grid item xs={12}>
                    <Skeleton height={100} />
                </Grid>
            )
            }
        </Grid>
        <Typography variant="h3" textAlign="center" mb={2}>
            {t('Queued')}
        </Typography>
        <Grid container spacing={2}>
            {queued.map(t=>{
                return <Grid key={t._id} item xs={3}>
                    <TurnData  text={t.code} {...COLORS.queued}/>
                </Grid>
            })}
            {!queued.length && (
                <Grid item xs={12}>
                    <Skeleton height={100} />
                </Grid>
                )
            }
        </Grid>
    </Box>
    );
}