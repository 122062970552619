import React from 'react';
import { CircularProgress, Slide } from '@mui/material';
import MainBox from '../MainBox';

export default () => {
    return (
        <MainBox alignItems="center" justifyContent="center">
            <Slide direction='right' in={true} mountOnEnter timeout={1000}>
                <CircularProgress />
            </Slide>
        </MainBox>);
}