import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Avatar, Button, Divider, Typography } from '@mui/material';
import Box from '@mui/material/Box';

import { Stack } from '@mui/system';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { FormTextField } from '../../shared/form-components';
import Title from '../../shared/Title';
import { useMutation } from '@apollo/client';
import { LOGIN } from '../../graphql/mutations';
import { useToken } from '../hooks';
import { createRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Google, Lock } from '@mui/icons-material';
import { blue } from '@mui/material/colors';
import { Link } from 'react-router-dom';

const credentialSchema = Yup.object({
    username: Yup.string().required(),
    password: Yup.string().required()
});

const LoginForm = ({switchToResetPassword, switchToSignUp}) => {
    const {t} = useTranslation();
    const passwordRef = createRef();
    const {control, handleSubmit, formState: {isLoading} } = useForm({
        resolver: yupResolver(credentialSchema)
    });

    const [_, setToken] = useToken();

    const [loginFnc, { data }] = useMutation(LOGIN);

    const login = async (data) =>{
        await loginFnc({
            variables: {
                input: {
                    ...data
                }
            }
        });
    }

    useEffect(()=> {
        if (!data){
            return;
        }

        setToken(data.login.access_token);
    }, [data]);

    const handlePasswordKeyDown = (event) => {
        if (event.keyCode === 13) {
            handleSubmit(login)();
        }
    }

    const handleUsernameKeyDown = (event) => {
        if (event.keyCode === 13) {
            passwordRef.current.focus();
        }
    }

    return (<Box p={2}>
                <Stack spacing={3}>
                    <Stack p={2} alignItems="center">
                        <Avatar sx={{ bgcolor: blue[500] }}>
                            <Lock />
                        </Avatar>
                        <Title sx={{marginBottom:0}}>{t('Login')}</Title>
                    </Stack>
                    <FormTextField 
                        onKeyDown={handleUsernameKeyDown}
                        control={control}
                        label={t("Username")}
                        name="username"
                        />

                    <Stack spacing={1}>
                        <FormTextField 
                            ref={passwordRef}
                            onKeyDown={handlePasswordKeyDown}
                            control={control}
                            type='password'
                            label={t("Password")}
                            name="password"
                            inputProps={{
                                autocomplete: 'new-password',
                                form: {
                                autocomplete: 'off',
                                },
                            }}
                        />
                        <Link onClick={switchToResetPassword}>
                            <Typography variant='caption'>
                                {t('Reset password')}
                            </Typography>
                        </Link>
                    </Stack>
                    <Stack spacing={1}>
                        <LoadingButton 
                            fullWidth
                            color="primary" 
                            variant="contained"
                            loading={isLoading}
                            onClick={handleSubmit(login)}>{t('Login')}</LoadingButton>
                        <Divider>
                            <Typography variant='caption'>{t('OR LOGIN WITH')}</Typography>
                        </Divider>
                        <Button variant='outlined' disabled={true}>
                            <Stack direction='row' spacing={1}>
                                <Google />
                                <Typography variant='button'>
                                    Google
                                </Typography>
                            </Stack>
                        </Button>
                    </Stack>
                    

                    <Button 
                        fullWidth
                        size='small' 
                        disabled={isLoading} 
                        color='primary' 
                        variant='text'
                        onClick={switchToSignUp}>
                        {t('Sign Up for an account')}
                    </Button>
                </Stack>
            </Box>);
}

export default LoginForm;
