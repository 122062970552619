import {DataGrid, getGridDateOperators } from '@mui/x-data-grid'
import * as moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';

const buildColumns = (t) =>[
    { 
      filterable: false,
      field: 'specialist', 
      sortable: false,
      headerName: t('Specialist'), 
      width: 230,
      valueGetter: ({value}) => value?.name
    },
    { 
      type: 'date',
      field: 'dateFrom', 
      headerName: t('DateFrom'), 
      width: 130 ,
      filterOperators: getGridDateOperators().filter(x=>x.value === 'is'),
      valueGetter: ({value}) =>  moment(value).toDate(),
      renderCell: ({value}) =>  moment(value).format('DD-MM-yyyy HH:mm')
    },
    { 
      type: 'date',
      field: 'dateTo', 
      headerName: t('DateTo'), 
      width: 130 ,
      filterOperators: getGridDateOperators().filter(x=>x.value === 'is'),
      valueGetter: ({value}) =>  moment(value).toDate(),
      renderCell: ({value}) =>  moment(value).format('DD-MM-yyyy HH:mm')
    },
    { 
      field: 'reason', 
      headerName: t('Reason'), 
      width: 230,
    },
  ];
  
const TimeOffList = ({rows, count, loading, onFilterModelChange, onSortModelChange, onPaginationModelChange, onRowSelectedChanged, toolbar}) => {
  const {t} = useTranslation();
  const columns = buildColumns(t);
  
  return (
    <Box sx={{ height: 450, width: '100%' }}>
      <DataGrid
        density='compact'
        rows={rows}
        columns={columns}
        rowCount={count}
        loading={loading}
        getRowId={(r)=>r._id}
        onRowSelectionModelChange={onRowSelectedChanged}
        initialState={{
          columns: {
            columnVisibilityModel: {
              _id: false
            }
          },
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 15, 20]}
        onPaginationModelChange={onPaginationModelChange}
        checkboxSelection
        onSortModelChange={onSortModelChange}
        paginationMode="server"
        onFilterModelChange={onFilterModelChange}
        filterMode="server"
        slots={{
          toolbar: toolbar
        }}
      />
    </Box>
  );
}

export default TimeOffList;