import { FormTextField } from "../shared/form-components";
import { Box, Button, Divider, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Title from "../shared/Title";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';
import Confirmation from "../shared/Confirmation";
import { useMutation } from "@apollo/client";
import { CREATE_TAG } from "../graphql/mutations";
import { GET_TAGS } from "../graphql/queries";
import { useEffect, useRef, useState } from "react";
import { useTenantId } from "../tenant/hooks";
import { useSnackbar } from "notistack";

export default ({defaultValues,onBack, onAdded}) => {
    const { t } = useTranslation();
    const ref = useRef();
    const [tenantId] = useTenantId();
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(()=>{
        ref.current.focus();
    });

    const [ createTag ] = useMutation(CREATE_TAG, {
        refetchQueries: [
            GET_TAGS
        ]
    })

    const schema = yup.object({
        name: yup.string().required(t('RequiredField', {Field: t('Name')})),
        description: yup.string().nullable(true),
        categories: yup.array(yup.string()).required()
    });

    const methods = useForm({
        resolver: yupResolver(schema),
        mode: 'all',
        values: defaultValues,
        defaultValues: defaultValues
    });

    const { watch, handleSubmit, control, setValue } = methods;

    const onSubmit = async data => {
        setOpenConfirmation(false);

        const response = await createTag({
            variables: {
                input: data,
                tenants:[tenantId]
            }
        });

        enqueueSnackbar(t("OperationDoneSuccessfully"), {variant: "success"});
        if (response.data.createTag){
            onAdded && onAdded(response.data.createTag);
        }
    }

    const handleClose = () => {
        setOpenConfirmation(false);
    }
    
    return (
        <>
           <Confirmation
                open={openConfirmation}
                onNo={handleClose}
                onClose={handleClose}
                onYes={handleSubmit(onSubmit)}
                title={t('Confirmation')}
                description={t('AreYouSureQuestion')}
            />
            <Stack>
                <Box padding={2}>
                    <Title sx={{marginBottom: 0}}>{t("CreateTag")}</Title>
                </Box>
                <Divider />
                <Box padding={2}>
                    <Stack spacing={2}>
                        <FormTextField 
                            ref={ref}
                            fullWidth
                            label={t('Name')}
                            name="name"
                            control={control}
                        />
                        <FormTextField
                            fullWidth
                            rows={3}
                            multiline={true} 
                            label={t('Description')}
                            name="description"
                            control={control}
                        />
                        <Divider />
                        <Stack direction='row' spacing={2}>
                            <Button
                            fullWidth
                            variant='contained'
                            color="grey"
                            onClick={onBack}>
                                {t('Back')}
                            </Button>
                            <Button
                                fullWidth
                                variant='contained'
                                color='primary'
                                onClick={handleSubmit(onSubmit)}
                            >
                                {t('Save')}
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
        </>
    );
}
