import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next'
import { IconButton, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import * as moment from 'moment';
import { formatMoney } from '../shared/format-helpers';
import { brown, common, green, grey, orange, red, yellow } from '@mui/material/colors';

const CommonColumns = ({data}) => {
    return (
        <>
            <TableCell align='right'>
                <Typography color={orange[500]} fontWeight="bold">
                    {formatMoney(data.total)}
                </Typography>
            </TableCell>
            <TableCell align='right'>
                <Typography color={red[500]} fontWeight="bold">
                    {formatMoney(data.commissionAmount??0)}
                </Typography>
            </TableCell>
            <TableCell align='right'> 
                <Typography color={green[500]} fontWeight="bold">
                    {formatMoney(data.total - (data.commissionAmount??0))}
                </Typography>
            </TableCell>
        </>
    )
}

const InvoiceRow = ({invoice}) => {
    return (
    <TableRow sx={{backgroundColor: grey[100]}}>
        <TableCell colSpan={5}></TableCell>
        <TableCell sx={{maxWidth:40, overflow: 'clip'}}>{invoice.key._id}</TableCell>
        <CommonColumns data={invoice.summary} />
    </TableRow>);
}

const ServiceRow = ({service, parentKey, isOpen, switchOpen}) => {
    const key = `${parentKey}-service-${service.key._id}`;
    return (
        <>
            <TableRow sx={{backgroundColor: yellow[100]}}>
                <TableCell colSpan={3}></TableCell>
                <TableCell>
                    <IconButton size='small' onClick={switchOpen(key)}>
                        { isOpen(key) ? <ArrowDropDownIcon /> : <ArrowRightIcon /> }
                    </IconButton>
                </TableCell>
                <TableCell>{service.key.name}</TableCell>
                <TableCell align='center'>{service.invoices}</TableCell>
                <CommonColumns data={service.summary} />
            </TableRow>
            { isOpen(key) && 
            service.rows.map(invoice => (
                <InvoiceRow 

                    key={`${key}-invoice-${invoice.key._id}`} 
                    invoice={invoice} />
            ))}
        </>
    )
}

const SpecialistRow = ({specialist, parentKey, isOpen, switchOpen}) => {
    const key = `${parentKey}-specialist-${specialist.key._id}`;
    return (
        <>    
            <TableRow sx={{backgroundColor: green[100]}}>
                <TableCell colSpan={2}></TableCell>
                <TableCell>
                    <IconButton size='small' onClick={switchOpen(key)}>
                        { isOpen(key)  ? <ArrowDropDownIcon /> : <ArrowRightIcon /> }
                    </IconButton>
                </TableCell>
                <TableCell>{specialist.key.name}</TableCell>
                <TableCell align='center'>{specialist.services}</TableCell>
                <TableCell align='center'>{specialist.invoices}</TableCell>
                <CommonColumns data={specialist.summary} />
            </TableRow>
            { 
                isOpen(key) && 
                specialist.rows.map(service => 
                    <ServiceRow 
                        key={`${key}-service-${service.key._id}`}
                        parentKey={key}
                        service={service}
                        isOpen={isOpen}
                        switchOpen={switchOpen} />)
            }
        </>
    );
}

const SpecialtyRow = ({specialty, isOpen, switchOpen, parentKey}) => {
    const key = `${parentKey}-specialty-${specialty.key._id}`;
    return (
        <>
            <TableRow sx={{backgroundColor: orange[100]}}>
                <TableCell></TableCell>
                <TableCell>
                    <IconButton size='small' onClick={switchOpen(key)}>
                        { isOpen(key) ? <ArrowDropDownIcon /> : <ArrowRightIcon /> }
                    </IconButton>
                </TableCell>
                <TableCell>{specialty.key.name}</TableCell>
                <TableCell align='center'>{specialty.specialists}</TableCell>
                <TableCell align='center'>{specialty.services}</TableCell>
                <TableCell align='center'>{specialty.invoices}</TableCell>
                <CommonColumns data={specialty.summary} />
            </TableRow>
            { isOpen(key) && 
            specialty.rows.map(specialist=> (
                <SpecialistRow 
                    key={`${key}-specialist-${specialist.key._id}`}
                    specialist={specialist}  
                    isOpen={isOpen}
                    switchOpen={switchOpen}
                    parentKey={key}
                />
            ))}
        </>
    );
}

const DateRow = ({date, switchOpen, isOpen}) => {
    const dateFormatted = moment(date.key).format('DD-MM-yyyy');
                        
    return (
        <TableBody>
            <TableRow>
                <TableCell>
                    <IconButton size='small' onClick={switchOpen(dateFormatted)}>
                        { isOpen(dateFormatted) ? <ArrowDropDownIcon /> : <ArrowRightIcon /> }
                    </IconButton>
                </TableCell>
                <TableCell>{dateFormatted}</TableCell>
                <TableCell align='center'>{date.specialties}</TableCell>
                <TableCell align='center'>{date.specialists}</TableCell>
                <TableCell align='center'>{date.services}</TableCell>
                <TableCell align='center'>{date.invoices}</TableCell>
                <CommonColumns data={date.summary} />
            </TableRow>
                { isOpen(dateFormatted) && 
                date.rows.map(specialty=> (
                    <SpecialtyRow 
                        key={`${dateFormatted}-service-${specialty.key._id}`}
                        specialty={specialty}
                        isOpen={isOpen}
                        switchOpen={switchOpen}
                        parentKey={dateFormatted}
                    />
                ))}
        </TableBody>);
};


export default ({data}) => {
    const { t } = useTranslation();
    const [collapsed, setCollapsed] = useState({});
    
    const switchOpen = (key) => () => {
        setCollapsed({...collapsed, [key]: !collapsed[key]})
    }

    const isOpen = (key) => {
        return collapsed[key];
    }

    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell sx={{minWidth:80}}>{t('Date')}</TableCell>
                        <TableCell align='center' sx={{minWidth:150}}>{t('Specialty')}</TableCell>
                        <TableCell align='center' sx={{minWidth:100}}>{t('Specialist')}</TableCell>
                        <TableCell align='center' sx={{minWidth:100}}>{t('Service')}</TableCell>
                        <TableCell align='center' sx={{maxWidth:40}}>{t('Invoice')}</TableCell>
                        <TableCell align='right' sx={{minWidth:120}}>{t('TotalAmount')}</TableCell>
                        <TableCell align='right' sx={{minWidth:120}}>{t('CommissionAmount')}</TableCell>
                        <TableCell align='right' sx={{minWidth:120}}>{t('SpecialistAmount')}</TableCell>
                    </TableRow>
                </TableHead>
                { 
                    (data?.getGeneralReport?.rows?.length>0) ? 
                        data?.getGeneralReport?.rows?.map((date) => 
                            <DateRow 
                                key={date.key}
                                date={date} 
                                switchOpen={switchOpen} 
                                isOpen={isOpen} />
                        ) : (<TableBody key="no-data">
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell colSpan={12}>
                                    {t('ThereIsNotData')}
                                </TableCell>
                            </TableRow>
                        </TableBody>)
                }
                { data?.getGeneralReport?.rows?.length>0 && (
                    <TableFooter sx={{backgroundColor: grey[800]}}>
                        <TableRow>
                            <TableCell ></TableCell>
                            <TableCell sx={{color: common.white, fontWeight:'bold'}} align='center'>{data.getGeneralReport.dates}</TableCell>
                            <TableCell sx={{color: common.white, fontWeight:'bold'}} align='center'>{data.getGeneralReport.specialties}</TableCell>
                            <TableCell sx={{color: common.white, fontWeight:'bold'}} align='center'>{data.getGeneralReport.specialists}</TableCell>
                            <TableCell sx={{color: common.white, fontWeight:'bold'}} align='center'>{data.getGeneralReport.services}</TableCell>
                            <TableCell sx={{color: common.white, fontWeight:'bold'}} align='center'>{data.getGeneralReport.invoices}</TableCell>
                            <CommonColumns data={data.getGeneralReport.summary} />
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        </TableContainer>
    )
}