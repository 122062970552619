import { Box, Button, Divider, Paper, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { useForm } from "react-hook-form";
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next'
import Title from '../shared/Title';
import { FormSelect, FormTextField } from '../shared/form-components';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@apollo/client';
import { FIND_MEDICAL_TEST_BY_ID, GET_MEDICAL_TESTS } from '../graphql/queries';
import { CREATE_MEDICAL_TEST, UPDATE_MEDICAL_TEST } from '../graphql/mutations';
import { useSnackbar } from 'notistack';
import { useTenantId } from '../tenant/hooks';

const colorOptions = [
    'default',
    'primary',
    'warning',
    'error'
]

export default ({medicalTestId, onCancel, onAdded}) => {
    const isNew = !medicalTestId;

    const [tenantId] = useTenantId();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [ createMedicalTest] = useMutation(isNew ? CREATE_MEDICAL_TEST : UPDATE_MEDICAL_TEST,{
        refetchQueries: [
            GET_MEDICAL_TESTS,
            FIND_MEDICAL_TEST_BY_ID
        ]
    })
 
    const {data, loading} = useQuery(FIND_MEDICAL_TEST_BY_ID, {
        variables: {
            medicalTestId: medicalTestId
        },
        skip: isNew
    });

    const schema = yup.object({
        _id: yup.string(),
        name: yup.string().required(t('RequiredField', {Field: t('Name')})),
        metadata: yup.object({
            color: yup.string()
        })
    });

    const { setValue, control, handleSubmit, formState: { isSubmitting } } = useForm({
        resolver: yupResolver(schema),
        mode: 'all',
        defaultValues: {
            _id: medicalTestId
        }
    });

    const onSubmit = async data => {
        const response = await createMedicalTest({
            variables: {
                input: data,
                ...(isNew ? {tenants:[tenantId]}: {})
            }
        });
        onAdded && onAdded({
            ...data,
            ...response.data.createMedicalTest
        });

        enqueueSnackbar(t("OperationDoneSuccessfully"), {variant: "success"});
    }
    
    useEffect(()=>{
        if (!data?.findMedicalTest) {
            return;
        }

        setValue("name", data.findMedicalTest.name);
        setValue("metadata.color", data.findMedicalTest.metadata?.color);
    }, [data]);

    return (
        <Paper data-testid="medical-test-form">
            <Box p={2}>
                <Title sx={{marginBottom: 0}}>{t(isNew?"CreateMedicalTest":"EditMedicalTest")}</Title>
            </Box>
            <Divider />
            <Box p={2}>
                <Stack spacing={2}>
                    <FormTextField 
                        label={t('Name')}
                        name="name"
                        variant="outlined"
                        control={control}
                        fullWidth
                    />
                    <FormSelect
                        label={t("Color")}
                        name="metadata.color"
                        options={colorOptions}
                        getOptionLabel={(o)=>o}
                        getOptionValue={(o)=>o}
                        control={control}
                    />
                </Stack>
            </Box>
            <Divider />
            <Box p={2}>
                <Stack direction="row" justifyContent="space-between">
                    <Button data-testid="medical-test-form-cancel-btn" variant='contained' color="grey" onClick={onCancel} disabled={isSubmitting}>{t('Cancel')}</Button>
                    <LoadingButton data-testid="medical-test-form-submit-btn" variant='contained' color='primary' onClick={handleSubmit(onSubmit)} loading={isSubmitting}>{t('Submit')}</LoadingButton>
                </Stack>
            </Box>
        </Paper>
    )
}