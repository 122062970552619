import { Box, LinearProgress } from "@mui/material"

export default ({loading, appointments}) => {
    const loaded = !loading && appointments;
    const count = loaded? appointments.filter(a=>a?.status!='CANCELLED').length : 0;
    const working = loaded ? appointments.filter(a=>a?.turn?.status=='WORKING').length : 0
    const completed = loaded ? appointments.filter(a=>a?.status=='COMPLETE').length : 0

    const getPercent = (value) => {
        return count>0 ? (value/count)*100 : 0
    }
    return (
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box sx={{width: '100%', mr: 1}}>
                <LinearProgress 
                variant={loaded ? (working>0 ? "buffer" : "determinate") : "indeterminate" }
                valueBuffer={getPercent(completed+working)}
                value={getPercent(completed)}/>
            </Box>
            <Box sx={{minWidth: 35}}>
                {loaded? `${completed}/${count}` : 'Loading'}
            </Box>
        </Box>
    )
}