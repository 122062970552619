import React from 'react';
import Box from "@mui/material/Box";
import { Button, Paper, Slide, Typography } from '@mui/material';
import MainBox from '../MainBox';

export default () => {
    const handleRetry = () => {
        window.location.reload()
    }
    return (
        <MainBox alignItems="center" justifyContent="center">
            <Slide direction='right' in={true} mountOnEnter timeout={1000}>
                <Paper sx={{width:'300px'}} elevation={3}>
                    <Box textAlign='center' mb={5} mt={5}>
                        <Typography variant='subtitle1'>
                            Network Issues.
                        </Typography>
                        <Typography variant='caption'>
                            Ups! page cannot be loaded.
                        </Typography>
                        <Box>
                            <Typography variant='caption'>
                                Please
                            </Typography>
                            <Button variant='text' color='info' onClick={handleRetry} >Retry</Button>
                        </Box>
                    </Box>
                </Paper>
            </Slide>
        </MainBox>);
}