import { useTranslation } from "react-i18next";
import DefaultContainer from "../shared/DefaultContainer";
import Title from "../shared/Title";
import WorkProgress from "./WorkProgress";
import WorkingControllers from "./WorkingControllers";
import { useWorkingSpecialistIds } from "./hooks";
import * as moment from 'moment';
import { useQuery } from "@apollo/client";
import { 
    GET_QUEUED_APPOINTMENTS,
} from '../graphql/queries';
import WorkingPatient from "./WorkingPatient";
import { Box } from "@mui/system";
import WorkingQueueTable from "./WorkingQueueTable";
import { useState } from "react";
import { useTenantId } from "../tenant/hooks";

export default () => {
    const { t } = useTranslation();
    const [tenantId] = useTenantId();
    const specialistIds = useWorkingSpecialistIds();
    const date = moment(new Date).utc(true).toISOString().split("T")[0];
    const [isCurrentWorking, setCurrentWorking] = useState(true);
    const {data, loading, refetch} = useQuery(GET_QUEUED_APPOINTMENTS,{
        variables: {
            specialistIds: specialistIds,
            tenants: [tenantId]
        },
        fetchPolicy: 'network-only'
    });

    const working = data?.getQueuedAppointments?.filter(a=>a.status=="WORKING")[0];
    const nonWorking = data?.getQueuedAppointments?.filter(a=>a.status!="WORKING");

    const handleComplete = () => {
        refetch();
    }

    return (
        <>
            <DefaultContainer plain>
                <Title>{t('Workspace')}</Title>
            </DefaultContainer>
            <DefaultContainer>
                <WorkProgress 
                    date={date}
                    appointments={data?.getQueuedAppointments}
                />

            </DefaultContainer>
            {working?.patient ? <Box p={2}> <WorkingPatient onCurrentWorkingChanged={setCurrentWorking} patient={working.patient} /> </Box> :
            <DefaultContainer plain>
                <WorkingQueueTable appointments={nonWorking || []} /> 
            </DefaultContainer>}
        
            {specialistIds?.length>0? (
                <DefaultContainer>
                    <WorkingControllers 
                        date={date}
                        isCurrentWorking={isCurrentWorking}
                        specialistId={specialistIds[0]}
                        appointments={data?.getQueuedAppointments}
                        onRefetch={handleComplete} />
                </DefaultContainer>)
                : undefined}
        </>
    );
}