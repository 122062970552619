import { Grid, Paper, Typography } from "@mui/material";
import { green, red } from "@mui/material/colors";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { formatMoney } from "../shared/format-helpers";
import { getServiceText } from "../shared/label-helpers";
import StatusChip from "../shared/StatusChip";

const buildColumns = (t) => {
    return [
    { 
        field: 'status', 
        headerName: t('Status'), 
        width: 200 ,
        renderCell: ({value, row}) => <>
            <StatusChip status={value} /> 
        </>,
        align: 'center'
    },
    { field: 'time', headerName: t('Time'), width: 90 },
    { field: 'code', headerName: t('Code'), width: 200, },
    { field: 'turn', headerName: t('Turn'), width: 150,
        valueGetter: ({value}) => value?.code
    },
    { 
      field: 'service', 
      headerName: t('Service'), 
      width: 180,
      valueGetter: ({value}) => getServiceText(value)
    },
    { 
        field: 'totalAmount',
        align: 'right',
        headerName: t('TotalAmount'),
        width: 130,
        valueGetter: ({value}) => formatMoney(value??0),
        renderCell: ({value}) => <Typography color={green[500]}>{value}</Typography>
    },
    { 
        field: 'commissionAmount',
        align: 'right',
        headerName: t('CommissionAmount'),
        valueGetter: ({row}) => formatMoney(row.invoice?.commissionAmount??0),
        width: 150,     
        renderCell: ({value}) => <Typography color={red[500]}>{value}</Typography>
    },
    { 
        field: 'specialistAmount',
        headerName: t('SpecialistAmount'), 
        align: 'right',
        type: 'number',
        valueGetter: ({row}) => formatMoney((row.invoice?.totalAmount??0) - (row.invoice?.commissionAmount??0)),
        width: 130,
        renderCell: ({value}) => <Typography color={green[500]} fontWeight='bold'>{value}</Typography>
    }
  ];
}
  

export default ({appointments}) => {
    const {t} = useTranslation();
    const completeApp = appointments.filter(x=>x.status === "COMPLETE");
    const commissionAmmount = completeApp.reduce((t,a)=>t+(a.invoice?.commissionAmount??0), 0);
    const specialistAmount = completeApp.reduce((t,a)=>t+(a.invoice?.totalAmount??0), 0) - commissionAmmount;

    const columns = buildColumns(t);
    return (
        <Grid container spacing={2}>
            <Grid display='none' item xs={6}>
                <Paper>
                    <Box p={2}>
                        <Typography variant="subtitle1">
                            {t('CommissionAmount')}
                        </Typography>
                        <Typography variant="h6" align="right" color={red[500]}>
                            {formatMoney(commissionAmmount)}
                        </Typography>
                    </Box>
                </Paper>
            </Grid>

            <Grid item xs={12}>
                <Paper>
                    <Box p={2}>
                        <Typography variant="subtitle1">
                            {t('SpecialistAmount')}
                        </Typography>
                        <Typography variant="h6" align="right" color={green[500]}>
                            {formatMoney(specialistAmount)}
                        </Typography>

                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper>
                    <DataGrid
                        density='compact'
                        getRowId={(r)=>r._id}
                        rows={appointments}
                        columns={columns}
                        hideFooterPagination={true}
                        hideFooter={true}
                    />
                </Paper>

            </Grid>
        </Grid>)
}