import { Controller, useController } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LinearProgress from "@mui/material/LinearProgress";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import moment from "moment";
import { useState, useEffect, forwardRef } from "react";
import { useDebounce } from './hooks'
import { 
    FormHelperText, 
    FormLabel, 
    FormControlLabel, 
    Switch, 
    Typography, 
    Select, 
    MenuItem, 
    FormControl,
    InputLabel,
    Slider,
    RadioGroup,
    Radio,
} from '@mui/material';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { useTranslation } from "react-i18next";

export const FormTextField = forwardRef((props, ref) => {
  const { name, control, } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value, onBlur },
        fieldState: { error },
        formState: { isLoading },
      }) => (
        <TextField
          size="small"
          inputRef={ref}
          helperText={error ? error.message : null}
          error={!!error}
          disabled={isLoading}
          onChange={onChange}
          value={value||''}
          onBlur={onBlur}
          {...props}
        />
      )}
    />
  );
});


export const FormSelect = (props) => {
    const { 
        name, 
        control, 
        options,
        label,
        getOptionLabel,
        transformValue,
        fullWidth,
        loading,
        getOptionValue = (opt)=>opt._id,
        isOptionDisabled = (opt) => false,
        ...otherOpts
     } = props;
    const {
        field: { onChange, onBlur, value },
        fieldState: { error },
        formState: { isSubmitting }
    } = useController({
        name: name,
        control: control
    });

    const handleChange = (opt)=>{
        transformValue ? onChange(transformValue(value, opt.target.value)) : onChange(opt);
    }
 
    const notExistValue = !!(value && (!options || !options.some(o=> getOptionValue(o)===value)));

    return (
        <FormControl fullWidth={fullWidth} error={!!error}>
            <InputLabel size="small">{label}</InputLabel>
            <Select
                size="small"
                label={label}
                name={name}
                disabled={isSubmitting}
                onChange={handleChange}
                value={value||''}
                onBlur={onBlur}
                {...otherOpts}
            >
                {options && options.map((o,index)=>{
                    return (
                        <MenuItem 
                            key={index}
                            value={getOptionValue(o)}
                            disabled={isOptionDisabled(o)}>
                            {getOptionLabel(o)}
                        </MenuItem>
                    );
                })}
                {notExistValue && ( 
                    <MenuItem 
                        key="CUSTOM"
                        value={getOptionValue(value)}>
                        {getOptionValue(value)}
                    </MenuItem>
                )}
            </Select>
            {loading && <LinearProgress />}
            {!!error && <FormHelperText>{error ? error.message : null}</FormHelperText>}
        </FormControl>
    );
  };
  

export const FormDatePicker = (props) => {
    const { name, control, disabled } = props;
    
    const {
        field: { onChange, value, onBlur },
        fieldState: { error },
        formState: { isSubmitting },
    } = useController({
        name: name,
        control: control
    });

    const handleChange = (data) => {
        onChange(moment(data).startOf('day').toDate());
    }

    return (
        <DatePicker
            {...props}
            onChange={handleChange}
            value={moment(value??null)}
            disabled={isSubmitting || disabled}
            slotProps={{
                textField: {
                    name: name,
                    size: "small",
                    fullWidth: true,
                    helperText: (error ? error.message : null),
                    error: (!!error),
                    onBlur:(onBlur)
                }
            }}
        />
    );
};



export const FormTimePicker = (props) => {
    const { name, control, ...otherProps } = props;
    
    const {
        field: { onChange, value, onBlur },
        fieldState: { error },
        formState: { isSubmitting },
    } = useController({
        name: name,
        control: control
    });

    const handleChange = (data) => {
        onChange(moment(data).format('LT'));
    }

    return (
        <TimePicker
            {...otherProps}
            onChange={handleChange}
            value={moment(value, 'hh:mm a')}
            disabled={isSubmitting}
            viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
                seconds: renderTimeViewClock,
            }}
            slotProps={{
                textField: {
                    size: "small",
                    ...otherProps,
                    helperText: (error ? error.message : null),
                    error: (!!error),
                    onBlur:(onBlur)
                }
            }}
        />
    );
};

export const FormDateTimePicker = (props) => {
    const { name } = props;
    const { control } = useController({
        name: name
    });

    const {
        field: { onChange, value, onBlur },
        fieldState: { error },
        formState: { isSubmitting },
      } = useController({
        name: name,
        control: control
      });

    return (
        <DateTimePicker
            {...props}
            onChange={onChange}
            value={value || moment()}
            disabled={isSubmitting}
            slotProps={{
                textField: {
                    size: "small",
                    helperText: (error ? error.message : null),
                    error: (!!error),
                    onBlur:(onBlur)
                }
            }}
        />
    );
};

export const FormAutocomplete = (props) => {
    const { 
        loading,
        name, 
        control,
        onSearchTextChanged, onValueChanged, 
        options,
        selected, label, getOptionLabel, 
        getOptionValue = (val)=>val?._id ?? val,
        getKey = (k) => getOptionValue(k),
        compareOptions= (left,right) =>getKey(left) === getKey(right), 
        delay = 500, ...objProps } = props;
    const [searchText, setSearchText] = useState(undefined);
    const debouncedValue = useDebounce(searchText, delay);

    useEffect(()=>{
        onSearchTextChanged && onSearchTextChanged(debouncedValue);
    }, [debouncedValue]);

    const handleInputChanged = (_, text) => {
        setSearchText(text||'');
    }

    const {
        field: { onChange, onBlur, value },
        fieldState: { error },
        formState: { isSubmitting }
    } = useController({
        name: name,
        control: control
    });

    const selectValue = (nValue) => {
        if ((!nValue && !value) || (nValue && getOptionValue(nValue) === value) || ( nValue===value )){
            return;
        }

        if (!nValue){
            onChange(undefined);
            onValueChanged && onValueChanged(undefined);
            return;
        }
        onValueChanged && onValueChanged(nValue);
        onChange(getOptionValue(nValue));
    }

    const handleSelect = (event, value) => {
        selectValue(value);
    };

    const finalOptions = [...(options ?? []), selected].filter(c=>c);

    useEffect(()=>{     
        if (!selected) {
            return;
        }

        selectValue(selected);
        setSearchText(getOptionLabel(selected));

    }, [selected]);

    useEffect(() => {
        if (!options?.length) {
            return;
        }

        if (!value) {
            return;
        }

        const option = finalOptions.find(p => compareOptions(p, value));
        selectValue(option);
        setSearchText(getOptionLabel(option));
    }, [options]);

    useEffect(() => {
        if (!options?.length) {
            return;
        }

        if (!value) {
            return;
        }

        const option = finalOptions.find(p => compareOptions(p, value));
        setSearchText(getOptionLabel(option));
    }, [options, value]);

    return (
        <>
            <Autocomplete
                renderInput={(params) => 
                <TextField
                    helperText={error ? error.message : null}
                    error={!!error}
                    disabled={isSubmitting}
                    onBlur={onBlur}
                    label={label}
                    {...params}  
                    size="small"
                    {...objProps}
                />}
                value={searchText||''}
                onInputChange={handleInputChanged}
                onChange={handleSelect}
                getOptionLabel={getOptionLabel}
                options={options||[]}
                {...objProps}
            />
            {loading && <LinearProgress />}
        </>);

};

export const FormSwitchs = (props) => {
    const {t} = useTranslation();

    const { 
        name, control, label, 
        disabled,
        noDataMessage, options, 
        getOptionValue = opt=>opt?._id??opt, 
        getOptionKey = opt=>opt?._id??opt, 
        compareOptions= (left,right) => getOptionValue(left) === getOptionValue(right), 
        getOptionLabel = opt=>opt.name
    } = props;

    const {
        field: { onChange, value },
        fieldState: { error },
        formState: { isSubmitting }
    } = useController({
        name: name,
        control: control
    });

    const handleChange = (nValue) => (event) => {
        if (disabled){
            return;
        }
        if (!value) {
            onChange([getOptionValue(nValue)]);
        } else {
            if (value.some((left) => compareOptions(left, nValue))) {
                onChange(value.filter(left => !compareOptions(left, nValue)));
            } else {
                onChange([...value, getOptionValue(nValue)]);
            }
        }
    }

    return (<>
            {label && <FormLabel error={!!error}>{label}</FormLabel>}
            {
                options ? 
                options.map((opt, index) => 
                    <FormControlLabel 
                        error={error ? error.message : null}
                        key={getOptionKey(opt)} 
                        label={getOptionLabel(opt)} 
                        onClick={handleChange(opt)}
                        required={opt.required}
                        control={
                            <Switch 
                                size="small"
                                color={opt.required ? 'warning' : 'primary'}
                                checked={ (value && value.some((left) => compareOptions(left, opt))) ?? false } 
                                disabled={isSubmitting || disabled} 
                                value={opt} />}  />
                ):
                <Typography>{noDataMessage}</Typography>
            }
            {!!error ? <FormHelperText error>{error ? t(error.message) : null}</FormHelperText> : undefined}
        </>);

};

export const FormSwitch = (props) => {
    const { 
        name, control, label, 
        disabled,
        ...objProps
    } = props;

    const {
        field: { onChange, value },
        fieldState: { error },
        formState: { isSubmitting }
    } = useController({
        name: name,
        control: control
    });

    return (<>
            <FormControlLabel 
                error={error ? error.message : null}
                label={label} 
                onClick={onChange}
                control={
                    <Switch
                        size="small"
                        {...objProps}
                        checked={ value } 
                        disabled={isSubmitting || disabled} 
                    />}  
            />
        </>);
};


export const FormSlider = (props) => {
    const { 
        name, control, label, fullWidth, marks
    } = props;

    const {
        field: { onChange, value },
        fieldState: { error },
        formState: { isSubmitting }
    } = useController({
        name: name,
        control: control
    });

    return (
        <FormControl fullWidth={fullWidth} error={!!error}>
            <Typography gutterBottom>{label}</Typography>
            <Slider 
                size="small"
                value={typeof value === 'number' ? value : 0}
                onChange={ onChange } 
                disabled={isSubmitting} 
                marks={marks}
                step={1}
                max={100}
                min={0}
                valueLabelDisplay='on'
            />
            {!!error ? <FormHelperText error>{error ? error.message : null}</FormHelperText> : undefined}
        </FormControl>
        );
};

export const FormSelectMultiple = forwardRef((props, ref) => {
    const { 
        name, control, onValueChanged, label, placeholder,
        noDataMessage, options, 
        fullWidth,
        variant,
        selected,
        getOptionValue = opt=>opt,
        getKey = opt=> (opt?._id || opt),
        compareOptions= (left,right) =>getKey(left) === getKey(right), 
        onKeyUp,
        onKeyDown,
         ...objProps 
    } = props;

    const {
        field: { onChange, onBlur, value },
        fieldState: { error },
        formState: { isSubmitting }
    } = useController({
        name: name,
        control: control
    });


    useEffect(()=>{
        if (!selected) {
            return;
        }
        onChange(selected);
    }, [selected, onChange]);

    const handleChange = (a,values) => {
        const newValues = values.map(v=>getOptionValue(v));
        onChange(newValues);
    }

    const chipValues = options?.filter(c => value?.some(v => compareOptions(c, v))) || []

    return (
        <FormControl fullWidth={fullWidth}>
            <Autocomplete
                multiple
                onChange={handleChange}
                value={chipValues}
                options={options||[]}
                filterSelectedOptions
                renderInput={(params) => (
                <TextField
                    {...params}
                    ref={ref}
                    size="small"
                    helperText={error ? error.message : null}
                    error={!!error}
                    disabled={isSubmitting}
                    onBlur={onBlur}
                    label={label}
                    variant={variant}
                    onKeyUp={onKeyUp}
                    placeholder={placeholder}
                />
                )}
                {...objProps}
            />
        </FormControl>
    );
});

export const FormRadios = (props) => {
    const {t} = useTranslation();

    const { 
        name, control, onValueChanged, label, placeholder, defaultValue,
        noDataMessage, 
        options,
        row,
        selected,
        loading,
        getOptionValue = opt=>opt,
        getKey = opt=> (opt?._id || opt),
        compareOptions= (left,right) =>getKey(left) === getKey(right), 
         ...objProps } = props;

    const {
        field: { onChange, onBlur, value },
        fieldState: { error },
        formState: { isSubmitting }
    } = useController({
        name: name,
        control: control
    });

    return (
        <FormControl>
             {label && <FormLabel error={!!error}>{label}</FormLabel>}
            <RadioGroup
                defaultValue={defaultValue}
                value={value}
                onChange={ onChange } 
                disabled={isSubmitting} 
                row={row}
            >
                {options.map(p=>{

                    return (
                        <FormControlLabel key={p.key || p.value} value={p.value} control={<Radio />} label={t(p.label)} />
                    )
                })}
            </RadioGroup>
            {loading && <LinearProgress />}
            {!!error ? <FormHelperText error>{error ? t(error.message) : null}</FormHelperText> : undefined}
        </FormControl>
    );
}