import { useEffect, useState } from "react";
import {Container, Box, Fab, Paper, Tooltip, Breadcrumbs, Typography, Fade} from "@mui/material"
import DefaultContainer from "../shared/DefaultContainer"
import ModalContainer from "../shared/ModalContainer"
import Title from "../shared/Title"
import AppointmentList from "./AppointmentList"
import AddIcon from '@mui/icons-material/Add';
import AppointmentForm from "./AppointmentForm"
import Modal from '@mui/material/Modal';
import { useMutation, useQuery } from '@apollo/client';
import {FIND_APPOINTMENT_BY_ID, GET_PAGINATED_APPOINTMENTS, GET_TAGS} from '../graphql/queries';
import {DELETE_APPOINTMENTS} from '../graphql/mutations';
import Confirmation from "../shared/Confirmation";
import CustomToolbar from "../shared/CustomToolbar";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import moment from "moment";
import { HasAccessTo, useAccessConstraints } from "../auth/hooks";
import { Link, useParams } from "react-router-dom";
import {Dashboard, DownloadForOffline} from "@mui/icons-material";
import { useGridApiRef } from "@mui/x-data-grid";
import { useTenantId } from "../tenant/hooks";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";

const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
};

const AppointmentModal = ({open, onClose, onAdded, appointmentId}) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            closeAfterTransition
        >
            <Fade in={open}>
                <ModalContainer width={550}>
                    <AppointmentForm
                        appointmentId={appointmentId}
                        onCancel={onClose}
                        onAdded={onAdded}/>
                </ModalContainer>
            </Fade>
        </Modal>
    )
}

const AppointmentMaintenance = () => {
    const apiRef = useGridApiRef();

    const { patientId } = useParams();
   
    const [tenantId] = useTenantId();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [open,setOpen] = useState(false);

    const [openDeleteConfirmation,setOpenDeleteConfirmation] = useState(false);

    const [selecteds,setSelecteds] = useState([]);

    const specialistIds = useAccessConstraints({
        entity: "Appointment",
        code: "VIEW",
        constraint: "Specialist"
    });

    const {data, loading, refetch} = useQuery(GET_PAGINATED_APPOINTMENTS, {
        variables: {
            skip:0,
            limit: 10,
            tenants:[tenantId],
            filter: {
                specialistIds: specialistIds,
                patientIds: patientId ? [patientId]: undefined
            }
        }
    });

    const {refetch: refetchById} = useQuery(FIND_APPOINTMENT_BY_ID, {
        skip: true
    });

    const [sort, setSort] = useState(undefined);
    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(10);
    const [filter, setFilter] = useState({
        specialistIds: specialistIds, 
        patientIds: patientId ? [patientId]: undefined
    });
    
    const [deleteAppointments, {loading:ldAppointments}] = useMutation(DELETE_APPOINTMENTS, {
        refetchQueries: [
            GET_PAGINATED_APPOINTMENTS
        ],
        onCompleted: ()=>{
            enqueueSnackbar(t("OperationDoneSuccessfully"), {variant: "success"});
        }
    });
    const [editAppointmentId,setEditAppointmentId] = useState(undefined);

    useEffect(()=>{
        refetch({
            sort: sort,
            skip: skip,
            limit: limit,
            filter: filter,
            tenants:[tenantId],
        });
    }, [sort, skip, limit, filter, tenantId, refetch]);
    
    const switchOpen = () => {
        setOpen(!open);
        if (!!open) {
            setEditAppointmentId(undefined);
        }
    }

    const handleRowSelectedChanged = (value) => {
        setSelecteds(value);
    }

    const switchDeleteConfirmation = () => {
        setOpenDeleteConfirmation(!openDeleteConfirmation);
    }

    const handleDeleteAppointments = async () => {
        switchDeleteConfirmation();
        await deleteAppointments({
            variables: {
                appointmentIds: selecteds
            }
        });
    }

    const handleEditAppointment = () => {
        setEditAppointmentId(selecteds[0]);
        switchOpen();
    }

    const handlePaginationModelChanged = (e) => {
        setSkip(e.page*e.pageSize);
        setLimit(e.pageSize);
    }

    const handleSortModelChanged = (sorts) => {
        const sort = sorts.reduce((p,c)=>{
            p[c.field] = c.sort === 'asc' ? 'Asc' : 'Desc';
            return p;
        }, {});
        setSort(sort);
    }

    const handleFilterModelChanged = (e) => {
        const nFilter =  e.items.reduce((p, c) => {
            if (c.operator==='is' && c.value && c.field=='date') {
                p[c.field+'Start'] = moment(c.value).startOf('day').utc().toDate()
                p[c.field+'End'] = moment(c.value).endOf('day').utc().toDate()
            } else if (c.field=='tags') {
                if (c.value) {
                    p[c.field] = [c.value];
                }
            } else {
                p[c.field] = c.value;
            }
            return p;
        }, {});
        setFilter({
            ...nFilter,
            specialistIds: specialistIds,
            patientIds: patientId ? [patientId]: undefined
        });
    }

    const handleAppointmentAdded = async (appointment) => {
        refetch();
        switchOpen();
        await refetchById({appointmentId: appointment._id});
    }

    const actionEnabled = !patientId;
    
    const downloadCsvReport = async () => {
        await refetch({
            sort: sort,
            skip: null,
            limit: null,
            tenants:[tenantId],
            filter: filter
        });

        apiRef.current.exportDataAsCsv({
            allColumns: false
        });

        await refetch({
            sort: sort,
            skip: skip,
            limit: limit,
            tenants:[tenantId],
            filter: filter
        });
    }

    return (
        <>
            <Confirmation 
                open={openDeleteConfirmation}
                title={t("Confirmation")}
                description={t("AreYouSureToDeleteTheseAppointmentsQuestion")}
                onClose={switchDeleteConfirmation}
                onNo={switchDeleteConfirmation}
                onYes={handleDeleteAppointments}
            />
            <AppointmentModal 
                onClose={switchOpen}
                onAdded={handleAppointmentAdded}
                appointmentId={editAppointmentId}
                open={open}/>
            {!patientId && (
                <Container>
                    <Box pt={2}>
                        <Title>{t('Appointments')}</Title>
                    </Box>
                </Container>
            )}
            <DefaultContainer sx={{mt:0}} plain>
                {patientId && (
                    <Box pt={2} pb={2}>
                        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
                            <Link to={"/patients"}
                                color="inherit"
                                underline="hover"
                                style={{ color: 'inherit', textDecoration: 'inherit', display: 'flex', alignItems: 'center'}}
                            >
                                <MedicalInformationIcon color="primary" sx={{ mr: 0.5}} />
                                {t('Patients')}
                            </Link>
                            <Typography>{patientId}</Typography>
                            <Typography>{t('Appointments')}</Typography>
                        </Breadcrumbs>
                    </Box>
                )}
                <Paper >
                    <AppointmentList 
                        apiRef={apiRef}
                        loading={loading}
                        onSortModelChange={handleSortModelChanged}
                        onPaginationModelChange={handlePaginationModelChanged}
                        rows={data?.paginatedResults?.rows || []}
                        count={data?.paginatedResults?.count || 0}
                        onFilterModelChange={handleFilterModelChanged}
                        onRowSelectedChanged={handleRowSelectedChanged}
                        toolbar={()=><CustomToolbar 
                            entity='Appointment'
                            selecteds={selecteds} 
                            deleting={ldAppointments} 
                            onEdit={handleEditAppointment}
                            onDelete={switchDeleteConfirmation}
                        >
                            <Tooltip title={t("DownloadCSVReport")}>
                                <span>
                                    <Fab
                                        size="small"
                                        color="default"
                                        onClick={downloadCsvReport}
                                        disabled={selecteds.length>0}>
                                        <DownloadForOffline></DownloadForOffline>
                                    </Fab>
                                </span>
                            </Tooltip>
                        </CustomToolbar>}
                        />
                </Paper>
            </DefaultContainer>
                            
            {actionEnabled && 
                <HasAccessTo entity='Appointment' code='CREATE' >
                    <Fab 
                        onClick={switchOpen}
                        color="primary" 
                        sx={fabStyle}>
                        <AddIcon />
                    </Fab>
                </HasAccessTo>
            }
        </>
    )
}

export default AppointmentMaintenance;