import { Box, MenuItem, Select, Typography } from "@mui/material"
import { useUser } from "../auth/hooks";
import { useTenantId } from "./hooks";



export default ()=> {
    const [user] = useUser();
    const [tenantId, setTenantId] = useTenantId();

    const onSelectChange = (event) => {
        setTenantId(event.target.value);
    }

    if (user.tenants?.length === 1) {
        return <Box alignItems='center' justifyContent='center' display='flex' flexGrow={1}>
                <Typography> {user.tenants[0].name} </Typography>
            </Box>
    }

    return (
    <Select 
        value={tenantId}
        size="small" 
        variant="outlined" 
        onChange={onSelectChange}
        fullWidth 
    >
        {user.tenants?.map(t=>{
            return (
                <MenuItem
                    key={t._id}
                    value={t._id}>
                    {t.name}
                </MenuItem>
            )
        })}
    </Select>);
} 