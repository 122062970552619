import { 
    Button, 
    Grid, 
    List, 
    ListItem, 
    Stack, 
    Typography, 
    Paper,
    Badge,
    Collapse,
    Tooltip,
    Popover,
    Slider,
    Box,
    TextField
} from '@mui/material';
import React, {useState} from 'react'
import { useTranslation } from 'react-i18next'
import Chip from '@mui/material/Chip';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import SubTitle from '../shared/SubTitle';
import { getSpecialistText } from '../shared/label-helpers';
import { useMutation, useQuery } from '@apollo/client';
import { FIND_APPOINTMENT_BY_ID, GET_QUEUED_APPOINTMENTS } from '../graphql/queries';
import { QUEUE_APPOINTMENT, SET_TURN_PRIORITY } from '../graphql/mutations';
import * as moment from 'moment';
import AppointmentSummary from '../appointment/AppointmentSummary';
import { LoadingButton, Masonry } from '@mui/lab';
import { useSpecialists } from '../specialist/hooks';
import Modal from '@mui/material/Modal';
import {
    ExpandLess,
    ExpandMore
} from '@mui/icons-material';
import ModalContainer from "../shared/ModalContainer"
import AppointmentForm from "../appointment/AppointmentForm"
import { useSnackbar } from 'notistack';
import { useFromNow } from '../shared/TimeFromNow';
import TurnVoucher from './TurnVoucher';
import { useTenantId } from '../tenant/hooks';

const getAllSeconds = (date) => {
    if (!date) {
        return 24*60*60;
    }

    const m = moment(date);
    return (m.hour()*60*60) + (m.hour()*60) + m.second();
}

const compareTurns = (a, b) => {
    if (!a || !b) {
        return 0;
    }

    if (a.availableAt || b.availableAt) {
        const aAv = !a.availableAt || moment(a.availableAt).isBefore(new Date());
        const bAv = !b.availableAt || moment(b.availableAt).isBefore(new Date());
        
        if (aAv && !bAv) {
            return -1;
        } else if (!aAv && bAv) {
            return 1;
        } else if (!aAv && !bAv && a.availableAt && a.availableAt) {
            return getAllSeconds(a.availableAt) - getAllSeconds(b.availableAt);
        }
    }

    const priority = (a.priority ?? Number.MAX_VALUE) - (b.priority??Number.MAX_VALUE);
    const queuedAt = getAllSeconds(a.queuedAt) - getAllSeconds(b.queuedAt);
    
    return priority !== 0 ? priority : queuedAt;
}

const AppointmentTurnListItem = ({showAvailability, appointment, showStatus, handleClick, color}) => {
    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentPriority, setCurrentPriority] = useState(appointment.turn?.priority ?? 10);
    const fromNow = useFromNow(appointment.turn?.queuedAt);
    const editTurn = appointment.status == 'QUEUED';

    const [setTurnPriority, {loading}] = useMutation(SET_TURN_PRIORITY, {
        refetchQueries: [
            GET_QUEUED_APPOINTMENTS
        ]
    });

    const handleClickPriority = (event) => {
        if (editTurn){
            setAnchorEl(event.currentTarget);
        }
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handlePriorityChanged = (_, value) => {
        setCurrentPriority(value);
    }

    const handleApplyPriority = async () => {
        await setTurnPriority({
            variables: {
                turnId: appointment.turn._id,
                priority: currentPriority
            }
        });
        handleClose();
    }
  
    const open = Boolean(anchorEl);

    const available = (!appointment.turn?.availableAt || moment(appointment.turn?.availableAt).isBefore(new Date()))

    return (
    <Tooltip title={appointment.service.name}>
        <ListItem sx={{paddingBottom: 0, paddingTop: 1}}>
            <Stack direction="row" justifyContent="space-between" sx={{width:'100%'}}> 
                <Chip 
                    color={!showAvailability ? color : ((available) ? color : 'secondary')}
                    variant={!showAvailability ? 'outlined' : ((available)? 'filled': 'outlined')}
                    onClick={handleClick(appointment)} 
                    size="small"
                    label={
                        `${appointment.confirmed? t('Confirmed'): t('Unconfirmed')} | ${appointment.code}${appointment?.turn?.code ? ` | ${appointment?.turn?.code} ` : '' } ${appointment.time} ${showStatus? ' - ' + appointment.status : ''} ${fromNow && appointment.status==="WORKING" && appointment.turn?.status === "QUEUED" ? ', '+(fromNow) : ''}`.trim() 
                    } />
                {appointment.turn && appointment.status=='QUEUED' && (
                    <>
                        <Button 
                            size="small" 
                            color='info'
                            variant='contained'
                            disabled={loading}
                            onClick={handleClickPriority}>
                            {t('Priority')} {appointment.turn?.priority??'-'}
                        </Button>
                        
                        <Popover
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'right',
                            }}
                            slotProps={{
                                paper: {sx:{overflow: 'visible'}}
                            }}
                            >
                                <Box p={1}>
                                    <Stack spacing={2} sx={{minWidth: '400px'}} direction='row'>
                                        <Slider 
                                            min={1} 
                                            max={Math.max(100,(appointment.turn?.priority??0))} 
                                            step={1} 
                                            valueLabelDisplay='on' 
                                            value={currentPriority}
                                            onChange={handlePriorityChanged}
                                            sx={{zIndex:1001}}/>
                                        <Button   
                                        color='info'
                                        variant='contained'
                                        disabled={loading}
                                        onClick={handleApplyPriority}>
                                            {t('Apply')}
                                        </Button>
                                    </Stack>
                                </Box>
                            </Popover>
                        </>
                )}
            </Stack>
        </ListItem>
    </Tooltip>
    )
}

const AppointmentTurnList = ({showAvailability, appointments, showStatus, handleClick, color}) => {
    return appointments?.length>0 ? 
    appointments.map(a => <AppointmentTurnListItem key={a._id} showAvailability={showAvailability} appointment={a} showStatus={showStatus} handleClick={handleClick} color={color} />)
    : <Typography align='center'>N/A</Typography>
}

const AppointmentList = ({search, showAvailability, appointments, title, showStatus, onClick, color, Component }) => {
    const [open, setOpen] = React.useState(appointments?.length>0);
    const handleClick = (appointment) => () => {
        onClick && onClick(appointment)
    }

    const handleClickCollapse = () => {
      setOpen(!open);
    };

    const CurrentComponent = Component || AppointmentTurnList;
  
    if (search && appointments.every(a=>!a.code || a.code?.toLowerCase()?.indexOf(search?.toLowerCase())===-1)) {
        return undefined;
    }

    return (
        <List subheader={
                <ListItemButton onClick={handleClickCollapse}>
                    <ListItemText color={color} primary={title} />
                     <Badge
                            badgeContent={appointments.length} 
                            color={color || "secondary"} >      
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </Badge>
                </ListItemButton>
        }>
            <Collapse in={open || !!search} timeout="auto" unmountOnExit>
                <CurrentComponent handleClick={handleClick} showAvailability={showAvailability} color={color} appointments={appointments} showStatus={showStatus} />
            </Collapse>
        </List>
    )
}

const AppointmentStatus = {
    "Pending": 'warning',
    "Queued": 'warning',
    "Working": 'primary',
    "Incomplete": 'warning', 
    "Complete": 'success',
    "Cancelled": 'error',
}

const AppointmentModal = ({open, onClose, onAdded, appointmentId}) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <ModalContainer sx={{padding: 0}}>
                <AppointmentForm 
                    appointmentId={appointmentId}
                    onCancel={onClose}
                    onAdded={onAdded}/>
            </ModalContainer>
        </Modal>
    )
}

const SpecialistTurnList = ({specialist, search}) => {
    const { t } = useTranslation();
    const [tenantId] = useTenantId();
    const { enqueueSnackbar } = useSnackbar();

    const {data, loading, refetch} = useQuery(GET_QUEUED_APPOINTMENTS,{
        variables: {
            specialistIds: specialist? [specialist._id] : [],
            tenants: [tenantId]
        },
        fetchPolicy: 'network-only'
    });

    const [currentAppointment, setCurrentAppointment] = useState();
    const [open, setOpen] = useState(false);
    const [turnAppointmentId, setTurnAppointmentId] = useState(false);

    const switchOpen = () => {
        setOpen(!open);
        if (open) {
            setCurrentAppointment(undefined);
        }
    }

    const [ queueAppointment, { loading: queueLoading }] = useMutation(
        QUEUE_APPOINTMENT, {
            refetchQueries: [FIND_APPOINTMENT_BY_ID],
            onCompleted: ()=>{
                enqueueSnackbar(t("OperationDoneSuccessfully"), {variant: "success"});
            }
        });

    const getByStatus = (statuses) => {
        return data?.getQueuedAppointments?.filter(a => statuses.indexOf(a?.status)>=0) ?? [];
    }

    const getByOtherStatus = () => {
        return data?.getQueuedAppointments?.filter(a => Object.keys(AppointmentStatus).every(as => as.toUpperCase() !== a.status) && !(a?.turn?.status) ) ?? [];
    }

    const handleClose = () => {
        setCurrentAppointment(undefined);
    }

    const openTurnVoucher = () => {
        setTurnAppointmentId(currentAppointment?._id);
    }

    const handleQueue = async (appointmentId) => {
        await queueAppointment({
            variables:{
                appointmentId: appointmentId
            }
        });
        handleClose();
        refetch();
        openTurnVoucher(appointmentId);
    }

    const handleCloseTurnVoucher = () => {
        setTurnAppointmentId(false);
    }

    const noMatch = search && !!(data?.getQueuedAppointments?.every(c=>!c.code || c.code?.toLowerCase()?.indexOf(search?.toLowerCase())===-1));

    if(noMatch) {
        return (
            <Paper>
                <Box p={5}>{t('ThereIsNotData')}</Box>
            </Paper>
        )
    }

    return ( 
        <>
            {turnAppointmentId}
            {!!turnAppointmentId}
            
            <TurnVoucher 
                open={!!turnAppointmentId}
                onClose={handleCloseTurnVoucher}
                appointmentId={turnAppointmentId}
            />
            <AppointmentModal 
                onClose={switchOpen}
                onAdded={switchOpen}
                appointmentId={currentAppointment?._id}
                open={open}/>
            <AppointmentSummary appointment={currentAppointment} onClose={handleClose}>
                <Stack direction="row" justifyContent="space-between" width="100%">
                    <Button 
                        disabled={loading} 
                        variant='contained' 
                        color="grey" 
                        onClick={handleClose}>
                        {t('Back')}
                    </Button>

                    {currentAppointment?.turn && (                    
                        <Button 
                            disabled={loading}
                            variant='contained'
                            color="primary"
                            onClick={openTurnVoucher}
                        >
                            {t('Turn')}
                        </Button>
                    )}
                    
                    <Button 
                        disabled={loading}
                        variant='contained'
                        color="primary"
                        onClick={switchOpen}
                    >
                        {t('View')}
                    </Button>
                    {currentAppointment?.status==="PENDING" && !currentAppointment.turn && ( 
                        <LoadingButton 
                            variant='contained' 
                            color='warning' 
                            loading={queueLoading}
                            onClick={()=>handleQueue(currentAppointment._id)}>
                                {t('PayAndPutInQueue')}
                        </LoadingButton>) }
                </Stack>
            </AppointmentSummary>
            {Object.keys(AppointmentStatus).map(s=>{
                return (
                    <AppointmentList 
                        key={s}
                        title={t(s)} 
                        color={AppointmentStatus[s]}
                        search={search}
                        onClick={setCurrentAppointment}
                        appointments={getByStatus([s.toUpperCase()]).sort((a,b) => compareTurns(a.turn, b.turn))}
                    />
                )
            })}
      
            <AppointmentList 
                title={t("Others")} 
                search={search}
                appointments={getByOtherStatus()}
                onClick={setCurrentAppointment}
            />
        </>
    );
}

const DayTurnList = () => {
    const {t} = useTranslation();
    const [specialists] = useSpecialists();

    const [search, setSearch] = useState('');

    const handleChange = ($event) => {
        setSearch($event.target.value);
    }

    return (
        <>
                {
                (specialists && specialists.length>0) ? <>
                    <TextField
                        size="small"
                        fullWidth
                        label={t('Search Appointments')}
                        onChange={handleChange}
                        type='text'
                    ></TextField>
                    <Masonry columns={{sx:1, sm:2, md: 3}}>
                        {specialists.map((s) => (   
                            <Stack>
                                <SubTitle>
                                    {getSpecialistText(s)}
                                </SubTitle>
                                <Paper>
                                    <SpecialistTurnList search={search} specialist={s} />    
                                </Paper>
                            </Stack>
                        ))}
                    </Masonry>
                </>
                : <Typography>{t('PleaseSelectSpecialists')}</Typography>}
        </>
    );
}

export default DayTurnList;