import { useState } from "react";
import { Container, Box, Fab, Paper } from "@mui/material"
import DefaultContainer from "../shared/DefaultContainer"
import ModalContainer from "../shared/ModalContainer"
import Title from "../shared/Title"
import MedicalTestList from "./MedicalTestList"
import AddIcon from '@mui/icons-material/Add';
import Modal from '@mui/material/Modal';
import { useMutation, useQuery } from '@apollo/client';
import {DELETE_MEDICAL_TESTS} from '../graphql/mutations';
import { GET_MEDICAL_TESTS } from '../graphql/queries';
import Confirmation from "../shared/Confirmation";
import MedicalTestForm from "./MedicalTestForm";
import CustomToolbar from "../shared/CustomToolbar";
import { HasAccessTo } from "../auth/hooks";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useTenantId } from "../tenant/hooks";

const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
};

const MedicalTestModal = ({open, onClose, onAdded, medicalTestId}) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            data-testId="medical-test-modal"
        >
            <ModalContainer sx={{padding: 0}}>
                <MedicalTestForm 
                    medicalTestId={medicalTestId}
                    onCancel={onClose}
                    onAdded={onAdded}/>
            </ModalContainer>
        </Modal>
    )
}



export default ({}) => {
    const { t } = useTranslation();    
    const { enqueueSnackbar } = useSnackbar();

    const [tenantId] = useTenantId();

    const [open,setOpen] = useState(false);

    const [openDeleteConfirmation,setOpenDeleteConfirmation] = useState(false);

    const [selecteds,setSelecteds] = useState([]);

    const {data, loading} = useQuery(GET_MEDICAL_TESTS, {
        variables: {
            tenants: [tenantId]
        }
    });

    const [deleteMedicalTests, {loading:ldMedicalTests}] = useMutation(DELETE_MEDICAL_TESTS, {
        refetchQueries: [
            GET_MEDICAL_TESTS
        ],
        onCompleted: ()=>{
            enqueueSnackbar(t("OperationDoneSuccessfully"), {variant: "success"});
        }
    });
    const [editMedicalTestId,setEditMedicalTestId] = useState(undefined);

    const switchOpen = () => {
        setOpen(!open);
        if (!!open) {
            setEditMedicalTestId(undefined);
        }
    }

    const handleRowSelectedChanged = (value) => {
        setSelecteds(value);
    }

    const switchDeleteConfirmation = () => {
        setOpenDeleteConfirmation(!openDeleteConfirmation);
    }

    const handleDeleteMedicalTests = async () => {
        switchDeleteConfirmation();
        await deleteMedicalTests({
            variables: {
                medicalTestIds: selecteds
            }
        });
    }

    const handleEditMedicalTest = () => {
        setEditMedicalTestId(selecteds[0]);
        switchOpen();
    }

    return (
        <>
            <Confirmation 
                open={openDeleteConfirmation}
                title={t("Confirmation")}
                description={t("AreYouSureToDeleteTheseMedicalTestsQuestions")}
                onClose={switchDeleteConfirmation}
                onNo={switchDeleteConfirmation}
                onYes={handleDeleteMedicalTests}
            />
            <MedicalTestModal 
                onClose={switchOpen}
                onAdded={switchOpen}
                medicalTestId={editMedicalTestId}
                open={open}/>
            <Container>
                <Box pt={2}>
                    <Title testid="main-title">{t('MedicalTests')}</Title>
                </Box>
            </Container>
            <DefaultContainer sx={{mt:0}} plain>
                <Paper>
                    <MedicalTestList 
                        loading={loading}
                        rows={data?.getMedicalTests || []}
                        count={data?.getMedicalTests?.length || 0}
                        onRowSelectedChanged={handleRowSelectedChanged}
                        toolbar={()=><CustomToolbar 
                            entity="MedicalTest"
                            selecteds={selecteds} 
                            deleting={ldMedicalTests} 
                            onEdit={handleEditMedicalTest}
                            onDelete={switchDeleteConfirmation}
                        />}
                    />
                </Paper>
            </DefaultContainer>
       
            <HasAccessTo entity="MedicalTest" code="CREATE">
                <Fab 
                    data-testid="open-create-medical-test-modal"
                    onClick={switchOpen}
                    color="primary" 
                    sx={fabStyle}>
                    <AddIcon />
                </Fab>
            </HasAccessTo>
        </>
    )
}