import { useState } from "react";
import { Container, Box, Fab, Paper } from "@mui/material"
import DefaultContainer from "../shared/DefaultContainer"
import ModalContainer from "../shared/ModalContainer"
import Title from "../shared/Title"
import SpecialtyList from "./SpecialtyList"
import AddIcon from '@mui/icons-material/Add';
import Modal from '@mui/material/Modal';
import { useMutation, useQuery } from '@apollo/client';
import {DELETE_SPECIALTIES} from '../graphql/mutations';
import { GET_SPECIALTIES } from '../graphql/queries';
import Confirmation from "../shared/Confirmation";
import SpecialtyForm from "./SpecialtyForm";
import CustomToolbar from "../shared/CustomToolbar";
import { HasAccessTo } from "../auth/hooks";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useTenantId } from "../tenant/hooks";

const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
};

const SpecialtyModal = ({open, onClose, onAdded, specialtyId}) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <ModalContainer sx={{padding: 0}}>
                <SpecialtyForm 
                    specialtyId={specialtyId}
                    onCancel={onClose}
                    onAdded={onAdded}/>
            </ModalContainer>
        </Modal>
    )
}



export default ({}) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    
    const [tenantId] = useTenantId();

    const [open,setOpen] = useState(false);

    const [openDeleteConfirmation,setOpenDeleteConfirmation] = useState(false);

    const [selecteds,setSelecteds] = useState([]);

    const {data, loading} = useQuery(GET_SPECIALTIES, {
        variables: {
            tenants: [tenantId]
        }
    });

    const [deleteSpecialties, {loading:ldSpecialties}] = useMutation(DELETE_SPECIALTIES, {
        refetchQueries: [
            GET_SPECIALTIES
        ],
        onCompleted: ()=>{
            enqueueSnackbar(t("OperationDoneSuccessfully"), {variant: "success"});
        }
    });
    const [editSpecialtyId,setEditSpecialtyId] = useState(undefined);

    const switchOpen = () => {
        setOpen(!open);
        if (!!open) {
            setEditSpecialtyId(undefined);
        }
    }

    const handleRowSelectedChanged = (value) => {
        setSelecteds(value);
    }

    const switchDeleteConfirmation = () => {
        setOpenDeleteConfirmation(!openDeleteConfirmation);
    }

    const handleDeleteSpecialties = async () => {
        switchDeleteConfirmation();
        await deleteSpecialties({
            variables: {
                specialtyIds: selecteds
            }
        });
    }

    const handleEditSpecialty = () => {
        setEditSpecialtyId(selecteds[0]);
        switchOpen();
    }

    return (
        <>
            <Confirmation 
                open={openDeleteConfirmation}
                title={t("Confirmation")}
                description={t("AreYouSureToDeleteTheseSpecialtiesQuestion")}
                onClose={switchDeleteConfirmation}
                onNo={switchDeleteConfirmation}
                onYes={handleDeleteSpecialties}
            />
            <SpecialtyModal 
                onClose={switchOpen}
                onAdded={switchOpen}
                specialtyId={editSpecialtyId}
                open={open}/>
            <Container>
                <Box pt={2}>
                    <Title>{t('Specialties')}</Title>
                </Box>
            </Container>
            <DefaultContainer sx={{mt:0}} plain>
                <Paper>
                    <SpecialtyList 
                        loading={loading}
                        rows={data?.getSpecialties || []}
                        onRowSelectedChanged={handleRowSelectedChanged}
                        toolbar={()=><CustomToolbar
                            entity="Specialty"
                            selecteds={selecteds} 
                            deleting={ldSpecialties} 
                            onEdit={handleEditSpecialty}
                            onDelete={switchDeleteConfirmation}
                        />}
                    />
                </Paper>
            </DefaultContainer>
       
            <HasAccessTo entity="Specialty" code="CREATE">
                <Fab 
                    onClick={switchOpen}
                    color="primary" 
                    sx={fabStyle}>
                    <AddIcon />
                </Fab>
            </HasAccessTo>
        </>
    )
}