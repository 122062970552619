import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Paper, Slide, Typography } from '@mui/material';
import Box from '@mui/material/Box';

import { Stack } from '@mui/system';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import { createRef } from 'react';
import { useTranslation } from 'react-i18next';
import MainBox from '../../shared/MainBox';
import { useParams, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { FormTextField } from '../../shared/form-components';
import { RESET_PASSWORD } from '../../graphql/mutations';
import FooterSignature from '../../shared/FooterSignature';

const resetPasswordSchema = Yup.object({
    email: Yup.string().email().required(),
    password: Yup.string().required(),
    confirmPassword: Yup.string().required().oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

export default () => {
    const { resetPasswordCode } = useParams();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const {t} = useTranslation();

    const emaildRef = createRef();
    const password1Ref = createRef();
    const password2Ref = createRef();
    
    const {control, handleSubmit, formState: {isLoading} } = useForm({
        resolver: yupResolver(resetPasswordSchema)
    });

    const [resetPasswordFnc, { data, loading, error }] = useMutation(RESET_PASSWORD);

    const resetPassword = async (data) =>{
        await resetPasswordFnc({
            variables: {
                input: {
                    email: data.email,
                    password: data.password,
                    code: resetPasswordCode
                }
            }
        });

        enqueueSnackbar(t("ResetPasswordDone"), {variant: "success"});
        navigate('/');
    }

    const handleEmailKeyDown = (event) => {
        if (event.keyCode === 13) {
            password1Ref.current.focus();
        }
    }

    const handlePassword1KeyDown = (event) => {
        if (event.keyCode === 13) {
            password2Ref.current.focus();
        }
    }

    const handlePassword2KeyDown = (event) => {
        if (event.keyCode === 13) {
            handleSubmit(resetPassword)();
        }
    }

    return <MainBox alignItems="center" justifyContent="center">
            <Slide direction='right' in={true} mountOnEnter timeout={1000}>
                <Stack sx={{width: '300px'}} alignItems="center">
                    <Paper sx={{width:'100%'}} elevation={3}>
                    <Box p={2}>
                        <Stack spacing={2}>
                            <FormTextField
                                ref={emaildRef}
                                onKeyDown={handleEmailKeyDown}
                                control={control}
                                label={t("Email")}
                                name="email"
                                />

                            <FormTextField 
                                ref={password1Ref}
                                onKeyDown={handlePassword1KeyDown}
                                control={control}
                                type='password'
                                label={t("Password")}
                                name="password"
                                inputProps={{
                                    autocomplete: 'new-password',
                                    form: {
                                    autocomplete: 'off',
                                    },
                                }}
                            />

                            <FormTextField 
                                ref={password2Ref}
                                onKeyDown={handlePassword2KeyDown}
                                control={control}
                                type='password'
                                label={t("Confirm Password")}
                                name="confirmPassword"
                                inputProps={{
                                    autocomplete: 'new-password',
                                    form: {
                                    autocomplete: 'off',
                                    },
                                }}
                            />
                            <LoadingButton 
                                fullWidth
                                color="primary" 
                                variant="contained"
                                loading={isLoading}
                                onClick={handleSubmit(resetPassword)}>{t('Reset Password')}</LoadingButton>
                        </Stack>
                    </Box>
                    </Paper>
                   <Box p={2}>
                        <FooterSignature />
                    </Box>
                </Stack>
            </Slide>
        </MainBox>
}
