import { useMutation, useQuery } from "@apollo/client";
import { 
    Divider, 
    Grid, 
    List, 
    Paper, 
    Tabs, 
    TextField, 
    Tab, 
    ListItemButton, 
    ListItemText, 
    ListItemIcon, 
    Typography,
    Button,
    InputLabel,
    IconButton,
    Fab,
    Badge,
    Menu,
    MenuItem,
    Tooltip
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { GET_APPOINTMENTS_BY_PATIENT_ID, GET_MEDICAL_TESTS } from "../graphql/queries";
import { 
    UPDATE_APPOINTMENT_REQUESTED_MEDICAL_TESTS
} from "../graphql/mutations";
import { getAppointmentText, getAppointmentMediumText, getSpecialistText } from "../shared/label-helpers";
import SubTitle from "../shared/SubTitle";
import * as moment from 'moment';
import { useEffect, useRef, useState } from "react";
import TabPanel from '@mui/lab/TabPanel';
import FormLabel from '@mui/material/FormLabel';
import { TabContext } from "@mui/lab";
import { useForm } from "react-hook-form";
import StarIcon from '@mui/icons-material/Star';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import MedicalTestChips from '../medical-test/MedicalTestChips';
import { FormSelectMultiple } from '../shared/form-components'
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import StatusChip from "../shared/StatusChip";
import { formatMoney } from "../shared/format-helpers";
import AppointmentComments from "../appointment/AppointmentComments";
import { Close, WorkHistory } from "@mui/icons-material";
import { red } from "@mui/material/colors";
import { useTenantId } from "../tenant/hooks";

const InvoiceSummary = ({invoice}) => {
    const {t} = useTranslation();
    return (
        <Paper>
            <Box p={2}>
                <SubTitle>{t('Invoice')}</SubTitle>
            </Box>
            <Divider/>
            <Box>
                {invoice ? 
                    (
                    <>
                        <Stack p={2}>
                            <Stack direction="row" justifyContent="space-between">
                                <Typography fontWeight="bold">{t('Status')}:</Typography>
                                <Typography><StatusChip status={invoice.status} /></Typography>
                            </Stack>

                            <Stack direction="row" justifyContent="space-between">
                                <Typography fontWeight="bold">{t('SubTotalAmount')}:</Typography>
                                <Typography>{formatMoney(invoice.subTotalAmount)}</Typography>
                            </Stack>

                            <Stack direction="row" justifyContent="space-between">
                                <Typography fontWeight="bold">{t('DiscountAmount')}:</Typography>
                                <Typography>{formatMoney(invoice.discountAmount)}</Typography>
                            </Stack>

                            <Stack direction="row" justifyContent="space-between">
                                <Typography fontWeight="bold">{t('InsuranceAmount')}:</Typography>
                                <Typography>{formatMoney(invoice.insuranceAmount)}</Typography>
                            </Stack>
                        </Stack>
                        <Divider />
                        <Stack 
                            p={2} 
                            direction="row" 
                            justifyContent="space-between">
                            <Typography fontWeight="bold">{t('TotalAmount')}:</Typography>
                            <Typography>{formatMoney(invoice.totalAmount)}</Typography>
                        </Stack>
                    </>): 
                        <Box p={2}>
                            {t('NoInvoiceYet')}
                        </Box>
                }
            </Box>
        </Paper>
    );
}

const HistoryList = ({appointments, selecteds, onClick }) => {
    const {t} = useTranslation();

    const handleClick = (app) => () => {
        onClick && onClick(app); 
    }

    return (
        <Paper>
            <Box p={2}>
                <SubTitle>{t('History')}</SubTitle>
            </Box>
            <Divider />
            <List sx={{maxHeight: '350px', overflow: 'auto'}}>
                {appointments?.filter(a => a?.turn?.status !== "WORKING")?.map((a, index)=>{
                    return (
                        <ListItemButton 
                            key={index} 
                            onClick={handleClick(a)}>
                                <ListItemIcon>
                                    {
                                        selecteds.some(s=>s._id === a._id) 
                                        ? <VisibilityOffIcon />
                                        : <VisibilityIcon />
                                        
                                    }
                                </ListItemIcon>
                                <ListItemText primary={ getAppointmentMediumText(a) } />
                        </ListItemButton>
                    )
                })}
            </List>
        </Paper>
    );
}


const HistoryButton = ({appointments, onMenuClick,  selecteds}) => {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const ref = useRef();

    const handleClick = () => {
        setOpen(!open);
    }

    const handleMenuClick = (app) => () => {
        onMenuClick && onMenuClick(app); 
        setOpen(false);
    }

    return (<>
            <Tooltip title={t('History')}>
                <Badge color="primary" badgeContent={(appointments?.filter(a => a?.turn?.status !== "WORKING")?.length)??0}>
                    <IconButton ref={ref} variant="contained" onClick={handleClick}>
                        <WorkHistory />
                    </IconButton>
                </Badge>
            </Tooltip>
            <Menu anchorEl={ref.current} open={open} onClose={handleClick}>
                {appointments?.filter(a => a?.turn?.status !== "WORKING" && !selecteds.some(s=>s._id == a._id))?.map((a, index)=>{
                    return (
                        <MenuItem 
                            key={index} 
                            onClick={handleMenuClick(a)}
                            >
                            { getAppointmentMediumText(a) }
                        </MenuItem>
                    )
                })}
            </Menu>
        </>
    );
}

const updateAppointmentSchema = Yup.object({
    medicalTests: Yup.array().nonNullable()
});

const AppointmentDetail = ({appointment, onChanged}) => {
    const [tenantId] = useTenantId();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const {control, handleSubmit, loading} = useForm({
        resolver: yupResolver(updateAppointmentSchema),
        values: {
            medicalTests: appointment.requestedMedicalTests
        }
    });

    const [updateAppointmentRequestedMedicalTests, {data: uData, loading: uLoading}] = useMutation(
        UPDATE_APPOINTMENT_REQUESTED_MEDICAL_TESTS, {
            refetchQueries: [],
            onCompleted: ()=>{
                enqueueSnackbar(t("OperationDoneSuccessfully"), {variant: "success"});
            }
        });

    const { data } = useQuery(GET_MEDICAL_TESTS, {
        variables: {
            tenants:[tenantId]
        }
    });

    const handleNodeAdded = () => {
        onChanged && onChanged();
    }

    const editable = appointment?.turn?.status=='WORKING' || uLoading || loading;

    const handleUpdate =  async(data) => {
        await updateAppointmentRequestedMedicalTests({
            variables: {
                appointmentId: appointment._id,
                medicalTests: (data.medicalTests ?? [])
            }
        });
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={8}>
                <Paper>
                    <Box p={2}>
                        <SubTitle>{t('Appointment')}</SubTitle>
                    </Box>
                    <Divider />
                    <Box p={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <TextField 
                                    fullWidth
                                    size="small"
                                    variant="standard" 
                                    label={t("Date")}
                                    contentEditable={false}
                                    value={moment(appointment.date).format('l')}/>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField 
                                    fullWidth
                                    size="small"
                                    variant="standard" 
                                    label={t("Time")}
                                    contentEditable={false}
                                    value={appointment.time}/>
                            </Grid>
                            <Grid item xs={4}>
                                <InputLabel shrink={true} size="small">{t('Status')}</InputLabel>
                                <StatusChip status={appointment.status} />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField 
                                    fullWidth
                                    size="small"
                                    variant="standard" 
                                    label={t("Service")}
                                    contentEditable={false}
                                    value={appointment.service?.name}/>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField 
                                    fullWidth
                                    size="small"
                                    variant="standard" 
                                    label={t("Specialist")}
                                    contentEditable={false}
                                    value={getSpecialistText(appointment?.service?.specialist)}/>
                            </Grid>
                            <Grid item xs={12}>
                                <FormLabel>
                                    {t('RequiredMedicalTests')}
                                </FormLabel>
                                <div>
                                    <MedicalTestChips medicalTests={appointment?.requiredMedicalTests}/>
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <FormLabel>
                                    {t('DoneMedicalTests')}
                                </FormLabel>
                                <div>
                                    <MedicalTestChips medicalTests={appointment?.doneMedicalTests}/>
                                </div>
                            </Grid>

                            {editable ? ( 
                            <>
                                <Grid item xs={12}>
                                    <FormSelectMultiple 
                                        fullWidth
                                        control={control}
                                        getOptionLabel={(option) => `${option.name}` }
                                        options={((data?.getMedicalTests)||[])}
                                        disabled={!editable}
                                        variant="standard"
                                        name="medicalTests"
                                        label={t("RequestedMedicalTests")}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button 
                                        fullWidth
                                        variant="contained" 
                                        color="success"
                                        disabled={!editable}
                                        onClick={handleSubmit(handleUpdate)}>
                                        {t('Update')}
                                    </Button>
                                </Grid>
                            </>
                            ) : (
                                <Grid item xs={12}>
                                    <FormLabel>
                                        {t('RequestedMedicalTest')}
                                    </FormLabel>
                                    <div>
                                        <MedicalTestChips medicalTests={appointment?.requestedMedicalTests}/>
                                    </div>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={4}>
                <Stack spacing={2}>
                    <InvoiceSummary invoice={appointment.invoice}/>
                    <AppointmentComments 
                        comments={appointment.comments} 
                        appointmentId={appointment._id}
                        onAdded={handleNodeAdded}
                        />
                </Stack>
            </Grid>
        </Grid>
    );
}

export default ({ patient, onCurrentWorkingChanged }) => {
    const {t} = useTranslation();
    const [tenantId] = useTenantId();
    const {data: dAData, loaindg: dALoading, refetch} = useQuery(GET_APPOINTMENTS_BY_PATIENT_ID,{
        variables: {
            patientId: patient._id,
            tenants: [tenantId]
        }
    });

    const [apps, setApps] = useState([]);

    const [tab, setTab] = useState("0");

    const changeTab = (index) => {
        setTab(index);
        onCurrentWorkingChanged(index==0);
    }

    const handleChange = (event, newValue) => {
        changeTab(newValue);
    };

    const handleAppointmentChanged = () => {
        refetch();
    }

    useEffect(()=>{
        onCurrentWorkingChanged(true);
    }, [patient]);

    const workings = dAData?.getAppointments?.filter(a => a.status === "WORKING") ?? [];
    const allApps = [...workings, ...apps];

    const handleClickHistory = (ap) => {
        const index = allApps.findIndex(a => a._id === ap._id);
        if (index === -1) {
            setApps([...apps, ap]);
            changeTab(allApps.length.toString());
        } else {
            const newApps = apps.filter(a=>a._id !== ap._id);
            setApps(newApps);
            changeTab("0");
        }
    }

    return (
        <Box p={2}>
            <Grid container spacing={2}>
               <Grid item xs={12}>
                    <Paper>
                        <Box p={2}>
                            <SubTitle>{t('Information')}</SubTitle>
                        </Box>
                        <Divider>
                            {t('Patient')}
                        </Divider>
                        <Box p={2}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <TextField 
                                        fullWidth 
                                        size="small"
                                        variant="standard" 
                                        label={t("PatientName")}
                                        contentEditable={false}
                                        value={patient.patientName}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField 
                                        fullWidth
                                        size="small"
                                        variant="standard" 
                                        label={t("PatientBirthday")}
                                        contentEditable={false}
                                        value={patient.patientBirthday}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField 
                                        fullWidth
                                        size="small"
                                        variant="standard" 
                                        label={t("PatientAge")}
                                        contentEditable={false}
                                        value={moment(patient.patientBirthday).fromNow()}/>
                                </Grid>
                            </Grid>
                        </Box>
                        <Divider>
                            {t('Parent')}
                        </Divider>
                        <Box p={2}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <TextField 
                                        fullWidth
                                        size="small"
                                        variant="standard" 
                                        label={t("TutorFirstName")}
                                        contentEditable={false}
                                        value={patient.tutorFirstName}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField 
                                        fullWidth
                                        size="small"
                                        variant="standard" 
                                        label={t("TutorLastName")}
                                        contentEditable={false}
                                        value={patient.tutorLastName}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField 
                                        fullWidth
                                        size="small"
                                        variant="standard" 
                                        label={t("TutorPhoneNumber")}
                                        contentEditable={false}
                                        value={patient.tutorPhoneNumber}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField 
                                        fullWidth
                                        size="small"
                                        variant="standard" 
                                        label={t("TutorIdentification")}
                                        contentEditable={false}
                                        value={patient.tutorIdentification}/>
                                </Grid>
                            </Grid>
                            
                        </Box>

                    </Paper>
                </Grid>  
                {allApps.length>0 ?
                (
                    <Grid item xs={12}>
                        <TabContext value={tab.toString()}>
                            <Stack direction="row" justifyContent="space-between">
                                <Box sx={{ width:'100%', bgcolor: 'background.paper', mb:2 }}>
                                    <Tabs
                                        variant="scrollable"
                                        scrollButtons
                                        value={tab}
                                        onChange={handleChange}
                                    >
                                        {allApps.map((app, index)=>{
                                            const isWorking = app?.turn?.status === "WORKING";
                                            return (
                                                <Tab 
                                                    key={index}
                                                    value={index.toString()} 
                                                    iconPosition="start"
                                                    icon={isWorking ? <StarIcon /> : undefined}
                                                label={ <Stack spacing={2} direction='row' alignItems="center">
                                                    <Typography>
                                                    {getAppointmentMediumText(app)}
                                                    </Typography>
                                                    {!isWorking && <IconButton color="error"
                                                        onClick={()=>handleClickHistory(app)}
                                                        size="small">
                                                        <Close/>
                                                    </IconButton>}
                                                </Stack>}
                                            />)
                                        })}
                                    </Tabs>
                                </Box>
                                <Box p={2}>
                                    <HistoryButton 
                                        selecteds={apps}
                                        onMenuClick={handleClickHistory} 
                                        appointments={dAData?.getAppointments} 
                                    />
                                </Box>
                            </Stack>
                            {allApps.map((app, index)=>{
                                return (
                                    <TabPanel 
                                        sx={{p:0}}
                                        key={index}
                                        value={index.toString()} 
                                        label={getAppointmentText(app)}>
                                        <AppointmentDetail 
                                            appointment={app}
                                            onChanged={handleAppointmentChanged}
                                        />
                                    </TabPanel>
                                );
                            })}
                        </TabContext>
                    </Grid>
                ): undefined}
            </Grid>

        </Box>
    );
}