import {DataGrid} from '@mui/x-data-grid'
import Switch from '@mui/material/Switch'
import { Box } from '@mui/material';

const columns = [
    { field: '_id', headerName: 'ID', width: 60, },
    { field: 'name', headerName: 'Name', width: 250 },
    { 
      field: 'percent', 
      headerName: 'Percent', 
      width: 180,
      valueGetter: ({row})=> `${row.percent}%`
    },
    { 
      field: 'enabled', 
      headerName: 'Enabled', 
      width: 150,
      renderCell: ({row}) => <Switch size='small' disabled checked={row.enabled} />
    },

   ];
  
  export default ({
      rows, 
      count, 
      loading, 
      onFilterModelChange, 
      onSortModelChange, 
      onPaginationModelChange, 
      onRowSelectedChanged,
      toolbar
    }) => {
    return (
      <Box sx={{ height: 450, width: '100%' }}>
        <DataGrid
          density='compact'
          rows={rows}
          columns={columns}
          rowCount={count}
          loading={loading}
          getRowId={(r)=>r._id}
          onRowSelectionModelChange={onRowSelectedChanged}
          initialState={{
            columns: {
              columnVisibilityModel: {
                _id: false
              }
            },
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 15, 20]}
          onPaginationModelChange={onPaginationModelChange}
          checkboxSelection
          onSortModelChange={onSortModelChange}
          onFilterModelChange={onFilterModelChange}
          slots={{
            toolbar: toolbar
          }}
        />
      </Box>
    );
  }