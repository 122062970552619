import { GET_TAGS } from "../graphql/queries";
import { FormSelectMultiple } from "../shared/form-components";
import { useRef, useState } from 'react';
import {Popover, Tooltip} from '@mui/material';
import TagForm from './TagForm';
import { useQuery } from '@apollo/client';
import { useController } from "react-hook-form";
import {useTranslation} from "react-i18next";

export default ({tenantId, categories, loading, control, name, ...props}) => {
    const { t } = useTranslation();
    const ref = useRef();
    const [text,setText] = useState();
    const {data, loading: queryLoading} = useQuery(GET_TAGS, {
        variables: {
            tenants: [tenantId],
            categories: categories
        },
        skip: loading
    });

    const {
        field: { onChange, value }
    } = useController({
        name: name,
        control: control
    });

    const [adding, setAdding] = useState(false);

    const onKeyUp = (param) => {
        if (param.key==="Enter") {
            const name = param.target.value??'';
            const tags = data.getTags??[];
            const tag = tags.find(t=>(t.name?.toLowerCase()??'')==name.toLowerCase());
            
            if (tag) {
                onChange([...( value??[]), tag]);
            } else {
                setText(param.target.value);
                setAdding(true);
            }
        }
    }

    const onAdded = (tag) => {
        setAdding(false);
        onChange([...( value??[]), tag]);
    }

    return (<>
        <Tooltip title={t('TagCreateTooltip')} placement="top-start">
            <FormSelectMultiple
                ref={ref.current}
                options={data?.getTags??[]}
                onKeyUp={onKeyUp}
                loading={queryLoading || loading}
                control={control}
                name={name}
                getOptionLabel={ (option) => option.name}
                {...props}
            />
        </Tooltip>
        <Popover 
            open={adding}
            anchorEl={ref.current}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            onClose={()=>{
                setAdding(false);
            }}
        >
            <TagForm 
                defaultValues={{
                    name: text,
                    categories
                }}
                onBack={()=>{
                    setAdding(false);
                }} 
                onAdded={onAdded}
            />
        </Popover>
    </>)
}