import { useEffect, useState } from "react";
import {Container, Box, Fab, Paper, Fade} from "@mui/material"
import DefaultContainer from "../shared/DefaultContainer"
import ModalContainer from "../shared/ModalContainer"
import Title from "../shared/Title"
import PatientList from "./PatientList"
import AddIcon from '@mui/icons-material/Add';
import PatientForm from "./PatientForm"
import Modal from '@mui/material/Modal';
import { useMutation, useQuery } from '@apollo/client';
import {GET_PAGINATED_PATIENTS} from '../graphql/queries';
import {DELETE_PATIENTS} from '../graphql/mutations';
import Confirmation from "../shared/Confirmation";
import { HasAccessTo } from "../auth/hooks";
import CustomToolbar from '../shared/CustomToolbar';
import { useTranslation } from "react-i18next";
import { useTenantId } from "../tenant/hooks";

const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
};

const PatientModal = ({open, onClose, onAdded, patientId}) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            closeAfterTransition
            data-testId="patient-modal"
        >
            <Fade in={open}>
                <ModalContainer width={450}>
                    <PatientForm
                        patientId={patientId}
                        onCancel={onClose}
                        onAdded={onAdded}/>
                </ModalContainer>
            </Fade>
        </Modal>
    )
}



export default ({}) => {
    const  {t} = useTranslation();
    const [open,setOpen] = useState(false);
    
    const [tenantId] = useTenantId();

    const [openDeleteConfirmation,setOpenDeleteConfirmation] = useState(false);

    const [selecteds,setSelecteds] = useState([]);

    const {data, loading, refetch} = useQuery(GET_PAGINATED_PATIENTS, {
        variables: {
            skip:0,
            limit: 10,
            tenants: [tenantId]
        }
    });

    const [sort, setSort] = useState(undefined);
    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(10);
    const [filter, setFilter] = useState({});

    const [deletePatients, {loading:ldPatients}] = useMutation(DELETE_PATIENTS, {
        refetchQueries: [
            GET_PAGINATED_PATIENTS
        ]
    });
    const [editPatientId,setEditPatientId] = useState(undefined);

    useEffect(()=>{
        refetch({
            sort: sort,
            skip: skip,
            limit: limit,
            filter: filter,
            tenants: [tenantId]
        })
    }, [sort, skip, limit, tenantId,filter]);
    
    const switchOpen = () => {
        setOpen(!open);
        if (!!open) {
            setEditPatientId(undefined);
        }
    }

    const handleRowSelectedChanged = (value) => {
        setSelecteds(value);
    }

    const switchDeleteConfirmation = () => {
        setOpenDeleteConfirmation(!openDeleteConfirmation);
    }

    const handleDeletePatients = async () => {
        switchDeleteConfirmation();
        await deletePatients({
            variables: {
                patientIds: selecteds
            }
        });
    }

    const handleEditPatient = () => {
        setEditPatientId(selecteds[0]);
        switchOpen();
    }

    const handlePaginationModelChanged = (e) => {
        setSkip(e.page*e.pageSize);
        setLimit(e.pageSize);
    }

    const handleSortModelChanged = (e) => {
        setSort({[e.field]: e.sort});
    }

    const handleFilterModelChanged = (e) => {
        const nFilter =  e.items.reduce((p, c) => {
            p[c.field] = c.value;
            return p;
        }, {});

        setFilter({
            ...nFilter
        });
    }

    return (
        <>
            <Confirmation 
                open={openDeleteConfirmation}
                title={t("Confirmation")}
                description={t("AreYouSureToDeleteThesePatientsQuestion")}
                onClose={switchDeleteConfirmation}
                onNo={switchDeleteConfirmation}
                onYes={handleDeletePatients}
            />
            <PatientModal 
                onClose={switchOpen}
                onAdded={switchOpen}
                patientId={editPatientId}
                open={open}/>
            <Container>
                <Box pt={2}>
                    <Title testid="main-title">{t('Patients')}</Title>
                </Box>
            </Container>
            <DefaultContainer sx={{mt:0}} plain>
                <Paper>
                    <PatientList 
                        loading={loading}
                        onSortModelChange={handleSortModelChanged}
                        onPaginationModelChange={handlePaginationModelChanged}
                        rows={data?.paginatedResults?.rows || []}
                        count={data?.paginatedResults?.count || 0}
                        onFilterModelChange={handleFilterModelChanged}
                        onRowSelectedChanged={handleRowSelectedChanged}
                        toolbar={()=><CustomToolbar 
                            entity="Patient"
                            selecteds={selecteds} 
                            deleting={ldPatients} 
                            onEdit={handleEditPatient}
                            onDelete={switchDeleteConfirmation}
                        />}
                    />
                </Paper>
            </DefaultContainer>
       
            <HasAccessTo entity="Patient" code="CREATE">
                <Fab 
                    data-testid="open-create-patient-modal"
                    onClick={switchOpen}
                    color="primary" 
                    sx={fabStyle}>
                    <AddIcon />
                </Fab>
            </HasAccessTo>
        </>
    )
}