import { createContext, useContext, useState } from "react";

export const ConfigurationContext = createContext();

export const useConfiguration = () => {
    const {uri, ws} = useContext(ConfigurationContext);
    return [{uri, ws}];
}

const isProd = process.env.NODE_ENV === 'production';

const config = isProd ?
// Prod
{
    uri: '/api/graphql', 
    ws: {
        uri:'',
        path: '/api/socket.io'
    }
}
// Dev
: {
    uri: 'http://localhost:3001/graphql', 
    ws: {
        uri: 'http://localhost:3001',
        path: '/socket.io' 
    }
};

export const ConfigurationProvider = ({children}) => {
    const [configuration, setConfiguration] = useState(config);
   
    return (
        <ConfigurationContext.Provider value={configuration}>
            {children}
        </ConfigurationContext.Provider>
    )
}