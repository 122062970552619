import React from 'react';
import {
    Dialog,
    DialogActions,
    Typography,
    Stack,
    Divider,
    Paper,
    Box,
    Fab
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import * as moment from 'moment';
import { getServiceText } from '../shared/label-helpers';
import StatusChip from '../shared/StatusChip';
import {formatMoney} from '../shared/format-helpers';
import ShareIcon from '@mui/icons-material/Share'

const DataLine = ({label, children}) => (
    <Stack direction="row" justifyContent="space-between">
        <Typography sx={{fontWeight: 'bold'}}>
            {label}:
        </Typography>
        <Typography align='right'>
            {children}
        </Typography>
    </Stack>
);

export default ({
    appointment,
    onClose,
    children
}) => {

    const { t } = useTranslation();

    return (
    <Dialog
        open={appointment!==undefined}
        onClose={onClose}
    >
        <Paper p={2} elevation={0}>
            <Box p={2}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">
                        {t('Appointment')}  
                    </Typography>
                    <Stack spacing={2} direction="row" alignItems="center">
                        <StatusChip status={appointment?.status} />
                        <Fab size='small'>
                            <ShareIcon />
                        </Fab>
                    </Stack>
                </Stack>
            </Box>
        </Paper>
        <Divider/>
        <Paper sx={{minWidth: 300}} elevation={0}>
            <Box p={2}>
                <Stack>
                    <DataLine label={t("Patient")}>
                        {appointment?.patient?.patientName}
                   </DataLine>

                    <DataLine label={t("Tutor")}>
                       {appointment?.patient?.tutorFirstName} {appointment?.patient?.tutorLastName}
                    </DataLine>
                    <DataLine label={t("Phone")}>
                        {appointment?.patient?.tutorPhoneNumber}
                    </DataLine>
                </Stack>
                <Stack mt={2}>
                    <DataLine label={t("Service")}>
                        {getServiceText(appointment?.service)}
                    </DataLine>
                  
                    <DataLine label={t("Date")}>
                        {appointment ? moment(appointment?.date).format('l') : ''} {appointment?.time}
                    </DataLine>
                </Stack>
            </Box>
            {appointment?.turn && (
                <>
                    <Divider />
                    <Box p={2}>
                            <DataLine label={t("Turn")}>
                                {getServiceText(appointment?.turn?.code)}
                            </DataLine>
                    </Box>
                </>
                    )}
            <Divider/>
            <Box p={2}>
                <Stack>
                    <DataLine label={t("TotalAmount")}>
                        <Typography fontWeight='bold'>
                        {formatMoney(appointment?.totalAmount??0)}
                        </Typography>
                    </DataLine>
                </Stack>
            </Box>
            <Divider/>
            <Box p={2}>
                <Stack>
                    <Typography variant='caption' textAlign="center" p={0}>
                        {appointment?.code}
                    </Typography>
                    <Typography variant='caption' textAlign="center" p={0}>
                        {moment(appointment?.createdAt).format('l LT')}
                    </Typography>
                    <Typography variant='overline' textAlign="center" p={0}>
                        {appointment?._id}
                    </Typography>
                </Stack>
            </Box>
        </Paper>
        <Divider/>
        <DialogActions>
            {children}
        </DialogActions>
      </Dialog>
    );
}