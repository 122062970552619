import { Container, Box, Fab, Stack, Tooltip, CircularProgress, Button, Paper, styled } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { HasAccessTo } from "../auth/hooks";
import { useTranslation } from "react-i18next";


const FloutingStack = styled(Stack)({
    position: 'absolute',
    width: '100%',
    top: '-26px'
});

const CustomToolbar = ({deleting, onEdit, selecteds, onDelete, entity, children, constraint, getEntityRefProp}) => {
    const {t} = useTranslation();

    const entityRefs = (getEntityRefProp && selecteds.map(s=> getEntityRefProp(s))) ?? [];

    return (
        <FloutingStack 
            spacing={1} 
            direction="row" 
            justifyContent="end" 
            justifyItems="center">
            {children}
            <HasAccessTo any={[
                {
                    entity:entity,
                    code:"UPDATE",
                    constraint:constraint,
                    entityRef:entityRefs
                },
                {
                    entity:entity,
                    code:"VIEW",
                    constraint:constraint,
                    entityRef:entityRefs
                }
            ]}>
                <Tooltip title={t("EditSelected")}>
                    <span>
                        <Fab
                            size="small"
                            color="info"
                            onClick={onEdit}
                            disabled={selecteds.length!=1}
                            data-testid="toolbar-edit-btn">
                            <EditIcon></EditIcon>
                        </Fab>
                    </span>
                </Tooltip>
            </HasAccessTo>

            <HasAccessTo entity={entity} code="DELETE" constraint={constraint} entityRef={entityRefs}>
                <Tooltip title={t("DeleteSelecteds")}>
                    {deleting? <CircularProgress color="error"  size={20}/>
                        :
                        <span>
                            <Fab
                                size="small"
                                onClick={onDelete}
                                disabled={selecteds.length==0} 
                                color="error"
                                data-testid="toolbar-delete-btn"
                            >
                                <DeleteIcon></DeleteIcon>
                            </Fab>
                        </span>
                    }
                </Tooltip>
            </HasAccessTo>
        </FloutingStack>);
};


export default CustomToolbar;